import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { Api } from '../utils/api';
import Loginmessage from "../components/Admin/Loginmessage";
import {useNavigate } from 'react-router-dom';
import { Decimalstorequest } from '../utils/Decimalstorequest'
import { Decimals } from '../utils/Decimals'
import "react-datepicker/dist/react-datepicker.css";

const PickCreationDate = ({ creationDate, onDateChange }) => {
  const today = new Date();
  const maxDate = new Date().setDate(today.getDate());
  return (
    <div style={{position:"relative", zIndex:1000}}>
      <h3>Creation date: </h3>
      <DatePicker
       className="form-select"
        selected={creationDate} 
        onChange={onDateChange} 
        maxDate={new Date(maxDate)}
        showYearDropdown
        dropdownMode="select"  // select a year from a dropdown
        scrollableYearDropdown // makes the year dropdown scrollable
      />
    </div>
  );
};

const CproductExp =({setProductPage}) => {
  
  const navigate = useNavigate();
  const [fetchStatus, setFetchStatus] = useState(true);
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [artist, setArtist] = useState('');
  const [paintLength, setPaintLength] = useState('');
  const [paintWidth, setPaintWidth] = useState('');
  const [paintHeight, setPaintHeight] = useState('');
  const [paintWeight, setPaintWeight] = useState('');
  const [packageLength, setpackageLength] = useState('');
  const [packageWidth, setpackageWidth] = useState('');
  const [packageHeight, setpackageHeight] = useState('');
  const [condition, setCondition] = useState('');
  const [creationDate, setCreationDate] = useState("");
  const [availability, setAvailability] = useState('unavailable');
  const [packageWeight, setpackageWeight] = useState('');
  const[packageType, setPackageType] = useState('');
  const [packageFee, setpackageFee] = useState('');

  const [files,setFiles]=useState([])

  const [categories, setCategories]=useState([])
  const [isLoadingCs, setIsLoadingCs]=useState(true)

  const [selCategoryOpt, setSelCatOpt] = useState('');
  const [selTypeCat, setTypeCat] = useState('');
  const [category, setSelectedCat] = useState('');

  useEffect(() => {
    if (selTypeCat !== '') {
    setSelectedCat(selTypeCat);
    } else {
    setSelectedCat(selCategoryOpt);
    }
  }, [selTypeCat, selCategoryOpt]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await Api.get('/getpaintings/categories', {
          headers: {
            Authorization: `${localStorage.getItem('id_token')}`,
          },
        });

        if (response && response.data) {
          setCategories(response.data);
          setIsLoadingCs(false);
        }
      } catch (error) {
        setIsLoadingCs(false);
        if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error
          setFetchStatus(false);
        } else {
          console.log('failed to fetch categories')
        }
      }
    };

    fetchCategories();
  }, []);

  const changeFiles =(e)=>{
  setFiles(e.target.files)
  }

  const handleSubmit = async (e) => {
    setIsLoadingCs(true)
    e.preventDefault()

    let setPriceVal = Decimalstorequest(price,2)
    let setPackageFeeVal = Decimalstorequest(packageFee,2)
    let setpackageWeightVal = Decimalstorequest(packageWeight,1)
    let categoryToUse = category; // currently selected category
    let setpaintWeightVal = Decimalstorequest(paintWeight,1)

    const pdims = [paintLength,paintWidth,paintHeight,]
    const pckdims = [packageLength,packageWidth,packageHeight,]
    pdims.sort((a, b) => b - a);
    pckdims.sort((a, b) => b - a);

    try {
    // If a new category name is entered, create the category
      if (selTypeCat !== '') {
        const response = await Api.post('/createcategorysingle', 
          { categoryname: selTypeCat }, {
          headers: {
            Authorization: `${localStorage.getItem('id_token')}`,
          },
        });

        if (response && response.data) {
          categoryToUse = response.data.id; // Use the new category
        }
      }
      // Construct the painting object
      const painting = {
      title, 
      price:setPriceVal, 
      description, 
      artist, 
      paintLength:pdims[0],
      paintWidth:pdims[1],
      paintHeight:pdims[2],
      paintWeight:setpaintWeightVal,
      packageLength:pckdims[0],
      packageWidth:pckdims[1],
      packageHeight:pckdims[2],
      packageWeight:setpackageWeightVal,
      packageType,
      packageFee:setPackageFeeVal,
      condition, 
      creationDate, 
      availability,
      category: categoryToUse // Use the determined category
      };

      const response2 = await Api.post('/createpainting', {...painting},
        {
          headers: {
            Authorization: `${localStorage.getItem('id_token')}`,
            },
        } 
      );

      if(response2&&response2.data){

        if(files.length>0){
          const formData = new FormData()
          for(const file of files){
            formData.append("photos", file)
          }   
          const response3 = await Api.post(`/uploadphotos/${response2.data.id}`,formData, {
            headers:{
                      "content-type":"multipart/form-data",
                      Authorization: `${localStorage.getItem('id_token')}`,
                    },
          });

          if (response3 && response3.data) {
            setFiles([]) 
          }
        }
      }
    
      if (response2.status === 200) {
        alert('painting created');
        setProductPage(response2.data.id)
        setIsLoadingCs(false)
        navigate('/singleproductpage')
      } else {
        setIsLoadingCs(false)
        alert('Failed to create painting');
      }

    } catch (error) {
      setIsLoadingCs(false)
      alert('Failed to create painting');
    }
  };

  const handleSelCatOpt = (e) => {
    setTypeCat('')
    setSelCatOpt(e.target.value)
  }

  const handleTypeCat = (e) => {
    setSelCatOpt('')
    setTypeCat(e.target.value)
  };

  const handleChange = (value,p) => {

      let v = value
      if(v<=0){
      v=''
      }
      if(p==='price'||p==='packageFee'){
        if(value.toString().includes('.')){
                v=Decimals(value,2)
        }
      }else if(p==='packageWeight'||p==='paintWeight'){
        if(value.toString().includes('.')){
          v=Decimals(value,1)
      }
      }else{
        if(value.toString().includes('.')){
          v=Decimals(value,0)
      }
      }

      switch (p) {
        case 'price':
          setPrice(v);
          break;
        case 'packageHeight':
          setpackageHeight(v);
          break;
        case 'packageWidth':
          setpackageWidth(v);
          break;
        case 'packageLength':
          setpackageLength(v);
          break;
        case 'paintLength':
          setPaintLength(v);
          break;
        case 'paintWidth':
          setPaintWidth(v);
          break;
        case 'paintHeight':
          setPaintHeight(v);
          break;
        case 'packageWeight':
          setpackageWeight(v);
          break;
          case 'paintWeight':
            setPaintWeight(v);
            break;
            case 'packageFee':
              setpackageFee(v);
              break;
        default:
          break;
      } 
  };
  
  if(!fetchStatus){
    return(
      <Loginmessage/>
    )
  }

  if(isLoadingCs){
    return    <div className="text-light text-center custom6 custom4" style={{paddingTop:'260px'}}>
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
  }

  return (
  <div className="text-light container-md pb-2 custom6">
    <div className='mb-3 text-center'>
      <h3>Create new painting</h3>
    </div>
      <form onSubmit={handleSubmit}>         
        <div className='mb-3'>
          <h3>
            Add photo:
          </h3>
          <input id='file-upload' type="file" className="form-control" multiple onChange={changeFiles}/>
        </div>
        <div className='mb-3'>
          <h3>Category:</h3>
          <select
           className="form-select"
            name="category"
            value={selCategoryOpt}
            onChange={handleSelCatOpt}
          >
          <option value="">Select a category</option>
          {categories&&categories.map((cat,index) => (
            <option key={index} value={cat._id}>
              {cat.categoryname}
            </option>
          ))}
          </select>
          <div className="my-2">or</div>
          <label className="form-label">
              type a new category:
              <input type="text" className="form-control" value={selTypeCat} onChange={handleTypeCat} />
            </label>
        </div>
        <div>
        <div>
          <div className='mb-3'>
            <PickCreationDate popperClassName="custom-popper" creationDate={creationDate} onDateChange={setCreationDate} />
            </div>
          
          <div className='mb-3' >
       
            <h3>Title:</h3>
            <input type="text" className="form-control" required value={title} onChange={(e) => setTitle(e.target.value)} />
         
          </div>
          <div className='mb-3'>
          <h3>Price $:</h3>
            
            <input
  type="number"
  name='price'
  className="form-control"
  required
  value={price}
  onChange={(e) => handleChange(e.target.value,e.target.name)}// Updated change handler
/>
          </div>
          <div className='mb-3'>     
            <h3>Description:</h3>
      <textarea
          className="form-control"
          rows="8"
          name="notes" 
          required value={description} onChange={(e) => setDescription(e.target.value)} />
    
      </div>
          <div className='mb-3' >
          <h3>
            Author:</h3>
            <input type="text" className="form-control" required value={artist} onChange={(e) => setArtist(e.target.value)} />
          
          </div>
          <div className="container">
  <div className="row justify-content-center">
    <div className="col-12 text-center mb-3">
      <h3>-Paint Dimensions (inches)-</h3>
    </div>
    <div className="col-sm">
      <div className="mb-3">
        <label htmlFor="paintLength" className="form-label">L:</label>
        <input 
          type="number" 
          className="form-control" 
          id="paintLength" 
          name="paintLength" 
          required 
          value={paintLength} 
          onChange={(e) => handleChange(e.target.value, e.target.name)} 
        />
      </div>
    </div>
    <div className="col-sm">
      <div className="mb-3">
        <label htmlFor="paintWidth" className="form-label">W:</label>
        <input 
          type="number" 
          className="form-control" 
          id="paintWidth" 
          name="paintWidth" 
          required 
          value={paintWidth} 
          onChange={(e) => handleChange(e.target.value, e.target.name)} 
        />
      </div>
    </div>
    <div className="col-sm">
      <div className="mb-3">
        <label htmlFor="paintHeight" className="form-label">H:</label>
        <input 
          type="number" 
          className="form-control" 
          id="paintHeight" 
          name="paintHeight" 
          required 
          value={paintHeight} 
          onChange={(e) => handleChange(e.target.value, e.target.name)} 
        />
      </div>
    </div>
  </div>
</div>
<div className='mb-3'>     <h3>
          Paint Weight(lbs):
            </h3>
          
            <input type="number" 
            name='paintWeight' 
            required
            className="form-control" 
            value={paintWeight} 
            onChange={(e) => 
              handleChange(e.target.value,e.target.name)} /></div>
     
        
            <div className="container">
  <div className="row justify-content-center">
    <div className="col-12 text-center mb-3">
      <h3>-Package Dimensions (inches)-</h3>
    </div>
    <div className="col-sm">
      <div className="mb-3">
        <label htmlFor="packageLength" className="form-label">L:</label>
        <input 
          type="number" 
          className="form-control" 
          id="packageLength" 
          name="packageLength" 
          required 
          value={packageLength} 
          onChange={(e) => handleChange(e.target.value, e.target.name)} 
        />
      </div>
    </div>
    <div className="col-sm">
      <div className="mb-3">
        <label htmlFor="packageWidth" className="form-label">W:</label>
        <input 
          type="number" 
          className="form-control" 
          id="packageWidth" 
          name="packageWidth" 
          required 
          value={packageWidth} 
          onChange={(e) => handleChange(e.target.value, e.target.name)} 
        />
      </div>
    </div>
    <div className="col-sm">
      <div className="mb-3">
        <label htmlFor="packageHeight" className="form-label">H:</label>
        <input 
          type="number" 
          className="form-control" 
          id="packageHeight" 
          name="packageHeight" 
          required 
          value={packageHeight} 
          onChange={(e) => handleChange(e.target.value, e.target.name)} 
        />
      </div>
    </div>
  </div>
</div>
<div className='mb-3'>     <h3>
          Package+Paint Weight(lbs):
            </h3>
          
            <input type="number" 
            name='packageWeight'
            className="form-control"  
            required
            value={packageWeight} 
            onChange={(e) => 
              handleChange(e.target.value,e.target.name)} /></div>
            <div className='mb-3'>
          <h3>Package Fee $:</h3>
            <input type="number" 
            name='packageFee' 
            className="form-control" 
            required
            value={packageFee} 
            onChange={(e) => 
              handleChange(e.target.value,e.target.name)} />
           </div>
<div className='mb-3'>         
   <h3>Select packaging:</h3>
          <select
            name="freight packaging"
            className="form-select"
            value={packageType}
            required
            onChange={(e)=>setPackageType(e.target.value)}
          >
          <option value=''>Select packaging</option>
          <option value='BOX'>Box</option>
          <option value='CRATE'>Crate</option>
          </select>
          </div>       
          <div className='mb-3'>
            <h3>Condition:</h3>
                       
            <input type="text" className='form-control' value={condition} onChange={(e) => setCondition(e.target.value)} />
            
            </div>        
        </div>
        <div className='mb-3'>
          <h3>Availability:</h3>
          <select
            name="availability"
            className='form-select'
            value={availability}
            required
            onChange={(e)=>setAvailability(e.target.value)}
          >    
            <option value="available">available</option>
            <option value="unavailable">unavailable</option>    
          </select> 
        </div>
        <div className='text-center mb-6'>
          <button type="submit" className='btn btn-primary'>
            C r e a t e
          </button>
        </div>
        </div>
      </form>                  

  </div>
  );
}

export default CproductExp;


