import {Api} from '../utils/api';
import SetSpotsDay from '../components/Admin/Setdates/SetSpotDay';
import Auth from "../utils/auth";
import Loginmessage from "../components/Admin/Loginmessage";
import React, { useState, useEffect } from 'react'; // Add missing import statement for useEffect

const Setdates = () => {

  const [isPLoading, setPIsLoading] = useState(false);
  const [errorPfound, setErrorPfound]=useState(false)
  const [errorUnauth, setErrorAuth]=useState(false)
  const [availabilityMonday, setAvailabilityMonday] = useState({day: 1,spots: []})
  const [availabilityTuesday, setAvailabilityTuesday] = useState({day: 2, spots: []})
  const [availabilityWednesday, setAvailabilityWednesday] = useState({day: 3,spots: []})
  const [availabilityThursday, setAvailabilityThursday] = useState({day: 4, spots: []})
  const [availabilityFriday, setAvailabilityFriday] = useState({day: 5, spots: []})  
  const [availabilitySaturday, setAvailabilitySaturday] = useState({day: 6, spots: []})
  const [availabilitySunday, setAvailabilitySunday] = useState({day: 0, spots: []})
  const [refetch, setRefetch] = useState(false)

  useEffect(() => {
    const fetchSchedule = async () => {
      setPIsLoading(true);
      try {
        const response = await Api.get('/getschedule', {
          headers: {
            Authorization: `${localStorage.getItem('id_token')}`,
          },
        });
  
        if (response && response.data) {
          if (response.data.length > 0) {
            // Retrieve schedule for each day
            response.data.forEach(dayData => {
              switch (dayData.day) {
                case "1":
                  setAvailabilityMonday(prevState => ({ ...prevState, spots: dayData.spots }));
                  break;
                case "2":
                  setAvailabilityTuesday(prevState => ({ ...prevState, spots: dayData.spots }));
                  break;
                case "3":
                  setAvailabilityWednesday(prevState => ({ ...prevState, spots: dayData.spots }));
                  break;
                case "4":
                  setAvailabilityThursday(prevState => ({ ...prevState, spots: dayData.spots }));
                  break;
                case "5":
                  setAvailabilityFriday(prevState => ({ ...prevState, spots: dayData.spots }));
                  break;
                case "6":
                  setAvailabilitySaturday(prevState => ({ ...prevState, spots: dayData.spots }));
                  break;
                case "0":
                  setAvailabilitySunday(prevState => ({ ...prevState, spots: dayData.spots }));
                  break;
                default:
                  break;
              }
            });
          }
          setPIsLoading(false);
        }
      } catch (error) {
        setPIsLoading(false);
        if (error.response && error.response.status === 401) {
          setErrorAuth(true)
        } else {
          setErrorPfound(true);
          
        }
      }
    };
  
    fetchSchedule();
  }, []);
  useEffect(() => {
    const fetchSchedule = async () => {
      setPIsLoading(true);
      try {
        const response = await Api.get('/getschedule', {
          headers: {
            Authorization: `${localStorage.getItem('id_token')}`,
          },
        });
  
        if (response && response.data) {
          if (response.data.length > 0) {
            // Retrieve schedule for each day
            response.data.forEach(dayData => {
              switch (dayData.day) {
                case "1":
                  setAvailabilityMonday(prevState => ({ ...prevState, spots: dayData.spots }));
                  break;
                case "2":
                  setAvailabilityTuesday(prevState => ({ ...prevState, spots: dayData.spots }));
                  break;
                case "3":
                  setAvailabilityWednesday(prevState => ({ ...prevState, spots: dayData.spots }));
                  break;
                case "4":
                  setAvailabilityThursday(prevState => ({ ...prevState, spots: dayData.spots }));
                  break;
                case "5":
                  setAvailabilityFriday(prevState => ({ ...prevState, spots: dayData.spots }));
                  break;
                case "6":
                  setAvailabilitySaturday(prevState => ({ ...prevState, spots: dayData.spots }));
                  break;
                case "0":
                  setAvailabilitySunday(prevState => ({ ...prevState, spots: dayData.spots }));
                  break;
                default:
                  break;
              }

            });

            setPIsLoading(false);
          }
        }
      } catch (error) {
        setPIsLoading(false);
        if (error.response && error.response.status === 401) {
          setErrorAuth(true)
        } else {
          setErrorPfound(true);
          
        }
      }finally{
        setRefetch(false)
      }
    };
  if(refetch===true)
    fetchSchedule();
  }, [refetch]);

  

  const handleSubmit = async () => {
    let alertmessage=''
    try {
      // Filter out the days with no spots and spots with incomplete times
      const daysWithSpots = [
        availabilityMonday,
        availabilityTuesday,
        availabilityWednesday,
        availabilityThursday,
        availabilityFriday,
        availabilitySaturday,
        availabilitySunday,
      ].filter(day => day.spots.length > 0 && day.spots.every(spot => spot.starthour && spot.endhour));
      
      // Make the POST request
      const response = await Api.post('/availabilitydates', daysWithSpots, {
        headers: {
        Authorization: `${localStorage.getItem('id_token')}`,
        },
      });
      if (response && response.data) {
        alert(`${response.data.message}`)
      }
  
      
    } catch (error) {
   
      if (error.response && error.response.status === 401) {
        setErrorAuth(true)
      }
      if(error.response && error.response.data.message==='schedule updated less than 8 minutes ago'){
        alertmessage='schedule updated less than 8 minutes ago'
      }else if (error.response && error.response.data.message==='a user has selected a time slot concurrently. Try again later'){
        alertmessage='a user has selected a time slot concurrently. Try again later'
      }
    }finally{
      if(alertmessage!==''){
        alert(`${alertmessage}`)
        setRefetch(true)
      }
      alertmessage=''
    }
  };


  if(isPLoading){
    return   ( <div className="text-light text-center custom6 custom4" style={{paddingTop:'260px'}}>
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>)
  }

  if(errorUnauth||!Auth.loggedIn){
    return(
      <Loginmessage/>
    )
  }

  if (errorPfound) {
    return (
      <div className='text-light text-center custom4 custom6'>
        an error has occurred on our side. Please try again later
      </div>
    );
  }

  
  return (
    <div>
      
    <div className='custom6 custom4'>
      <SetSpotsDay dayName="Monday" availability={availabilityMonday} setAvailability={setAvailabilityMonday} />
      <SetSpotsDay dayName="Tuesday" availability={availabilityTuesday} setAvailability={setAvailabilityTuesday} />
      <SetSpotsDay dayName="Wednesday" availability={availabilityWednesday} setAvailability={setAvailabilityWednesday} />   
      <SetSpotsDay dayName="Thursday" availability={availabilityThursday} setAvailability={setAvailabilityThursday} />  
      <SetSpotsDay dayName="Friday" availability={availabilityFriday} setAvailability={setAvailabilityFriday} />  
      <SetSpotsDay dayName="Saturday" availability={availabilitySaturday} setAvailability={setAvailabilitySaturday} />  
      <SetSpotsDay dayName="Sunday" availability={availabilitySunday} setAvailability={setAvailabilitySunday} />
    </div>
      <button className='btn btn-primary mx-2 mb-2' onClick={handleSubmit}>Save</button>
      <button className='btn btn-primary mx-2 mb-2' onClick={()=>setRefetch(true)}>Cancel</button>
    </div>
  );
};

export default Setdates;