import React, { useEffect, useState } from 'react';
import { Alldata,Api } from '../utils/api';
import Datesdiv from '../components/Gallery/Lessondates/Datesdiv';
import {useNavigate } from 'react-router-dom';
import Canonical from '../components/Canonical'

function Booking(
  {
    navigatemenuopen,
    setppalk,
    setLessonOrder,
    setOrderComplete
}
) {
  const navigate = useNavigate(); 
  const [dates, setDates] = useState([]);
  const [isPLoading, setPIsLoading] = useState(true);
  const [datesSelArr, setDatesSelArr] = useState([]);
  const [isLPLoading, setLPIsLoading] = useState(true);
  const [errorPfound, setErrorPfound]=useState(false)
  const [isincomplete, setisincomplete]=useState(true)
  const [errorlfound, setErrorlfound]=useState(false)
  const [feeHlesson, setFeeHlesson] = useState('');
  const [lesson, setLesson] = useState('');

  const[ppalstr, setppalstr]=useState('')
  const [loadingppal, setLoadingppal] = useState(false);
  const[errFoundppl, setErrFoundppal]=useState(false)

  const [userFormDatalesson, setUserFormDatalesson] = useState({name:'', email:'',phone:''});

  useEffect(() => {
    setLoadingppal(true);
    const fetchpplk = async () => {
   
     try {
       const response = await Api.get('/ppalclt')
       if (response && response.data) {
          setppalstr(response.data)
          setLoadingppal(false)
       }
     }catch(error){
      setLoadingppal(false)
      setErrFoundppal(true);
     }
    };

   fetchpplk();
 }, []);  


useEffect(() => { 

  const datestopass = datesSelArr.filter((t) => t.date !== ''&&t.timeslots.length>0)
  if(datestopass.length>0){
    setisincomplete(false)
  }else{
    setisincomplete(true)
  
  }

},[datesSelArr])

  useEffect(() => {
    const fetchDates = async () => {
      try {
        const response = await Alldata.get('/getdatesavailable');

        if (response && response.data) {
          setDates(response.data);
          setPIsLoading(false)
        }

      } catch (error) {
        setPIsLoading(false);
                 setErrorPfound(true);
          
      }
    };

    fetchDates();
  }, []);

  useEffect(() => {

    const fetchLessonHour = async () => {
      try {
        const response = await Alldata.get('/getlessonfee');
        if (response && response.data) {
          setFeeHlesson(response.data.feelessonvalue);
          setLPIsLoading(false)
        }

      } catch (error) {
        setLPIsLoading(false);
        setErrorlfound(true);
        
      }
    };

    fetchLessonHour();
  }, []);

  useEffect(() => {
    setLesson('')
 let arrOrdertoPass = datesSelArr.filter((t) => t.date !== ''&&t.timeslots.length>0)
 
if(arrOrdertoPass.length>0&&feeHlesson!==''&&userFormDatalesson.name!==''&&userFormDatalesson.email!==''&&userFormDatalesson.phone!==''){ 
let total = 0
let totalHours = 0
  const totalarr = arrOrdertoPass.map((element) => {
    let totalts = 0
    let totalhrs = 0
    let diff = 0
    let ts = element.timeslots
    let mapts = []

    if(ts.length>0){
   mapts = ts.map((t) => { 
      let a=0
      let b=0
      let feehour=0
     a = parseFloat(t.startingtimeMill)*1
     b = parseFloat(t.endingtimeMill)*1
    
     diff = (b-a)/(60*60*1000)   
     feehour = parseFloat(feeHlesson)*diff
    
     return {feeHour:feehour, hours:diff}
    })

   }

     if(mapts.length>0){
      for (let index = 0; index < mapts.length; index++) {
        totalts = mapts[index].feeHour+ totalts
        totalhrs = mapts[index].hours+ totalhrs
    }
  }
  return {Totalel:totalts, TotaLhours:totalhrs}
})


if(totalarr.length>0){
  for (let index = 0; index < totalarr.length; index++) {
    total = (totalarr[index].Totalel*1)+total
    totalHours = (totalarr[index].TotaLhours*1)+totalHours
  }
  // 
  setLesson({total:total, hours:totalHours, lessonfee:feeHlesson, timeslots:arrOrdertoPass, ...userFormDatalesson})
  
}
}else{
  setLesson('')
}
  }, [userFormDatalesson, datesSelArr, feeHlesson]);


  const handleInputChange = (event) => {
    
    const { name, value } = event.target;
    setUserFormDatalesson({ ...userFormDatalesson, [name]: value });
  };

 const handleFormSubmit = async () => {
   
  if(lesson){
    setOrderComplete(false)
    setppalk(ppalstr)
    setLessonOrder({...lesson, numberofstudents:1})


    navigate('/Paymentlesson')
  }
  }


  if(isPLoading||isLPLoading||loadingppal){
    return(
      <div>
{!navigatemenuopen&&<div className='text-light' style={{paddingTop:'150px',minHeight:'100vh'}}>Loading...
     </div>}
     </div>
     )
  }

  if (errorPfound||errorlfound||errFoundppl) {
    return (
      <div>
{!navigatemenuopen&&<div className='classminheight text-center text-light' style={{paddingTop:'200px'}}>
        An error has occurred on our side. Please try again later
      </div>}
      </div>
    );
  }
  if (dates.length===0||feeHlesson==='') {
    return (
      <div>
{!navigatemenuopen&&<div className='classminheight text-center text-light' style={{paddingTop:'200px'}}>
        <h5>Lesson booking is not available at the moment. Please try again later</h5>
      </div>}
      </div>
    );
  }

  return (
    <div>
      <Canonical url="https://www.mylovepainting.com/booking" />
  {!navigatemenuopen&&<div className="container bg-dark text-light py-3">
              
            <h3>Contact Info</h3>
            <div className="mb-3">
              <label htmlFor="nameInput" className="form-label">Name:</label>
              <input 
                type="text"
                className="form-control"
                id="nameInput"
                placeholder="Enter Full Name"
                name="name"
                required
                onChange={handleInputChange}
                value={userFormDatalesson.name}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="emailInput" className="form-label">Email:</label>
              <input 
                type="email" // Changed to email for validation
                className="form-control"
                id="emailInput"
                placeholder="example@myemail.com"
                name="email"
                required
                onChange={handleInputChange}
                value={userFormDatalesson.email}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phoneInput" className="form-label">Phone#:</label>
              <input 
                type="tel" // Changed to tel for validation
                className="form-control"
                id="phoneInput"
                placeholder="Phone Number"
                name="phone"
                required
                onChange={handleInputChange}
                value={userFormDatalesson.phone}
              />
            </div>
              <Datesdiv
              datesSelArr={datesSelArr}
               setDatesSelArr={setDatesSelArr}
               dates={dates} 
               setDates={setDates}
              />
            
<div className="mb-2 mt-2 text-center">
{feeHlesson&&<h5>Fee:${feeHlesson}CAD/hour</h5>}
{lesson&&<h5>Total hours:{lesson.hours}</h5>}
{lesson&&<h5>Total:${lesson.total}</h5>}
</div>

            <button disabled={
            userFormDatalesson.name===''
            ||userFormDatalesson.email===''
            ||userFormDatalesson.phone===''
          ||isincomplete
          ||lesson===''
            } 
            type="button" className="btn btn-primary mt-3" onClick={handleFormSubmit}>
              Proceed Payment
            </button>
         
        </div>}
        </div>
  
  );
}

export default Booking;
