import React, { useEffect, useState } from 'react';
import { Api } from '../utils/api';
import Auth from "../utils/auth";
import AllMyeventsadmin  from '../components/Admin/Myeventsadmin/AllMyeventsadmin';
import Loginmessage from "../components/Admin/Loginmessage";
import {useNavigate } from 'react-router-dom';

function Products() {
  const navigate = useNavigate();
  const [myEvents, setMyEvents]=useState([])
  const [isPLoading, setPIsLoading] = useState(true);
  const [fetchStatus, setFetchStatus] = useState(false);
 
  const [errorPfound, setErrorPfound]=useState(false)
  const [errorUnauth, setErrorAuth]=useState(false)

  useEffect(() => {

    const fetchEvents = async () => {

      try {
        const response = await Api.get('/geteventsadmin', {
          headers: {
            Authorization: `${localStorage.getItem('id_token')}`
          }
        });

        if (response && response.data) {
          setMyEvents(response.data);
          setPIsLoading(false)
        }

      } catch (error) {
        setPIsLoading(false);
        if (error.response && error.response.status === 401) {
          setErrorAuth(true)
        } else {
          setErrorPfound(true);
          
        }
      }
    };

    fetchEvents();
  }, []);
  useEffect(() => {

    const refetchEvents = async () => {
      setPIsLoading(true)
      try {
        const response = await Api.get('/geteventsadmin', {
          headers: {
            Authorization: `${localStorage.getItem('id_token')}`
          }
        });

        if (response && response.data) {
          setMyEvents(response.data);
          setPIsLoading(false)
        }

      } catch (error) {
        setPIsLoading(false);
        if (error.response && error.response.status === 401) {
          setErrorAuth(true)
        } else {
          setErrorPfound(true);
          
        }
      }finally{
        setFetchStatus(false)
      }
    };
if(fetchStatus===true){
  refetchEvents()
}
    ;
  }, [fetchStatus]);


  if(isPLoading){
    return   ( <div className="text-light text-center custom6 custom4" style={{paddingTop:'260px'}}>
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>)
  }

  if(errorUnauth||!Auth.loggedIn){
    return(
      <Loginmessage/>
    )
  }

  if (errorPfound) {
    return (
      <div className='text-light text-center custom4 custom6'>
        an error has occurred on our side. Please try again later
      </div>
    );
  }

  if (!errorUnauth&&!errorPfound&&Auth.loggedIn&&myEvents && myEvents.length === 0) {
    return (<div className='text-light custom4 custom6'>
    <div >
      <div className='text-center my-3'>
      <button
      className="btn btn-primary"
        type="button"
        onClick={() => {
          navigate("/createmyeventsadmin")
        }}
      >
      Create New Event
      </button>
    </div>
    <div className='text-center my-3'>NO EVENTS FOUND</div>
      </div>
    </div>
  
)
  }

  return (
    <div className='text-light custom4 custom6' >
      {!errorUnauth&&!errorPfound&&Auth.loggedIn&&myEvents&&<AllMyeventsadmin 
      myEvents = {myEvents} 
      setErrorAuth={setErrorAuth}
      errorUnauth={errorUnauth}
      setFetchStatus={setFetchStatus}
      />}
    </div>
  );
}

export default Products;