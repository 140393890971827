export function Checknegative(number,dim) {
   let v;
   
   if(parseFloat(number)<=0){
        v=dim
   } else {
        v=number
   }

   return v 
}










