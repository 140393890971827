import React, { useEffect, useState } from 'react';
import { Api} from '../utils/api';

import Loginmessage from "../components/Admin/Loginmessage";
import Allorders from "../components/Admin/Orders/Orderlist";

function Orders() {

  const [fetchStatus, setFetchStatus] = useState(true);
  const [orders, setOrders]=useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [errorfound, setErrorfound]=useState(false)

  useEffect(() => {

    const fetchOrders = async () => {
      try {
        const response = await Api.get('/myorders', {
          headers: {
            Authorization: `${localStorage.getItem('id_token')}`
          }
        });

        if (response && response.data) {
          setOrders(response.data);
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error
          setFetchStatus(false);
        } else {
        // Handle other errors
          setErrorfound(true)
          
        }
      }
    };

    fetchOrders();
  }, []);

  if(!fetchStatus){
    return(
      <Loginmessage/>
    )
  }

  if(isLoading){
    return(
      <div className='custom6 text-light custom4'>Loading...</div>
    )
  }

  if(errorfound){
    return(
      <div className='text-center text-light custom6 custom4'>An error has occurred on our side, try again later</div>
    )
  }

  return (
    <div className='custom6 custom4 text-light'>
      {orders.length>0? (
       <div > <Allorders orders = {orders} errorfound={errorfound} isLoading={isLoading}/></div>
      ) : (
      <div className='text-center'> NO ORDERS RECEIVED YET</div>
      )} 
    </div>
  );
}

export default Orders;