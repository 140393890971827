import React from 'react';

function Confirmresetpwd({ isOpen, message, handleEmail, onConfirm, onCancel, emailResetPwd, setEmailResetPwd}) {
    
  if (!isOpen) return null;
  
    return (
      <div className="confirmation-dialog">

        <p>{message}</p>

        <div className="mb-3">
            <input 
                className="form-control form-control-md mb-3"
                type="email"
                placeholder="name@example.com"
                name="email"
                onChange={handleEmail}
                value={emailResetPwd}
            />
        </div>
         <button onClick={onConfirm}>Submit</button>
    
        <button onClick={onCancel}>Cancel</button>
      </div>
    );
  
}

export default Confirmresetpwd;