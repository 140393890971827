import React from 'react';
import { Link } from 'react-router-dom';

function Orderlist({
  orders,
}) 
{

  const formatDate = (dateString) => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    const date = new Date(dateString);
    const dayName = days[date.getDay()];
    const monthName = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear().toString().slice(-2); // Get last two digits of year
  
    return `${dayName}, ${monthName} ${day}/${year}`;
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
<div className="container custom4 mt-2">
{orders && orders.length>3&&<code className='up' style={{ cursor: 'pointer'}} onClick={scrollToTop}>&#x21ea;</code>}
  <div className="row row-cols-1 row-cols-md-3 g-4">
    {orders && orders.map(order => (
      <div key={order._id} className="col">
        <div className="card border-success h-100 text-bg-dark">
          <div className="card-body">
            <h5 className="card-title">Order# {(order._id).slice(17)}</h5>
            <p className="card-text"><strong>Address:</strong> {order.address}</p>
            <p className="card-text"><strong>Items purchased:</strong> {order.goods.length}</p>
            <p className="card-text"><strong>Total purchase:</strong> ${order.total} (shipping included)</p>
            <p className="card-text"><strong>Date:</strong> {formatDate(order.createdAt)}</p>
            {order.refnum1!==null&&order.refnum1!==undefined&&order.refnum1!==''&&order.refnum2!==null
        &&order.refnum2!==undefined&&order.refnum2!==''?
        (<p className="card-text text-success"><strong>Status:</strong> completed</p>):
        (<p className="card-text text-danger"><strong>Status:</strong> unsuccessfull</p>)}
          </div>
          <div className="card-footer">
            <Link to={`/${order._id}`} className="btn btn-primary">View Order</Link>
          </div>
        </div>
      </div>
    ))}
  </div>
</div>

  );
}

export default Orderlist;