import React from 'react';
import {PayPalButtons} from "@paypal/react-paypal-js";
import {  useRef } from "react";
import {Api} from "../../../utils/api";
import {useNavigate } from 'react-router-dom';

const ButtonWrapper = ({
  currency,
  order,
  setIstimeout,
  istimeout,
  packagingDUpdts,
  paintDUpdts,
}) => {

  const navigate = useNavigate();
  const timeoutId = useRef(null)
  const orderobj = useRef(order);
  const orderobjdes = useRef(order.description);
  const preorder = useRef('');

  const handleCancel= async () => {
  return window.location.replace('/');
  }

  const handleTimeOut = async() => {
  setIstimeout(true)
  }

  const handleInitiation=async (b) => { 
    try {
    let myorder = orderobj.current
    const paints = {paints:myorder.goods, paintDUpdts:paintDUpdts, packagingDUpdts:packagingDUpdts}

    const response = await Api.post('/avalpaintings',paints);

    if (response && response.status===200) {
    const { description, ...preOrderObj} = orderobj.current;
    const preOrder = { ...preOrderObj, refnum1: b };
    preorder.current = preOrder
    }else{
    orderobj.current=''
    setIstimeout(true)
    alert('An error ocurred and your order could not be processed')
    }

    } catch (error) {
    setIstimeout(true)
    alert('An error ocurred and your order could not be processed')
    }
  }

  const handleCapture = async (a) => {   
  const order = { ...preorder.current, refnum2: a };
  try {
  const response = await Api.post('createorder',order);
  if (response && response.data) {
    const orderUrl = response.data.confirmation;
    // Redirect the user to the Google Cloud URL
    window.location.href = orderUrl;

  navigate('/Confirmation')
  }
  } catch (error) {
  alert('An error ocurred and your order could not be processed.Please claim a refund if any transaction has been made. ')

  }
  };

  return (

    <div style={{minHeight:'90vh', position:'relative'}}>
    <div className=' text-center mt-1 text-light px-1'>
<h5>After your purchase is approved, a new tab will open displaying your order details in a printable PDF format. Additionally, a PDF file containing your order details will be sent to your email.</h5>
</div>
<div className='text-center mt-3'>
<button className="btn btn-secondary mb-3" style={{ cursor: 'pointer' }} onClick={handleCancel}>Cancel Order</button>
</div>
  {packagingDUpdts&&paintDUpdts&&order&&istimeout===false&&<div className='ppaldiv'>
  <PayPalButtons
    forceReRender={[orderobj, istimeout, currency]}
    createOrder={(data, actions) => {
      return actions.order
      .create({
                purchase_units: [
                  {
                    amount: {
                      currency_code: currency,
                      value: order.total,
                    },
                    description: `painting(s):${orderobjdes.current}`,
                  },
                ],
                application_context: {
                  shipping_preference: "NO_SHIPPING",
                },
              })
      .then((orderId) => {

        setTimeout(() => {
          handleTimeOut()
        },8*60*1000)

        handleInitiation(orderId)

        return orderId;
      });
  }}
  onApprove={(data, actions) => {
    return actions.order.capture().then(() => {
    clearTimeout(timeoutId.current)
    const validate=data.payerID
    handleCapture(validate);
    });
  }}
  />
  </div>}
  </div>
  );
}

export default ButtonWrapper;
