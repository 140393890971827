import React from 'react';
import Spot from "./Spot";

const SetSpotsDay = ({
    dayName,
    availability,
    setAvailability,
}) => {
  const addSpot = () => {
    const updatedSpots = [
      ...availability.spots,
      { starthour: '', endhour: '' },
    ];
    setAvailability({ ...availability, spots: updatedSpots });
  };

  const removeSpot = (spotIndex) => {
    const updatedSpots = [...availability.spots];
    updatedSpots.splice(spotIndex, 1);
    setAvailability({ ...availability, spots: updatedSpots });
  };

  

  return (
<div className="card bg-dark text-light my-2 mx-2 border border-info border-1">
  <div className="card-body">
    <h2 className="card-title">{dayName}</h2>
    {availability.spots && availability.spots.map((spot, spotIndex) => (
      <div key={spotIndex} className="mb-3 border-bottom">
        <div className="d-flex align-items-center justify-content-between  pb-2">
          <Spot spotstart={spot.starthour} spotend={spot.endhour} spotIndex={spotIndex} availability={availability} setAvailability={setAvailability} />
          
        </div>
        <button className="btn btn-danger" onClick={() => removeSpot(spotIndex)}>Remove</button>
      </div>
    ))}
    <button className="btn btn-primary" onClick={addSpot}>Add Spot</button>
  </div>
</div>




  
  );
};

export default SetSpotsDay;

