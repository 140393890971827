import React, { useEffect, useState } from 'react';

import {Api} from "../../../utils/api";

function Shippingopt({            
  setSelectFdxSerFee, 
  setSelectFdxSerName,
  setPreOrder,
  setPaintingTotal,
  setpackagingTotal, 
  selObjAddress, 
  selAddress, 
  productsOrder,
  setpackagingDUpdts,
  setPaintDUpdts,
  order,
  selectFdxSerFee,
  paintingTotal,
  packagingTotal
}) {
  const [classification, setClassification] = useState('');
  const [fedexShippingOptions, setFedexShippingOptions] = useState('');
  const [selectServicelist, setSelectService] = useState('');
  const [errorFetching, setErrorFetching] = useState(false);
  const [errorApiNoAval, setErrorApiNoAval] = useState('');
  const [loadingResponse, setLoadingResponse] = useState(false);

useEffect(() => {
  setErrorApiNoAval('')
  setLoadingResponse(false)
  setErrorFetching(false)
  setFedexShippingOptions('')
  setPreOrder('')
  setPaintingTotal('')
  setpackagingTotal('')

  if(selObjAddress&&productsOrder&&classification){
    const Input= {address:{        
      "streetLines":selObjAddress.streetLinesToken,
      "city": selObjAddress.city,
      "stateOrProvinceCode": selObjAddress.stateOrProvinceCode,
      "postalCode": selObjAddress.parsedPostalCode.base,
      "classification":selObjAddress.classification,
      "countryCode": selObjAddress.countryCode},
      paintings:productsOrder,
      classification:classification
      }
      

    const fetchFedexDelivery = async () => {
      setLoadingResponse(true)
      try {
        const response1 = await Api.post("/fedexapi/fdxopts", Input)
   

        if (response1 && response1.data) {
          
          setErrorFetching(false)
  setFedexShippingOptions(response1.data.shipping.output.rateReplyDetails)
  setPreOrder(response1.data.preOrder)
  setPaintingTotal(response1.data.subtotal)
  setpackagingTotal(response1.data.preOrder.packageFee)
  setpackagingDUpdts(response1.data.pckUpdtT)
  setPaintDUpdts(response1.data.pntsUpdtT)
  setLoadingResponse(false)
 
        }else{
          setErrorFetching(true)
          setFedexShippingOptions('')
          setLoadingResponse(false)
        }
      } catch (error) {
        setFedexShippingOptions('')
        setErrorFetching(true)
        setLoadingResponse(false)
        if (error.response.data&&error.response.data.message) {
        setErrorApiNoAval(error.response.data.message);
        }
       
      }
    };
    fetchFedexDelivery()
  
  }
  ;

}, [selObjAddress, productsOrder, classification]);

const handleSelectService = (event) => {
 
  const selection = event.target.value;


    setSelectService(selection); 
    const objservice = fedexShippingOptions.find((serv) => serv.serviceName === selection);
    setSelectFdxSerName(objservice.serviceName); 
    setSelectFdxSerFee(objservice.ratedShipmentDetails[1].totalNetCharge);
 
};

return(
<div>
  {selAddress &&
    <div className="mb-3">
      <label htmlFor='classification' className="form-label">Is this location residential or business:</label>
      <select
        id='classification'
        name='classification'
        className="form-select"
        required
        value={classification}
        onChange={(e) => {
          setClassification(e.target.value);
        }}
      >
        <option value=''>Select an option</option>
        <option value='residential'>Residential</option>
        <option value='business'>Business</option>
      </select>
    </div>
  }

  <h3 className="mb-3">Select Delivery Option</h3>
  {loadingResponse &&
    <div className="text-light text-center" >
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
  }
  {fedexShippingOptions && fedexShippingOptions.map((option, index) => (
    <div className="form-check pb-1" key={index}>
      <input
        className="form-check-input"
        type='radio'
        name="deliveryOption"
        id={`deliveryOption${index}`}
        required
        value={option.serviceName}
        checked={selectServicelist === option.serviceName}
        onChange={handleSelectService}
      />
      <label className="form-check-label" htmlFor={`deliveryOption${index}`}>
        {`${option.serviceName}: $${option.ratedShipmentDetails[1].totalNetCharge} ${option.ratedShipmentDetails[1].currency}`}
      </label> 
    </div>
  ))}

{errorFetching &&
    <div>
      {errorApiNoAval!==''&&errorApiNoAval==='Api no available'? (
       <div className='text-center pt-3'><h4 className='pt-3 text-danger'>FEDEX RATES NOT AVAILABLE. TRY AGAIN LATER</h4> </div>
      ) : (
      <div className="alert alert-warning mt-3 pb-2" role="alert"> No delivery available for the selected ({classification}) address. </div>
      )}
     
    </div>
  }

  {order&&fedexShippingOptions&&selectFdxSerFee&&paintingTotal&&<div className='text-center pt-3'>
    <h4>shipping fee: ${selectFdxSerFee}</h4>
    {packagingTotal&&<h4>packaging fee: ${packagingTotal}</h4>}
    <h4>paintings total: ${paintingTotal}</h4>
    <h3>Order Total: ${order.total} CAD</h3>
    <a className='pt-3 text-warning' href="https://www.fedex.com/en-ca/shipping-services.html" target="_blank" rel="noopener noreferrer">click here to see Fedex delivery times</a>

    </div>}

</div>

 
)

}

export default Shippingopt;

