import React from 'react';
// import Slider from 'react-slick';
import Auth from "../../../utils/auth";
import Singleeventadmin from './Singleeventadmin'


function Eventadminlist(
{ 
  myEvents,
  activeEventIndex,
  setActiveEventIndex,
activeSingleEventIndex,
 setActiveSingleEventIndex,
 setErrorAuth,
 setFetchStatus
}) 
{

  const handleMyEventClick = (id) => {
    if(!Auth.loggedIn){
      setErrorAuth(true)
    }else{
      if (activeEventIndex === id) {
        setActiveEventIndex(null);
        setActiveSingleEventIndex(null);
      } else {
        setActiveEventIndex(id);
        setActiveSingleEventIndex(id);
      }
    }
 
  };

  return (
   
<div className="container mt-3 pb-2">
  <div className="row row-cols-1 g-4 justify-content-center">
    {myEvents && myEvents.map((element, index) => (
      <div key={index} className="col d-flex justify-content-center">
        <div className="card h-100" style={{ maxWidth: '700px', width: '100%' }}>
          {activeEventIndex !== element._id &&<img src={element.pics && element.pics[0]} className="card-img-top" alt={`event ${index}`} onClick={() => handleMyEventClick(element._id)} style={{ cursor: "pointer", width: '100%', height: 'auto' }}/>}
          <div className="card-body px-1">
          {activeEventIndex !== element._id &&<h5>tickets sold:{element.tickets_sold} / {element.numberofPeople}</h5>}
            {activeEventIndex !== element._id &&<h5 className="card-title" onClick={() => handleMyEventClick(element._id)} style={{ cursor: "pointer" }}>{element.title}</h5>}
            {activeEventIndex === element._id &&<div className="card-title text-center"><button className="btn btn-primary" onClick={() => handleMyEventClick(element._id)}>GO BACK</button></div>}
            {activeEventIndex === element._id && (
              <Singleeventadmin 
              id={activeSingleEventIndex} 
              setErrorAuth={setErrorAuth}
              setFetchStatus={setFetchStatus}
            />
            )}
          </div>
        </div>
      </div>
    ))}
  </div>
</div>


  );
}

export default Eventadminlist;

