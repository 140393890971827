import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import ButtonWrapperlesson from "./ButtonWrapperlesson";

function PayPalWrapper({
  ppalk,
  lessonOrder,
  istimeout,
  setIstimeout,
  setLessonOrder,
  setOrderComplete
}) {
 const currency = 'CAD'
 if (!ppalk) {
  // ppalk not provided or is an empty string
  return <div className='text-light' style={{paddingTop:'150px',minHeight:'100vh'}}>Loading...</div>;
} else {
  // ppalk is provided
  return (
    <PayPalScriptProvider
      options={{
        "client-id": ppalk,
        components: "buttons",
        currency: "CAD"
      }}
    >
      <ButtonWrapperlesson
        currency={currency}
        lessonOrder={lessonOrder}
        setIstimeout={setIstimeout}
        istimeout={istimeout}
        setLessonOrder={setLessonOrder}
        setOrderComplete={setOrderComplete}
     
      />
    </PayPalScriptProvider>
  );
}

 
}

export default PayPalWrapper;
