import React, { useEffect, useState } from 'react';
import { Api } from '../utils/api';
import { Checknegative } from '../utils/Checknegative'
import Loginmessage from "../components/Admin/Loginmessage";


function Packaginset() {

  const [cratePriceVolIn, setCratePriceVolIn] = useState('');
  const [crateDensityLbIn, setCrateDensityLbIn] = useState('');
  const [boxPriceVolIn, setBoxPriceVolIn] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const [fetchStatus, setFetchStatus] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [errorfound, setErrorfound]=useState(false)

  useEffect(() => {
    const fetchPackagingData = async () => {

      try {
        const response = await Api.get('/getpackaging', {
          headers: {
            Authorization: `${localStorage.getItem('id_token')}`,
          },
        });

        if (response && response.data) {
          setCratePriceVolIn(response.data.cratePriceVolIn|| '');
          setCrateDensityLbIn(response.data.crateDensityLbIn|| '');
          setBoxPriceVolIn(response.data.boxPriceVolIn|| '')
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error
          setFetchStatus(false);
        } else {
        // Handle other errors
          setErrorfound(true)
          
        }
        }
      }
    fetchPackagingData()

  }, []);

  useEffect(() => {
    const reFetchPackagingData = async () => {

      try {
        const response = await Api.get('/getpackaging', {
          headers: {
            Authorization: `${localStorage.getItem('id_token')}`,
          },
        });

        if (response && response.data) {
          setCratePriceVolIn(response.data.cratePriceVolIn|| '');
          setCrateDensityLbIn(response.data.crateDensityLbIn|| '');
          setBoxPriceVolIn(response.data.boxPriceVolIn|| '')
        }
      } catch (error) {
        setIsLoading(false)
        if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error
          setFetchStatus(false);
        } else {
        // Handle other errors
          setErrorfound(true)
          
        }
      }finally{
        setSubmitSuccess(false)
      }
    };

    if(submitSuccess===true){
      reFetchPackagingData()
    }
    
  }, [submitSuccess]);


  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    let setcratePriceVolInVal = Checknegative(cratePriceVolIn,'Crate cost')
    let setcrateDensityLbInVal = Checknegative(crateDensityLbIn,'Crate Density')
    let setboxPriceVolInVal = Checknegative(boxPriceVolIn,'Box cost')

    if(setcratePriceVolInVal==='Crate cost'){
      setIsLoading(false)
      return alert('"Crate cost" must be bigger than 0')
    }else if(setcrateDensityLbInVal==='Crate Density'){
      setIsLoading(false)
      return alert('"Crate Density" must be bigger than 0')
    }else if(setboxPriceVolInVal==='Box cost'){
      setIsLoading(false)
      return alert('"Box cost" must be bigger than 0')
    }

    try {

      const packagingdata = {
        cratePriceVolIn, 
        crateDensityLbIn, 
        boxPriceVolIn
      };

      const response = await Api.post('/packaging', packagingdata,
        {
          headers: {
            Authorization: `${localStorage.getItem('id_token')}`,
            },
        } 
      );

      if (response.status === 200) {
        setSubmitSuccess(true)
        setIsLoading(false)
        alert('Packaging data was updated');
       } else {
        setIsLoading(false)
        alert('Failed to update packaging data');
      }

    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.status === 401) {
      // Handle 401 Unauthorized error
        setFetchStatus(false);
      } else {
      // Handle other errors
        setErrorfound(true)
        
      }
    }
  };

  if(!fetchStatus){
    return(
      <Loginmessage/>
    )
  }

  if(isLoading){
    return(
      <div className='text-light custom6 custom4'>Loading...</div>
    )
  }

  if(errorfound){
    return(
      <div className='text-light custom4 custom6'>An error has occurred on our side, try again later</div>
    )
  }

  return (
    <div className='text-light custom4 px-2 custom6'>
          <form onSubmit={handleSubmit}>
            <div className='mb-3'>   
              <h3>
          Box cost($/In<sup>3</sup>):</h3>
            <input type="number"
            className='form-control' 
            required
            value={boxPriceVolIn} onChange={(e) => setBoxPriceVolIn(e.target.value)} />
          
          
          </div>
       <div className='text-light mb-3'>
        <h3>Crate cost($/In<sup>3</sup>):</h3>
          
            <input type="number" 
            required
            className='form-control' 
            value={cratePriceVolIn} onChange={(e) => setCratePriceVolIn(e.target.value)} />
         
          </div>
          <div className='mb-3'>
            
            <h3>Crate Density:</h3>
            <h3>(pounds/In<sup>3</sup>):</h3>
          
            <input type="number" 
            required
            className='form-control'
            value={crateDensityLbIn} onChange={(e) => setCrateDensityLbIn(e.target.value)} />
          </div>
          
      <div className='mb-3 text-center'>
        <button className='btn btn-primary'type="submit">Save Changes</button>
      </div>
    </form>  
    </div>
  );
}

export default Packaginset;