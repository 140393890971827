import React, { useState,useEffect } from 'react';
import Slider from 'react-slick';
import ReadMore from './ReadMore';
import Buytickets from './Buytickets';
import {useNavigate } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function convertISOTo12Hour(isoString) {
  const to12Hour = (time) => {
    let [hours, minutes] = time.split(':').map(num => parseInt(num, 10));
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    minutes = minutes < 10 ? '0' + minutes : minutes.toString();
    return `${hours}:${minutes} ${ampm}`;
  };

  const Time = isoString.split('T')[1].substring(0, 5);
  return to12Hour(Time);
}
function AlleventsFs({ 
  eventsFs, 
  setMyEventOrder,
  setEventOrderComplete,
  setppalk,
  ppalstr,
}) {

const [eventsOrder, setEventsOrder] = useState([]);
const [eventsOrderToPass, setEventsOrderToPass] = useState('');
const navigate = useNavigate();

  const settings = {
    className: "center slider-variable-width",
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true
  }

useEffect(() => {
  let updatedEOrder= eventsOrder.filter((f) => parseFloat(f.tickets_sold) >0);
if(updatedEOrder.length>0){
  let total=0;
for (let index = 0; index < updatedEOrder.length; index++) {
  const pricestr = updatedEOrder[index].price;
  const ticketstr = updatedEOrder[index].tickets_sold;

total+=parseFloat(pricestr)*parseFloat(ticketstr);
  
}

if(total>0){
  setEventsOrderToPass({myeventstopass:updatedEOrder, total:total});
}
  
}else{
  setEventsOrderToPass('');

}

}, [eventsOrder]); 


  return (
    <div className='container pt-sm-5 mt-sm-5 text-light'>
          {eventsOrderToPass!==''&&ppalstr&&<div className='cartbuttons'>
<button type="button"  className="btn btn-primary position-relative d-block p-1 cartbtnselement" onClick={() => {
        setMyEventOrder(eventsOrderToPass);
        setEventOrderComplete(false)
        setppalk(ppalstr)
        navigate('/Paymentevents');
       }}>
  Checkout 🛒
  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger ">
  {eventsOrderToPass.myeventstopass.length}
  </span>
</button>
<button type="button" className="btn btn-primary position-relative d-block mt-1 p-1 cartbtnselement" onClick={() =>setEventsOrder([])}>
Empty Cart
</button>
</div>}
   
      {eventsFs && eventsFs.map((event, index) => (
        <div key={event._id} className="card bg-dark mb-3">
          <div className="card-body">
          <h5 className="card-title mt-2 text-light text-center">{event.title}</h5>
            <Slider {...settings}>
              {event.pics && event.pics.map((pic, index) => (
                <div key={index} className="slick-image">
                  <img className="d-block w-100" src={pic} alt={`Event Pic ${index}`} />
                </div>
              ))}
            </Slider>
            <h5 className="card-title mt-2 text-light">Description:</h5>
            <div className='mb-3 text-light'><ReadMore text={event.description || 'No description provided.'} maxLength={50} /></div>
            <p className="card-text text-light"><strong className="text-light">Ticket Price:</strong> ${event.price}</p>
            <p className="card-text text-light"><strong className="text-light">Venue address:</strong> {event.venue}</p>
            {event.datetimeslots && event.datetimeslots.map((slot, index) => (
              <div key={index} className="mb-2">
                <strong className="text-light">Date:</strong> <span className="text-light">{slot.date.split('T')[0]}</span>
                <div>
                <strong className="text-light d-b">Time:</strong>
                {slot.timeslots && slot.timeslots.map((time, timeIndex) => (
                  <div key={`${timeIndex}timeslots`}>
         <span  className="ms-2 text-light">
                   {convertISOTo12Hour(time.startingtime)}-{convertISOTo12Hour(time.endingtime)}
                  </span>
                  </div>
         
                ))}
                </div>

                <div>
                  <Buytickets event={event} eventsOrder={eventsOrder} setEventsOrder={setEventsOrder} />
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default AlleventsFs;
