import React, { useEffect, useState } from 'react';
import { Api } from '../utils/api'
import { useParams } from 'react-router-dom';
import Singleorderelement from '../components/Admin/Ordersingle/Ordersingleelement';
import Loginmessage from "../components/Admin/Loginmessage"; 

function Ordersingle() {
  
  const { orderId } = useParams()
  const [order, setOrder]=useState('')
  const [fetchStatus, setFetchStatus] = useState(true);
  const [isSOLoading, setSOIsLoading] = useState(true);
  const [errorfound, setErrorfound]=useState(false)

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await Api.get(`/singleorder/${orderId}`, {
          headers: {
          Authorization: `${localStorage.getItem('id_token')}`,
          },
        });

        if (response && response.data) {
          setOrder(response.data);
          setSOIsLoading(false);
        }
      } catch (error) {
        setSOIsLoading(false);
        if (error.response && error.response.status === 401) { 
          setFetchStatus(false);
        } else {
          // Handle other errors
          setErrorfound(true);
          
        }
      }
    };
    fetchOrders();
  }, [orderId]);

  
  if(!fetchStatus){
    return(
      <Loginmessage/>
    )
  }

  if(isSOLoading){
    return(
      <div className='text-light custom6 custom4'>Loading...</div>
    )
  }

  return (
    <div className='text-light custom6 custom4'>
      {!errorfound&&!isSOLoading&&order&&
        <div>
          <Singleorderelement order={order}/>
        </div>
      }
      {errorfound&&
        <div >
          an error has occurred on our side
        </div>
      }
    </div>
  );
}

export default Ordersingle;

