import React, { useEffect, useState } from 'react';
import { Api, Alldata} from '../utils/api';
import { Checknegative } from '../utils/Checknegative'
import Loginmessage from "../components/Admin/Loginmessage";

function Lessonfeeset() {

  const [lessonFeeValue, setLessonFeeValue] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const [fetchStatus, setFetchStatus] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [errorfound, setErrorfound]=useState(false)

  useEffect(() => {
    const fetchLesonFeeData = async () => {
      setIsLoading(true)
      try {
        const response = await Alldata.get('/getlessonfee');

        if (response && response.data) {
          setLessonFeeValue(response.data.feelessonvalue|| '');
      
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error
          setFetchStatus(false);
        } else {
        // Handle other errors
          setErrorfound(true)
         
        }
        }
      }
      fetchLesonFeeData()

  }, []);

  useEffect(() => {
    const reFetchLessonFeeData = async () => {

      setIsLoading(true)

      try {
        const response = await Alldata.get('/getlessonfee');

        if (response && response.data) {
          setLessonFeeValue(response.data.feelessonvalue|| '');
      
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error
          setFetchStatus(false);
        } else {
        // Handle other errors
          setErrorfound(true)
         
        }
      }finally{
        setSubmitSuccess(false)
      }
    };

    if(submitSuccess===true){
      reFetchLessonFeeData()
    }
    
  }, [submitSuccess]);


  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    let setLessonFeeValue = Checknegative(lessonFeeValue,'Lesson Value')

    if(setLessonFeeValue==='Lesson Value'){
      setIsLoading(false)
      return alert('"Lesson value" must be bigger than 0')
    }
    try {

      const lessonfeedata = {
        feelessonvalue:lessonFeeValue
      };

      const response = await Api.post('/setlessonfee', lessonfeedata,
        {
          headers: {
            Authorization: `${localStorage.getItem('id_token')}`,
            },
        } 
      );

      if (response.status === 200) {
        setSubmitSuccess(true)
        setIsLoading(false)
        alert('Lesson fee data was updated');
       } else {
        setIsLoading(false)
        alert('Failed to update Lesson fee data');
      }

    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.status === 401) {
      // Handle 401 Unauthorized error
        setFetchStatus(false);
      } else {
      // Handle other errors
        setErrorfound(true)
      }
    }
  };

  if(!fetchStatus){
    return(
      <Loginmessage/>
    )
  }

  if(isLoading){
    return(
      <div className='text-light custom6 custom4'>Loading...</div>
    )
  }

  if(errorfound){
    return(
      <div className='text-light custom4 custom6'>An error has occurred on our side, try again later</div>
    )
  }

  return (
    <div className='text-light custom4 px-2 custom6'>
          <form onSubmit={handleSubmit}>
            <div className='mb-3'>   
              <h3>
          Lesson Fee $/hour:</h3>
            <input type="number"
            className='form-control' 
            required
            value={lessonFeeValue} onChange={(e) => setLessonFeeValue(e.target.value)} />
          
          
          </div>

          
      <div className='mb-3 text-center'>
        <button className='btn btn-primary'type="submit">Save Changes</button>
      </div>
    </form>  
    </div>
  );
}

export default Lessonfeeset;