import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import Navigatemenu from './Navigatemenu';
import image1 from '../components/Gallery/imgs/curtain.png';
import Auth from '../utils/auth';

function Header({ setNavigatemenuopen, navigatemenuopen }) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBurgerClick = () => {
    setNavigatemenuopen(true);
  };

  return (
    <div>
      {(location.pathname === '/about' || location.pathname === '/home' || location.pathname === '/booking'|| location.pathname === '/eventsfs') && (
        
        <div>
              
          {!navigatemenuopen && (
            <div className='navdivcontainer' style={{ zIndex: '1000' }}>
              <nav>
                <ul className="nav-list">
                  <li className="nav-item">
                    <Link to="/about" className="nav-link">Meet Yenis</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/home" className="nav-link">Gallery</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/booking" className="nav-link">Lesson booking</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/eventsfs" className="nav-link">Events</Link>
                  </li>
                </ul>
              </nav>
            </div>
          )}
          {!navigatemenuopen && (
            <div className='navdivcontainer2' style={{ minWidth: '100vw' }}>
              <i className="myburger" onClick={handleBurgerClick}>&#9776;</i>
            </div>
          )}
    
        </div>
      )}

      {!navigatemenuopen && (
        <div className={location.pathname === '/'?'':'headerelements pt-3'}>    
          {(location.pathname === '/about' || location.pathname === '/home'|| location.pathname === '/eventsfs') && (
            
            <>
            
              <img className='imgh' src={image1} alt="logo"></img>
              <h1 className='titlefont'>Yenis Camargo</h1>
              {location.pathname === '/home' && <h1 className='titlefont2'>Art Gallery</h1>}
              {location.pathname === '/about' && <h1 className='titlefont2'>About me</h1>}
              {location.pathname === '/eventsfs' && <h1 className='titlefont2'>Events</h1>}
            </>
          )}
          {location.pathname === '/Checkout' && (
            <>
              <h2 className='text-light pb-3 pt-3'>Checkout</h2>
              <button className='btn btn-primary mb-3' onClick={(event) => {
                event.preventDefault();
                navigate('/home');
              }}>GO BACK</button>
            </>
          )}
          {location.pathname === '/booking' && <h2 className='text-light pb-3 pt-3'>Lesson Booking</h2>}
        </div>
      )}

      {(location.pathname !== '/admin' && location.pathname !== "/about" && location.pathname !== "/"
        && location.pathname !== "/Checkout" && location.pathname !== "/Payment"
        && location.pathname !== "/Confirmation" && location.pathname !== "/home"
        && location.pathname !== '/booking'
        && location.pathname !== '/eventsfs'
        && location.pathname !== '/Paymentlesson'
        && location.pathname !== '/Paymentevents'
      
      ) && (
        <div className="fixed-top custom2">
          {Auth.loggedIn() && (
          
          
          <div className="custom2000 py-3">
            
              
                
                  <button className="btn customButton btn-success btn-sm" onClick={(event) => {
                    event.preventDefault();
                    Auth.logout();
                  }}>Logout</button>
                  <button className="btn customButton btn-success btn-sm" onClick={(event) => {
                    event.preventDefault();
                    navigate('/Orders');
                  }}>Orders</button>
                  <button className="btn customButton btn-success btn-sm" onClick={(event) => {
                    event.preventDefault();
                    navigate('/Products');
                  }}>Paintings</button>
                  <button className="btn customButton btn-success btn-sm" onClick={(event) => {
                    event.preventDefault();
                    navigate('/packageparams');
                  }}>Packaging Data</button>
                  <button className="btn customButton btn-success btn-sm" onClick={(event) => {
                    event.preventDefault();
                    navigate('/setavaldates');
                  }}>Schedule</button>
                  <button className="btn customButton btn-success btn-sm" onClick={(event) => {
                    event.preventDefault();
                    navigate('/myeventsadmin');
                  }}>My Events</button>
                  <button className="btn customButton btn-success btn-sm" onClick={(event) => {
                    event.preventDefault();
                    navigate('/lessonfeeset');
                  }}>Set Lesson Fee</button>
              
              
            
          </div>)}
        </div>
      )}

      {navigatemenuopen && (
        <div style={{ position: 'relative', minHeight: '100vh' }}>
          <Navigatemenu setNavigatemenuopen={setNavigatemenuopen} />
        </div>
      )}

    </div>
  );
}

export default Header;
