import React, { useEffect, useState } from 'react';
import { Api } from '../utils/api';
import Allproducts from '../components/Admin/Allproducts/Allproducts';
import Loginmessage from "../components/Admin/Loginmessage";
import {useNavigate } from 'react-router-dom';

function Products() {
  const navigate = useNavigate();
  const [products, setProducts]=useState([])
  const [isPLoading, setPIsLoading] = useState(true);
  const [fetchStatus, setFetchStatus] = useState(true);
  const [errorPfound, setErrorPfound]=useState(false)
  const [refetchsingle,setRefetch] = useState(false)

  useEffect(() => {

    const fetchProducts = async () => {

      try {
        const response = await Api.get('/getpaintings', {
          headers: {
            Authorization: `${localStorage.getItem('id_token')}`
          }
        });

        if (response && response.data) {
          setProducts(response.data);
          setPIsLoading(false)
        }

      } catch (error) {
        setPIsLoading(false);
        if (error.response && error.response.status === 401) {
          setFetchStatus(false);
        } else {
          setErrorPfound(true);
          
        }
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
  
    const fetchProducts = async () => {
      try {
        const response = await Api.get('/getpaintings', {
        headers: {
          Authorization: `${localStorage.getItem('id_token')}`
        }
      });

      if (response && response.data) {
      setProducts(response.data);
      setPIsLoading(false)
      }

      } catch (error) {
        setPIsLoading(false);
        if (error.response && error.response.status === 401) {
          setFetchStatus(false);
        } else {
          setErrorPfound(true);
          
        }
      }
    };

    if(refetchsingle===true){
      fetchProducts();
    }

  }, [refetchsingle]);


  if(!fetchStatus){
    return(
      <Loginmessage/>
    )
  }

  if(isPLoading){
    return   ( <div className="text-light text-center custom6 custom4" style={{paddingTop:'260px'}}>
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>)
  }

  if (errorPfound) {
    return (
      <div className='text-light text-center custom4 custom6'>
        an error has occurred on our side. Please try again later
      </div>
    );
  }

  if (products && products.length === 0) {
    return (<div className='text-light custom4 custom6'>
    <div >
      <div className='text-center my-3'>
      <button
      className="btn btn-primary"
        type="button"
        onClick={() => {
          navigate('/Createproduct')
        }}
      >
      Create New Painting
      </button>
    </div>
    <div className='text-center my-3'>NO PAINTINGS FOUND</div>
      </div>
    </div>
  
)
  }

  return (
    <div className='text-light custom4 custom6' >
      <Allproducts 
      products = {products} 
      refetchsingle={refetchsingle}
      setRefetch={setRefetch}
      
       setPIsLoading={setPIsLoading}  
      />
    </div>
  );
}

export default Products;