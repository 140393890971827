import React, {useEffect,useState } from 'react';
import { Alldata,Api } from '../utils/api';
import Loginmessage from "../components/Admin/Loginmessage";
import Dateseventdiv from '../components/Admin/Cevent/Dateseventdiv'
import {useNavigate } from 'react-router-dom';
import { Decimalstorequest } from '../utils/Decimalstorequest'
import { Decimals } from '../utils/Decimals'
import Datesnonsch from "../components/Admin/Cevent/Datesnonsch";


const Cevent =({setEventPage}) => {

  const navigate = useNavigate();
  const [fetchStatus, setFetchStatus] = useState(true);
  const [dates, setDates] = useState([]);
  
  const [isIncomplete, setIsIncomplete]=useState(true)
  const [timeoutId, setTimeoutId] = useState(null);
   const [counterMin, setCounterMin] = useState(7);
   const [datesSource, setDatesSource] = useState('');
  const [counterSec, setCounterSec] = useState(60);
  const [isPLoading, setPIsLoading] = useState(true);
  const [errorFoundEv, setErrorFoundEv] = useState(false);

  const [title, setTitle] = useState('');
  const [venue, setVenue] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [numberofpeople, setnumberofpeople] = useState('');
  const [publish, setPublish] = useState('no');
  const [files,setFiles]=useState([])
  const [datesSelArr, setDatesSelArr] = useState([]);

  const [isLoadingCs, setIsLoadingCs]=useState(false)

  useEffect(() => {
    if (timeoutId!== null) {
    const timer = setTimeout(() => {
      setTimeoutId(null);
      setIsIncomplete(true);
      setDatesSelArr([]);
      setCounterMin(7)
      setCounterSec(60)
    }, 8*60*1000);
  
    return () => clearTimeout(timer);
  }
  },[timeoutId])
  
  useEffect(() => {
    let min = counterMin
    let sec =counterSec
    if (timeoutId!== null) {
     
    const timer1 = setTimeout(() => {
    if(sec!==0){
      sec=sec-1
setCounterSec(sec)
    }

    if(min!==0&&sec===0){
      sec=60

      min=min-1
      setCounterMin(min)
      setCounterSec(sec)
    }

    }, 1000); // Trigger the animation 1 second after the component mounts
  
    return () => clearTimeout(timer1);
  }
  },[counterMin,counterSec,timeoutId])
  
    useEffect(() => {
      const fetchDates = async () => {
        try {
          const response = await Alldata.get('/getdatesavailable');
  
          if (response && response.data) {
            setDates(response.data);
            setPIsLoading(false)
          }
  
        } catch (error) {
          setPIsLoading(false);
          setErrorFoundEv(true);
            
        }
      };
  
      fetchDates();
    }, [isIncomplete]);

  const changeFiles =(e)=>{
  setFiles(e.target.files)
  }

  const handleDatesConfirmation=async (e) => { 
    try {
      setIsLoadingCs(true)
      e.preventDefault()
      let arrOrdertoPass = datesSelArr.filter((t) => t.date !== ''&&t.timeslots.length>0)

      if(arrOrdertoPass.length===0){

        setIsIncomplete(true)
        setIsLoadingCs(false)
        alert('Please select at least one date and time slot')
      }else{
     
      const response = await Api.post('/checktimeslotsevents',{timeslots:arrOrdertoPass});
                  
      if (response && response.status===200) {
        if(timeoutId!==null){
          setTimeoutId(200)
        }else if(timeoutId===200){
          setTimeoutId(2)
        }else{
          setTimeoutId(200)
        }
        
        setIsIncomplete(false)
        setIsLoadingCs(false)
      }
      }
    } catch (error) {
      alert('An error ocurred and your order could not be processed')
      
    }
          }

  const handleSubmit = async (e) => {
    setIsLoadingCs(true)
    e.preventDefault()
    let arrOrdertoPass = datesSelArr.filter((t) => t.date !== ''&&t.timeslots.length>0)
    let setPriceVal = Decimalstorequest(price,2)
    let setnumberofpeopleVal = Decimalstorequest(numberofpeople,0)

      try {
let myendpoint
        const paintingevent = {
    title, 
    venue,
    price:setPriceVal, 
    description,
    publish,
    timeslots:arrOrdertoPass, 
    numberofPeople:setnumberofpeopleVal,
    };

    if(datesSource==='schedule'){
      myendpoint='createevent'
    }else{
      myendpoint='createeventnosch'
    }

    const response2 = await Api.post(`/${myendpoint}`, paintingevent,
      {
        headers: {
          Authorization: `${localStorage.getItem('id_token')}`,
          },
      } 
    );

    if(response2&&response2.data){

      if(files.length>0){
        const formData = new FormData()
        for(const file of files){
          formData.append("photos", file)
        }   
        const response3 = await Api.post(`/uploadeventphotos/${response2.data.myEvent}`,formData, {
          headers:{
                    "content-type":"multipart/form-data",
                    Authorization: `${localStorage.getItem('id_token')}`,
                  },
        });

        if (response3 && response3.data) {
          setFiles([]) 
        }
      }
    }
  
    if (response2.status === 200) {
      setTimeoutId(200)

      alert('Event created successfully');
      setEventPage(response2.data.myEvent)
      setIsLoadingCs(false)
      navigate('/singleeventpage')
    } else {
     
      setIsLoadingCs(false)
      setDatesSelArr([])
      setTimeoutId(null);
      setCounterMin(7);
      setDatesSource('');
     setCounterSec(60);
     setIsIncomplete(true)
   
      alert('Failed to create event');
    }

  } catch (error) {
    setIsLoadingCs(false);
    setDatesSelArr([])
    setIsIncomplete(true)
    setTimeoutId(null);
    setCounterMin(7);
    setDatesSource('');
   setCounterSec(60);
    if (error.response && error.response.status === 401) {
    // Handle 401 Unauthorized error
      setFetchStatus(false);
    } 
    alert('Failed to create event');
  }
    
  };

  const handleChange = (value,p) => {

      let v = value
      if(v<=0){
      v=''
      }
      if(p==='price'){
        if(value.toString().includes('.')){
                v=Decimals(value,2)
        }
      }else if(p==='numberofpeople'){
        if(value.toString().includes('.')){
          v=Decimals(value,0)
      }
      }else{
        if(value.toString().includes('.')){
          v=Decimals(value,0)
      }
      }

      switch (p) {
        case 'price':
          setPrice(v);
          break;
        case 'numberofpeople':
          setnumberofpeople(v);
          break;
        default:
          break;
      } 
  };
  
  if(!fetchStatus){
    return(
      <Loginmessage/>
    )
  }

  if(isLoadingCs||isPLoading){
    return    <div className="text-light text-center custom6 custom4" style={{paddingTop:'260px'}}>
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
  }

  if (errorFoundEv) {
    return (
      <div className='text-light text-center custom4 custom6'>
        an error has occurred on our side. Please try again later
      </div>
    );
  }

  return (
  <div className="text-light container-md pb-2 custom4 custom6">
    <div className='mb-3 text-center'>
      <h3>Create new event</h3>
    </div>
<div>
  
  <div className='text-center'>
  {(datesSource===''||datesSource==='noschedule')&&<button 
          disabled={
         !isIncomplete
         
            }
          onClick={() => setDatesSource('schedule')} className='mt-3 mb-3 btn btn-primary'>
            set time and date from your regular schedule
          </button>}
</div>
<div className='text-center mb-3'>
{(datesSource===''||datesSource==='schedule')&&<button 
          disabled={
         !isIncomplete
            }
          onClick={() => {setDatesSource('noschedule');setDatesSelArr([])}}className='mt-3 btn btn-primary'>
            set time and date outside your regular schedule
          </button>}
</div>
</div>
{isIncomplete&&datesSource==='noschedule'&&<Datesnonsch
setDatesSelArr={setDatesSelArr}
setIsIncomplete={setIsIncomplete}
isIncomplete={isIncomplete}

/>}

<div>
{isIncomplete&&datesSource==='schedule'&&<Dateseventdiv
              datesSelArr={datesSelArr}
               setDatesSelArr={setDatesSelArr}
               dates={dates} 
               setDates={setDates}
               isIncomplete={isIncomplete}
              />}

{!isIncomplete&&datesSelArr.map((element,index) => (
               <div key={index} className='mb-3'>
                        <h5>
            Date: {element.date}
        </h5>      
        <h5>
           Time:
        </h5>  
        {element.timeslots&&element.timeslots.map((time,timeindex) => (
           <div key={`${timeindex}timeslots`}>
                  <h5>
            {time.startingtime}-{time.endingtime}
        </h5>        
           </div>

         ))
        }
        </div>
        ))}

        {!isIncomplete&&datesSource==='schedule'&&<div className='mb-3 text-center'><h1>{counterMin}:{counterSec<10?'0':''}{counterSec===60?'--':counterSec}</h1></div>}

        {datesSelArr.length>0&&datesSource==='schedule'&&<button 
          disabled={
         !isIncomplete
         
            }
          onClick={handleDatesConfirmation} className='mt-3 btn btn-primary'>
            Confirm date/time
          </button>}
</div>

      {!isIncomplete&&<form onSubmit={handleSubmit}>         
        <div className='mb-3'>
          <h3>
            Add photo:
          </h3>
          <input id='file-upload' type="file" className="form-control" multiple onChange={changeFiles}/>
        </div>
          <div>
        <div>        
          <div className='mb-3' >
       
            <h3>Title:</h3>
            <input type="text" className="form-control" required value={title} onChange={(e) => setTitle(e.target.value)} />
         
          </div>
          <div className='mb-3'>
          <h3>Price $:</h3>
            
            <input
  type="number"
  name='price'
  className="form-control"
  required
  value={price}
  onChange={(e) => handleChange(e.target.value,e.target.name)}// Updated change handler
/>
          </div>
          <div className='mb-3'>     
            <h3>Description:</h3>
      <textarea
          className="form-control"
          rows="8"
          name="notes" 
          required value={description} onChange={(e) => setDescription(e.target.value)} />
    
      </div>
<div className='mb-3'>     <h3>
          Number of participants:
            </h3>
          
            <input type="number" 
            name='numberofpeople' 
            required
            className="form-control" 
            value={numberofpeople} 
            onChange={(e) => 
              handleChange(e.target.value,e.target.name)} /></div>
    
        </div>
<div className='mb-3'>     <h3>
          Venue address:
            </h3>
          
            <input type="text" className="form-control" required value={venue} onChange={(e) => setVenue(e.target.value)} />
    
        </div>
        <div className='mb-3'>
          <h3>Publish event:</h3>
          <select
            name="publish"
            className='form-select'
            value={publish}
            required
            onChange={(e)=>setPublish(e.target.value)}
          >    
            <option value="yes">post event</option>
            <option value="no">do not post</option>    
          </select> 
        </div>

    
        <div className='text-center mb-6'>
          <button 
          disabled={
            title===''
            ||description===''
            ||price===''
          ||isIncomplete||
          datesSelArr.length===0
            }
          type="submit" className='btn btn-primary'>
            C r e a t e
          </button>
        </div>
        </div>
      </form>}
      <div className='text-center mt-3'>                  
      {!isIncomplete&&<button 
                      onClick={() => {
              setDatesSelArr([]);
              setTimeoutId(null);
               setCounterMin(7);
               setDatesSource('');
              setCounterSec(60);
              setIsIncomplete(true)
             }}
           className='btn btn-primary'>
            Cancel
          </button>}
          </div>

      
  </div>
  );
}

export default Cevent;