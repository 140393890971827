import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Api } from '../utils/api';

function Resetpwd() {
  const { token } = useParams();
  const [password, setPassword] = useState({ newPass: '', confirmPass: '' });
  const [message, setMessage] = useState(''); // Add state to store response message or error

  const handlePassInput = (event) => {
    const { name, value } = event.target;
    setPassword({ ...password, [name]: value });
  };

  const handleResetPwd = async () => {
    // Check if the passwords match
    if (password.newPass !== password.confirmPass) {
      setMessage("Passwords don't match.");
      return;
    }

    try {
      // Replace 'http://yourbackenddomain' with the actual domain of your backend
      const response = await Api.post(`/reset-password/${token}`, {
        password: password.newPass,
      });

      setMessage(response.data); // Assuming the backend sends back a success message
      // Optionally clear the form or redirect the user
    } catch (error) {
      setMessage(error.response ? error.response.data : 'Error resetting password');
    }
  };

  return (
    <div className='custom4 custom12 text-light px-2'>
      
    <div className='text-center mt-4 text-light mb-2' >
      
        <button
        className="btn btn-secondary"
          type="submit"
          onClick={(event) => {
            event.preventDefault();
            window.location.assign('../../');
          }}
        >
          GO HOME
        </button>
      
    
  </div>
      <form text-light>
        <div style={{maxWidth: '500px'}}> 
          <label htmlFor="newPass" className="form-label-lg mb-2">New Password:</label>
          <input
            className="form-control form-control-md mb-3"
            id="newPass"
            type="password"
            placeholder="New password"
            name="newPass"
            onChange={handlePassInput}
            value={password.newPass}
          />
          <label htmlFor="confirmPass" className="form-label-lg mb-2">Confirm New Password:</label>
          <input
            className="form-control form-control-md mb-3"
            id="confirmPass"
            type="password"
            placeholder="Confirm new password"
            name="confirmPass"
            onChange={handlePassInput}
            value={password.confirmPass}
          />
        </div>
        <button type="button" className="btn btn-primary" onClick={handleResetPwd}>
          Submit
        </button>
        {message && <div className="alert alert-info mt-3">{message}</div>}
      </form> 
    </div>
  );
}

export default Resetpwd;
