export const States=[
{s:'Alabama', c:'AL'},
{s:'Alaska', c:'AK'},
{s:'Arizona', c:'AZ'},
{s:'Arkansas', c:'AR'},
{s:'California', c:'CA'},
{s:'Colorado', c:'CO'},
{s:'Connecticut', c:'CT'},
{s:'Delaware', c:'DE'},
{s:'District of Columbia', c:'DC'},
{s:'Florida', c:'FL'},
{s:'Georgia', c:'GA'},
{s:'Hawaii', c:'HI'},
{s:'Idaho', c:'ID'},
{s:'Illinois', c:'IL'},
{s:'Indiana', c:'IN'},
{s:'Iowa', c:'IA'},
{s:'Kansas', c:'KS'},
{s:'Kentucky', c:'KY'},
{s:'Louisiana', c:'LA'},
{s:'Maine', c:'ME'},
{s:'Maryland', c:'MD'},
{s:'Massachusetts', c:'MA'},
{s:'Michigan', c:'MI'},
{s:'Minnesota', c:'MN'},
{s:'Mississippi', c:'MS'},
{s:'Missouri', c:'MO'},
{s:'Montana', c:'MT'},
{s:'Nebraska', c:'NE'},
{s:'Nevada', c:'NV'},
{s:'New Hampshire', c:'NH'},
{s:'New Jersey', c:'NJ'},
{s:'New Mexico', c:'NM'},
{s:'New York', c:'NY'},
{s:'North Carolina', c:'NC'},
{s:'North Dakota', c:'ND'},
{s:'Ohio', c:'OH'},
{s:'Oklahoma', c:'OK'},
{s:'Oregon', c:'OR'},
{s:'Pennsylvania', c:'PA'},
{s:'Rhode Island', c:'RI'},
{s:'South Carolina', c:'SC'},
{s:'South Dakota', c:'SD'},
{s:'Tennessee', c:'TN'},
{s:'Texas', c:'TX'},
{s:'Utah', c:'UT'},
{s:'Vermont', c:'VT'},
{s:'Virginia', c:'VA'},
{s:'Washington State', c:'WA'},
{s:'West Virginia', c:'WV'},
{s:'Wisconsin', c:'WI'},
{s:'Wyoming', c:'WY'},
{s:'Puerto Rico', c:'PR'}]







