import React, { useState} from 'react';

function DatesTimes({
 element,
  datesSelArr,
  setDatesSelArr,
  index,
  dates
}) {

  const [timespotselsel, settimespotselsel] = useState([]);
  const [timespotarrobj, setTimeSpotArrObj] = useState([]);

  const handleDateChange = (value) => {
    const i= dates.find((t) => t.date === value).id
    const t= dates.find((t) => t.date === value).dateMill
    const updatedSpots = [...datesSelArr];
    updatedSpots[index] = {
      ...updatedSpots[index],
      date: value,
     id:i,
     time:t,
      timeslots: [] // Reset timeslots when the date changes
    };
    setDatesSelArr(updatedSpots);
  };

  const handleSelectTs = (event) => {
    const value = event.target.value;

let myselarr=[]
let myselarrobj=[]
let selstr = timespotselsel.find((t) => t === value)
let selstrinobjarr = timespotarrobj.find((t) => t.startingtimeMill === value)

if(selstr&&selstrinobjarr){
  myselarr = timespotselsel.filter((t) => t !== value)
  myselarrobj = timespotarrobj.filter((t) => t.startingtimeMill !== value)
  settimespotselsel(myselarr)
  setTimeSpotArrObj(myselarrobj)
  }else{
    const timeslotobjtopass = dates.find((t) => t.date === datesSelArr[index].date).timespots.find((t) => t.startingtimeMill === value)
    myselarr = [...timespotselsel, value]
    myselarrobj = [...timespotarrobj, timeslotobjtopass]
    settimespotselsel(myselarr)
    setTimeSpotArrObj(myselarrobj)
  }

  const updatedSpots = [...datesSelArr];
  updatedSpots[index] = {
    ...updatedSpots[index],
    timeslots: [...myselarrobj]
  };
  setDatesSelArr(updatedSpots);
  }

  return (
    <div>
      {dates&&element.date===''&&<div className="d-flex-column">
       Select a date
          <select
            className="form-select-sm mx-2 mb-2"
            value={datesSelArr[index].date}
            onChange={(e) => handleDateChange(e.target.value)}
          >
            <option value="">----------</option>
            {dates&&dates.filter(date => !datesSelArr.some(selDate => selDate.date === date.date)).map((date) => (
              <option key={date.dateMill} value={date.date}>
                {date.date}
              </option>
            ))}
          </select>
          </div>}
          {datesSelArr[index].date !== '' && dates.filter(date => date.date === datesSelArr[index].date).map((selectedDate) => (
    <div key={selectedDate.dateMill}>
        {selectedDate.timespots.map((timeslot, tindex) => (
            <div className="form-check form-check-inline" key={tindex}>
                <input
                    className="form-check-input"
                    type="checkbox"
                    value={timeslot.startingtimeMill}
                    checked={timespotselsel.includes(timeslot.startingtimeMill)}
                    onChange={handleSelectTs}
                />
                <label className="form-check-label" htmlFor="Top">
                    {timeslot.startingtime}-{timeslot.endingtime}
                </label>
            </div>
        ))}
    </div>
))} 
    </div>
  );
}

export default DatesTimes;

