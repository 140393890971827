export const CaStates = [
    { s: "Alberta", c: "AB" },
    { s: "British Columbia", c: "BC" },
    { s: "Manitoba", c: "MB" },
    { s: "New Brunswick", c: "NB" },
    { s: "Newfoundland and Labrador", c: "NL" },
    { s: "Northwest Territories", c: "NT" },
    { s: "Nova Scotia", c: "NS" },
    { s: "Nunavut", c: "NU" },
    { s: "Ontario", c: "ON" },
    { s: "Prince Edward Island", c: "PE" },
    { s: "Quebec", c: "QC" },
    { s: "Saskatchewan", c: "SK" },
    { s: "Yukon", c: "YT" }
];








