import React from 'react';
import { Link } from 'react-router-dom'; // Import Link component

function Navigatemenu({ setNavigatemenuopen }) {
  const handleClick = () => {
    setNavigatemenuopen(false); // Close the navigation menu when a link is clicked
  };

  return (
    <div className='w-100 p-0 mt-0 menu2'style={{ width: '100%', minHeight:'80vh' }}>    
    <nav className="p-0 menu"style={{ width: '100%',zIndex:30000 }} >
    <ul className="nav flex-column w-100 py-0">
      <li className="nav-item limenu border-bottom border-light text-center">
        <Link to="/about" className="nav-link text-light text-center" onClick={handleClick}>Meet Yennis</Link>
      </li>
      <li className="nav-item limenu border-bottom border-light text-center">
        <Link to="/home" className="nav-link text-light text-center" onClick={handleClick}>Gallery</Link>
      </li>
      <li className="nav-item limenu border-bottom border-light text-center">
        <Link to="/booking" className="nav-link text-light text-center" onClick={handleClick}>Lesson booking</Link>
      </li>
      <li className="nav-item limenu border-bottom border-light text-center">
        <Link to="/eventsfs" className="nav-link text-light text-center" onClick={handleClick}>Events</Link>
      </li>
    </ul>
  </nav></div>

  );
}

export default Navigatemenu;
