import React, { useEffect, useState } from 'react';
import image1 from '../components/imgs/20240326_093225.jpg';
import image2 from '../components/imgs/laura-college-K_Na5gCmh38-unsplash.jpg';
import Navigatemenu from '../components/Navigatemenu';
import Canonical from '../components/Canonical'

function Welcome({
  setNavigatemenuopen,
        navigatemenuopen,
}) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Initial image index
  const [isTransitioning, setIsTransitioning] = useState(false); // State to control transition
  const backgroundImages = [image1, image2];
  

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setIsTransitioning(false);
        
        setCurrentImageIndex((currentImageIndex + 1) % backgroundImages.length);
      }, 1000); 
    }, 5000); 

    
    return () => clearInterval(interval);
  }, [currentImageIndex, backgroundImages.length]);


  return (
    <div>
      <Canonical url="https://www.mylovepainting.com/" />
      {!navigatemenuopen && (
        <div className='wcontainer text-center'>
          <div style={{zIndex:5000,position: 'relative' }} >
          <div className='titlefontw1' style={{ position: 'relative'}}>Yenis Camargo</div>
          <div className='titlefontw2 mb-3' style={{ position: 'relative'}}>Painting Artist</div>
          <button className='btnfontw mx-2 btn btn-sm btn-outline-primary' style={{ position: 'relative'}} onClick={() => { window.location.href = '/home' }}>Gallery</button>
          <button className='btnfontw mx-2 btn btn-sm btn-outline-primary' style={{ position: 'relative'}} onClick={() => { window.location.href = '/eventsfs' }}>Events</button>
          {/* <button className='btnfontw mx-2 btn btn-sm btn-outline-primary' style={{ position: 'relative'}} onClick={() => { window.location.href = '/events' }}>Go to events</button> */}
          </div> 
          {backgroundImages.map((image, index) => (
            <div key={index} style={{ maxWidth: '100vw'}} className='position-absolute top-50 start-50 translate-middle'>
              <img
                src={image}
                alt={`${index}`}
                style={{
                  zIndex: index === currentImageIndex ? 100 : 0,
                  opacity: index === currentImageIndex && isTransitioning === false ? 1 : 0,
                  transition: 'opacity 1s ease',
                }}
                className='welcomeimg'
              />
            </div>
          ))}
        </div>
      )}
      {navigatemenuopen && (
        <div style={{ position: 'relative', minHeight: '100vh' }}>
          <Navigatemenu setNavigatemenuopen={setNavigatemenuopen} />
        </div>
      )}
    </div>
  );
}

export default Welcome;
