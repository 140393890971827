import React, { useEffect, useState } from 'react';
import { Api } from '../../../utils/api';
import ConfirmationDialog from '../ConfirmationDialog'
import { Decimalstorequest } from '../../../utils/Decimalstorequest'
import { Decimals } from '../../../utils/Decimals'
function Singleproduct({ 
  id, 
  setErrorAuth,
  setFetchStatus
}) 
{
  
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [reloadSingleEvent, setFetchSingleEvent] = useState(false);

   const [isSPLoading, setSPIsLoading] = useState(false);

  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [tickets_sold, setTicketsSold] = useState('');
  const [description, setDescription] = useState('')
  const [numberofPeople,setNumberofPeople]=useState('')
  const [picsToDelete, setPicsToDelete]=useState([])
  const [files,setFiles]=useState([])
  const [picsToMap,setPicsToMap]=useState([])
  const [datesToMap,setMyEventDates]=useState([])
  const [isDeleting, setIsDeleting] = useState(false);

  const [publish, setPublish] = useState('');

  const changeFiles =(e)=>{
    setFiles(e.target.files)
  }

  useEffect(() => {
    const fetchSingleEvent = async () => {
setSPIsLoading(true)
      try {
        const response = await Api.get(`/getsingleeventadmin/${id}`, {
          headers: {
            Authorization: `${localStorage.getItem('id_token')}`,
          },
        });

        if (response && response.data) {
          setTitle(response.data.title || '');
          setPrice(response.data.price || '');
          setDescription(response.data.description || '');
          setPublish(response.data.publish || '');
          setTicketsSold(response.data.tickets_sold || '');
          setPicsToMap(response.data.pics|| []);
          setNumberofPeople(response.data.numberofPeople|| '')
          setMyEventDates(response.data.datetimeslots|| [])
          setSPIsLoading(false);
        }
      } catch (error) {
        setSPIsLoading(false);
        if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error
        setErrorAuth(true)
        }
      }finally{
        setFetchSingleEvent(false)
        setSPIsLoading(false)
      }
    };

    if(id!==''||reloadSingleEvent===true){
      fetchSingleEvent();
     
    }

  }, [id,reloadSingleEvent]);

  function convertISOTo12Hour(isoString) {
   
    const to12Hour = (time) => {
      let [hours, minutes] = time.split(':').map(num => parseInt(num, 10)); // Parse hours and minutes
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // Convert 0 to 12 for 12 AM
      minutes = minutes < 10 ? '0' + minutes : minutes.toString();
  
      return `${hours}:${minutes} ${ampm}`;
    };
  
    const Time = isoString.split('T')[1].substring(0, 5);
  
    const formattedTime = to12Hour(Time);
    
    return `${formattedTime}`;
  }

  function handleDeletePic (photo) {
    setPicsToDelete([...picsToDelete,photo])
  }

  function handleCancelDeletePic (photo) {
    const filterPicsToDelete = picsToDelete.filter((pic)=>pic!==photo)
    setPicsToDelete(filterPicsToDelete)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSaving(true)
    setIsDeleting(false)
    setConfirmMessage("Are you sure you want to save the changes?")
    setIsDialogOpen(true);
  };



  const handleDelete = async () => {
    
    setSPIsLoading(true)
setIsDialogOpen(false);
try {
  const response = await Api.post('/deleteevent',{_id:id}, {
    headers: {
      Authorization: `${localStorage.getItem('id_token')}`,
    },
  });

  if (response.status === 200) {
    setSPIsLoading(false)
        alert('event deleted'); 
  } else {
    setSPIsLoading(false)
    alert('Failed to delete event');
  }

} catch (err) {
  setSPIsLoading(false)
  if (err.response && err.response.status === 401) {
  // Handle 401 Unauthorized error
  alert('Failed to delete event');
  setErrorAuth(true);
 
  } else {
    alert('Failed to delete event');
  }
}finally{
  setFetchStatus(true);
}
};
const handleSubmitDelete = (event) => {
event.preventDefault();
// Open confirmation dialog
setIsDeleting(true)
setIsSaving(false)
setConfirmMessage("Are you sure you want to delete this event?")
setIsDialogOpen(true);
};


  const handleChange = (value,p) => {

    let v = value
    if(v<=0){
    v=''
    }
    if(p==='numberofPeople'){
      if(value.toString().includes('.')){
        v=Decimals(value,0)
      }
    }

    switch (p) {
      case 'numberofPeople':
        
        setNumberofPeople(v);
        break;
       default:
        break;
    } 
};

  const handleConfirm = async (e) => {
    setSPIsLoading(true)
    setIsDialogOpen(false);
    let setpeopleVal = Decimalstorequest(numberofPeople,0)
   try {

      const   updatedEvent = {
        numberofPeople:setpeopleVal,
        publish
      };

      const response = await Api.post(`/updatemyeventadmin/${id}`, 
        updatedEvent
        ,  {
        headers: {
          Authorization: `${localStorage.getItem('id_token')}`,
        },
      });

      if(files.length>0){
        const formData = new FormData()
        for(const file of files){
          formData.append("photos", file)
        }
        const response2 = await Api.post(`/uploadeventphotos/${id}`,formData, {
          headers:{
            "content-type":"multipart/form-data",
            Authorization: `${localStorage.getItem('id_token')}`,
          },
        });

        if (response2 && response2.data) {
          setFiles([]) 
          setPicsToDelete([])     
        }
      }

      if(picsToDelete.length>0){
        const response3 = await Api.post(`/deleteeventphotos/${id}`,{photoUrl:picsToDelete}, {
          headers:{
            Authorization: `${localStorage.getItem('id_token')}`,
          },
        });

        if (response3 && response3.data) {
          setPicsToDelete([])  
          setFiles([]) 
        }
      }

      if (response.status === 200) {
        setSPIsLoading(false)
        setIsSaving(false)
      
            alert('event updated'); 
      } else {
        setSPIsLoading(false)
        setIsSaving(false)
        alert('Failed to update event');
      }

    } catch (err) {
      setSPIsLoading(false)
      setIsSaving(false)
      if (err.response && err.response.status === 401) {
      // Handle 401 Unauthorized error
      setErrorAuth(true);
      alert('Failed to update event');
     
      } else {
        setIsSaving(false)
        alert('Failed to update event');
      }

    }finally{
      setFetchSingleEvent(true)
    }
  };

  const handleCancel = () => {
    setFiles([])
    setPicsToDelete([])
    setIsSaving(false)
    setIsDeleting(false)
    setIsDialogOpen(false);
    setFetchSingleEvent(true)
  };

  if (isSPLoading) {
    return (<div>Loading...</div>)
  }


  
  return (
  <div className="container px-2 text-bg-dark p-3">
    <div className={isDialogOpen?"confirmopen":""}> 
    <div className="mb-3">      
            <h3>Event title:</h3>
           <h4>{title}</h4>   
        </div>
    
    <div className="mb-3">
    {datesToMap&&datesToMap.map((element,index) => (
               <div key={index}>
                        
            <h3>Event date:</h3> 
            <h4>{element.date.split('T')[0]}</h4>
             


       
            <h3>Time:</h3>
         
        {element.timeslots&&element.timeslots.map((time,timeindex) => (
           <div key={`${timeindex}timeslots`}>
                  <h4>
            {convertISOTo12Hour(time.startingtime)}-{convertISOTo12Hour(time.endingtime)}
        </h4>        
           </div>
         ))
        } 
               </div>
             ))
            }
    </div>


    <div className="mb-3 d-flex flex-wrap">
      {picsToMap&&picsToMap.map((element,index) => (
        <div key={index} className="p-2">
          <img style={{width: '200px'}} className={`img-thumbnail ${picsToDelete.includes(element) ? 'toDelete' : ''}`} src={element} alt={`${element} ${index}`}/>          
          {picsToDelete.includes(element)? (
            <div>
              <button className="btn btn-secondary mt-2" disabled={isDialogOpen} style={{ cursor: 'pointer' }}
                onClick={() => handleCancelDeletePic(element)}>
              Cancel
            </button>
          </div> 
          ) : (
          <div>    
            <button  className="btn btn-danger mt-2" disabled={isDialogOpen} style={{ cursor: 'pointer' }}
              onClick={() => handleDeletePic(element)}>
              Delete
            </button>
          </div>
          )}
        </div>
      ))}
    </div>
    <div className="mb-3" >
      
        <h3>Add photo</h3>
     
      {!isDialogOpen&&<input id="file-upload" type="file" className="form-control" multiple onChange={changeFiles}/>}
    </div>
    <form onSubmit={handleSubmit}>
      <div >

      <div className="mb-3">
      <h3>publish status:</h3>
        {!isDialogOpen&& 
          <select
          className="form-select"
            name="publish"
            value={publish}
            onChange={(e)=>setPublish(e.target.value)}     
          >
            <option value=''>----------</option>
            <option value='yes'>post event</option>
            <option value='not'>do not post</option>
            
           
          </select>
      
      }
      <h4>{publish==='yes'?'event is published':'event is not published'}</h4>
  
    </div>

      {!isDialogOpen && (<div className='mb-3'>
          <h3>Number of participants/tickets for sale:</h3>
          <input 
            type="number"
            className='form-control'
            id='numberofPeople'
            name='numberofPeople'
            required 
            value={numberofPeople} 
            onChange={(e) => handleChange(e.target.value,e.target.name)}
          />
        </div>)}
            {isDialogOpen&&<div><h4>{numberofPeople}</h4></div>}
    
        <div className="mb-3">            
            <h3>Ticket Price:</h3>
            <h4>${price}</h4> 
        </div>
        <div className="mb-3">                
           <h3>Description:</h3> 
       <h4>{description}</h4>       
        </div>
        <div className="mb-3">                
           <h3>Tickets sold:</h3> 
       <h4>{tickets_sold}</h4>       
        </div>
      </div>
      <div className='mb-3'>
        <button className="btn btn-primary" disabled={isDialogOpen}  type="submit">Save Changes</button>
      </div>
    </form>  
    <div className='mb-3'>
        <button className="btn btn-primary" disabled={isDialogOpen} onClick={handleCancel} type="submit">Discard Changes</button>
      </div>
      <div className='mb-3'>
      <button className="btn btn-danger" disabled={isDialogOpen}
        onClick={handleSubmitDelete}>
        Delete Event
      </button>
    </div>
    </div>
        <ConfirmationDialog
        isOpen={isDialogOpen}
        message={confirmMessage}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        isDeleting={isDeleting}
        isSaving={isSaving}
        handleDelete={handleDelete}
      />                
  </div> 
  );
}

export default Singleproduct;