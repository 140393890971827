import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Orders from './pages/Orders';
import Singleorder from './pages/Orderssingle';
import Cproduct from './pages/Cproduct';
import Packagingset from './pages/Packagingset';
import Lessonfeeset from './pages/Lessonfeeset';
import Resetpwd from './pages/Resetpwd';
import Products from './pages/Products';
import Home from './pages/Home'
import auth from './utils/auth';
import Singleproductpage from "./components/Admin/CProduct/Singleproductpage";
import Singleeventpage from "./components/Admin/Cevent/Singleeventpage";
import Checkout from './pages/Checkout';
import Header from './components/Header';
import Payment from './pages/Payment';
import About from './pages/About';
import Confirmation from './pages/Orderconfirmation';
import Footer from "./components/Footer";
import Setdates from "./pages/Setdates";
import Welcome from './pages/Welcome';
import Booking from './pages/Booking';
import Cevent from './pages/Cevent';
import Myevents from './pages/Myevents';
import Eventspublish from './pages/Eventspublish';
import Paymentlesson from './pages/Paymentlesson';
import Paymentevent from './pages/Paymentevent';

function App() {
  
  const [navigatemenuopen, setNavigatemenuopen] = useState(false);
  const [ppalk, setppalk]=useState('')

  const[productpage,setProductPage] = useState('')
  const[eventpage,setEventPage] = useState('')
  const [productsOrder, setProductsOrder] = useState([]);
  const [order, setOrder]=useState('')
  const [selectService, setSelectFdxSerName] = useState('');
  const [selectFdxSerFee, setSelectFdxSerFee] = useState('');
  const[preOrder, setPreOrder]=useState('')
  const[paintingTotal, setPaintingTotal]=useState('')
  const[packagingTotal, setpackagingTotal]=useState('')
  const[packagingDUpdts, setpackagingDUpdts]=useState('')
  const[paintDUpdts, setPaintDUpdts]=useState('')
  const [userFormData, setUserFormData] = useState({name:'', email:'',phone:''});

  const [lessonOrder, setLessonOrder] = useState('');
  const [orderComplete, setOrderComplete] = useState(false);

  const[myEventOder, setMyEventOrder]=useState('')
  const [eventOrderComplete, setEventOrderComplete] = useState(false);

 
  useEffect(() => {
    if (selectService&&preOrder&&selectFdxSerFee&&paintingTotal&&packagingTotal) {
      const total=(selectFdxSerFee*1)+(paintingTotal*1)+(packagingTotal*1)
      const orderobj={...preOrder, total:total, shippingFee:selectFdxSerFee, delivery:selectService}
      
      const ordertopass={...orderobj,...userFormData}
      setOrder(ordertopass)
    }

  }, [selectService, preOrder,selectFdxSerFee,packagingTotal, paintingTotal,userFormData ]);

   return (
    <Router>
      <main >
        <Header       
        setNavigatemenuopen={setNavigatemenuopen}
        navigatemenuopen={navigatemenuopen}/>
        <div > 
          <Routes> 
          <Route path="/home" element={<Home setProductsOrder={setProductsOrder} navigatemenuopen={navigatemenuopen}
          productsOrder={productsOrder} />} />
            <Route path="/About" element={<About
            navigatemenuopen={navigatemenuopen}/>} />
            <Route path="/booking" 
            
              element={
              <Booking
              navigatemenuopen={navigatemenuopen}
              setLessonOrder={setLessonOrder}
              setppalk={setppalk}
              setOrderComplete={setOrderComplete} 
              />
              } 
            />
            <Route path="/eventsfs" 
            element={
            <Eventspublish
            navigatemenuopen={navigatemenuopen}
         setMyEventOrder={setMyEventOrder}
         setEventOrderComplete={setEventOrderComplete}
         setppalk={setppalk}
            />} 
            />
            <Route path="/" element={<Welcome
            setNavigatemenuopen={setNavigatemenuopen}
            navigatemenuopen={navigatemenuopen}/>} />
            <Route path="/Checkout" element={
            <Checkout 
            ppalk={ppalk} 
            setSelectFdxSerName={setSelectFdxSerName} 
            setSelectFdxSerFee={setSelectFdxSerFee}
            setPreOrder={setPreOrder}
            setPaintingTotal={setPaintingTotal}
            setpackagingTotal={setpackagingTotal}
            setUserFormData={setUserFormData}
            setppalk={setppalk} 
            userFormData={userFormData}
            productsOrder={productsOrder}
            order={order}
            selectFdxSerFee={selectFdxSerFee}
            setpackagingDUpdts={setpackagingDUpdts}
            setPaintDUpdts={setPaintDUpdts}
            paintingTotal={paintingTotal}
            packagingTotal={packagingTotal}
            />
          
          }
            />
            <Route path="/Payment" element={<Payment packagingDUpdts={packagingDUpdts} paintDUpdts={paintDUpdts} order={order} setOrder={setOrder} ppalk={ppalk}/>} />
            <Route path="/Paymentlesson" 
            element={orderComplete&&lessonOrder==='' ? <Navigate to="/Confirmation" /> : <Paymentlesson
            lessonOrder={lessonOrder}
            ppalk={ppalk} 
            setLessonOrder={setLessonOrder}
            setOrderComplete={setOrderComplete}
            />} 
            
            />
            <Route path="/Paymentevents" 
            element={eventOrderComplete&&myEventOder!==''? <Navigate to="/Confirmation" /> : <Paymentevent
            myEventOder={myEventOder}
            ppalk={ppalk} 
            setppalk={setppalk}
            setMyEventOrder={setMyEventOrder}
            setEventOrderComplete={setEventOrderComplete}
            />} 
            
            />
            <Route path="/Confirmation" element={<Confirmation/>} />

            <Route 
              path="/admin" 
              element={auth.loggedIn() ? <Navigate to="/Orders" /> : <Login />} 
            />            
            <Route 
              path="/Orders" 
              element={auth.loggedIn() ?<Orders
              />:<Navigate to="/admin" />} 
            />
            <Route 
              path="/Products" 
              element={auth.loggedIn() ? <Products
              />:<Navigate to="/admin" />} 
            />
            <Route 
              path="/Createproduct" 
              element={auth.loggedIn() ?<Cproduct 
              setProductPage={setProductPage}  
              />:<Navigate to="/admin" />} 
            />
            <Route 
              path="/:orderId" 
              element={auth.loggedIn() ?<Singleorder
              />:<Navigate to="/admin" />} 
            />
            <Route 
              path="/singleproductpage" 
              element={auth.loggedIn() ?<Singleproductpage
              productpage={productpage}
              />:<Navigate to="/admin"/>} 
            />
            <Route 
              path="/singleeventpage" 
              element={auth.loggedIn() ?<Singleeventpage
                eventpage={eventpage}
              />:<Navigate to="/admin"/>} 
            />
            <Route 
              path="/setavaldates" 
              element={auth.loggedIn() ?<Setdates
              />:<Navigate to="/admin"/>} 
            />
            <Route 
              path="/resetpassword/:token" 
              element={<Resetpwd/>} 
            />
            <Route 
              path="/packageparams" 
              element={auth.loggedIn() ?<Packagingset
              />:<Navigate to="/admin" />} 
            />
            <Route 
              path="/lessonfeeset" 
              element={auth.loggedIn() ?<Lessonfeeset
              />:<Navigate to="/admin" />} 
            />
            <Route 
              path="/createmyeventsadmin" 
              element={auth.loggedIn() ?<Cevent
              setEventPage={setEventPage}
              />:<Navigate to="/admin" />} 
            />
            <Route 
              path="/myeventsadmin" 
              element={auth.loggedIn() ?<Myevents
              />:<Navigate to="/admin" />} 
            />
          </Routes>
        </div>
        <Footer 
        setNavigatemenuopen={setNavigatemenuopen}
        />
      </main>
    </Router>
  );
}

export default App;
