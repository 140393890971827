import React, { useState } from 'react';


function ReadMore({ text, maxLength = 50 }) {
    const [showLess, setShowLess] = useState(true);
  
    if (text.length <= maxLength) {
      return <span>{text}</span>;
    }
  
    return (
      <span>
        {showLess ? `${text.substring(0, maxLength)}...` : text}
        <button onClick={() => setShowLess(!showLess)} className="btn btn-link p-0 align-baseline" style={{color:"greenyellow"}}>
          {showLess ? 'Read More' : 'Read Less'}
        </button>
      </span>
    );
  }
  
  export default ReadMore;