import React, { useEffect, useState } from 'react';
import { Api } from '../../../utils/api';
import {useNavigate } from 'react-router-dom';
import ConfirmationDialog from '../ConfirmationDialog'
import { Decimalstorequest } from '../../../utils/Decimalstorequest'
import { Decimals } from '../../../utils/Decimals'
function Singleproduct({ 
id,
refetchsingle,
setRefetch, 
setActiveProductIndex,
allCategories,
setRefetchCat,

        setPIsLoading
}) 
{
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [fetchStatus, setFetchStatus] = useState(true);

  const [sproduct,setSProduct] = useState('')

  const [isSPLoading, setSPIsLoading] = useState(true);

  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [artist, setArtist] = useState('');
  const [paintLength, setPaintLength] = useState('');
  const [paintWidth, setPaintWidth] = useState('');
  const [paintHeight, setPaintHeight] = useState('');
  const [paintWeight, setPaintWeight] = useState('');
  const [packageLength, setpackageLength] = useState('');
  const [packageWidth, setpackageWidth] = useState('');
  const [packageHeight, setpackageHeight] = useState('');
  const [packageWeight, setpackageWeight] = useState('');
  const [packageFee, setpackageFee] = useState('');

  const [condition, setCondition] = useState('');

  const [picsToDelete, setPicsToDelete]=useState([])
  const [files,setFiles]=useState([])

  const [editCategory,setEditCategory]=useState(false)

  const [selCategoryOpt, setSelCatOpt] = useState('');
  const [selTypeCat, setTypeCat] = useState('');
  const [category, setSelectedCat] = useState('');

  const [editAvailability,setEditAval]=useState(false)
  const [availability, setSelectedAval] = useState('');

  const [editPackaging,setEditPackaging]=useState(false)
  const[packageType, setPackageType] = useState('');

  const changeFiles =(e)=>{
    setFiles(e.target.files)
  }

  useEffect(() => {
    const fetchSingleProduct = async () => {

      try {
        const response = await Api.get(`/getsinglepainting/${id}`, {
          headers: {
            Authorization: `${localStorage.getItem('id_token')}`,
          },
        });

        if (response && response.data) {
          setSProduct(response.data);
          setSPIsLoading(false);
        }
      } catch (error) {
        setSPIsLoading(false);
        if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error
          setFetchStatus(false);
        }
      }finally{
        setRefetchCat(true)
      }
    };

    if(id!==''||refetchsingle===true){
      fetchSingleProduct();
      setRefetch(false)
    }

  }, [id, refetchsingle, setRefetch, setRefetchCat]);

  useEffect(() => {

  if (sproduct) {
    setTitle(sproduct.title || '');
    setPrice(sproduct.price || '');
    setDescription(sproduct.description || '');
    setArtist(sproduct.artist || '');
    setPaintLength(sproduct.paintLength||'');
    setPaintWidth(sproduct.paintWidth||'');
    setPaintHeight(sproduct.paintHeight||'');
    setPaintWeight(sproduct.paintWeight||'');
    setpackageLength(sproduct.packageLength||'');
   setpackageWidth(sproduct.packageWidth||'');
    setpackageHeight(sproduct.packageHeight||'');
    setCondition(sproduct.condition || '');
    setSelectedCat(sproduct.category || '');
    setSelectedAval(sproduct.availability || '');
    setpackageWeight(sproduct.packageWeight||'');
    setpackageFee(sproduct.packageFee||'');
    setPackageType(sproduct.packageType||'');
  }
  }, [sproduct]);

  useEffect(() => {
    if (selTypeCat !== '') {
    setSelectedCat(selTypeCat);
    } else {
    setSelectedCat(selCategoryOpt);
    }

  }, [selTypeCat, selCategoryOpt]);

  function handleDeletePic (photo) {
    setPicsToDelete([...picsToDelete,photo])
  }

  function handleCancelDeletePic (photo) {
    const filterPicsToDelete = picsToDelete.filter((pic)=>pic!==photo)
    setPicsToDelete(filterPicsToDelete)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSaving(true)
    setConfirmMessage("Are you sure you want to save the changes?")
    setIsDialogOpen(true);
  };

  const handleDelete = async () => {
    
        setPIsLoading(true)
    setIsDialogOpen(false);
    try {
      const response = await Api.delete(`/deletepainting/${id}`, {
        headers: {
          Authorization: `${localStorage.getItem('id_token')}`,
        },
      });

      if (response.status === 200) {
        setPIsLoading(false)
        alert('painting deleted');
        setActiveProductIndex(null)
      } else {
        setPIsLoading(false)
        alert('Failed to delete painting');
      }

    } catch (err) {
      setPIsLoading(false)
      if (err.response && err.response.status === 401) {
      // Handle 401 Unauthorized error
      alert('Failed to delete painting');
      setFetchStatus(false);
      } else {
        alert('Failed to delete painting');
      }
    }finally{
      setRefetch(true)
    }
  };
  const handleSubmitDelete = (event) => {
    event.preventDefault();
    // Open confirmation dialog
    setIsDeleting(true)
    setConfirmMessage("Are you sure you want to delete this painting?")
    setIsDialogOpen(true);
  };

  const cancelEditCategory =(e) => {
    e.preventDefault();
    setEditCategory(false)
  };

  const handleSelCatOpt = (e) => {
    setTypeCat('')
    setSelCatOpt(e.target.value)
  }

  const handleTypeCat = (e) => {
    setSelCatOpt('')
    setTypeCat(e.target.value)
  };


  const handleChange = (value,p) => {

    let v = value
    if(v<=0){
    v=''
    }
    if(p==='price'||p==='packageFee'){
      if(value.toString().includes('.')){
              v=Decimals(value,2)
      }
    }else if(p==='packageWeight'||p==='paintWeight'){
      if(value.toString().includes('.')){
        v=Decimals(value,1)
    }
    }else{
      if(value.toString().includes('.')){
        v=Decimals(value,0)
    }
    }

    switch (p) {
      case 'price':
        setPrice(v);
        break;
      case 'packageHeight':
        setpackageHeight(v);
        break;
      case 'packageWidth':
        setpackageWidth(v);
        break;
      case 'packageLength':
        setpackageLength(v);
        break;
      case 'paintLength':
        setPaintLength(v);
        break;
      case 'paintWidth':
        setPaintWidth(v);
        break;
      case 'paintHeight':
        setPaintHeight(v);
        break;
      case 'packageWeight':
        setpackageWeight(v);
        break;
      case 'packageFee':
        setpackageFee(v);
        break;
      case 'paintWeight':
        setPaintWeight(v);
        break;
      default:
        break;
    } 
};

  const handleConfirm = async (e) => {
    setPIsLoading(true)
    setIsDialogOpen(false);
    let setPriceVal = Decimalstorequest(price,2)
    let setPackageFeeVal = Decimalstorequest(packageFee,2)
    let setpackageWeightVal = Decimalstorequest(packageWeight,1)
    let setpaintWeightVal = Decimalstorequest(paintWeight,1)

    // Proceed with form submission logic
    e.preventDefault();

    try {

      let categoryToUse = category

      if(categoryToUse!==''){
  
        if (selTypeCat !== '') {
        const response = await Api.post('/createcategory', { categoryname: selTypeCat, paintId:id }, {
          headers: {
            Authorization: `${localStorage.getItem('id_token')}`,
          },
        });

        if (response && response.data) {
          categoryToUse = response.data.id; // Use the new category
        }
    
        }else if(categoryToUse._id!==undefined&&categoryToUse._id!==null&&categoryToUse._id!==''){
          categoryToUse=category._id
    
        }
      } 

      const   updatedProduct = {
      title, 
      price:setPriceVal, 
      description, 
      artist, 
      paintWidth, 
      paintHeight, 
      paintLength,
      paintWeight:setpaintWeightVal, 
      packageWidth, 
      packageHeight, 
      packageLength, 
      packageWeight:setpackageWeightVal, 
      packageType,
      packageFee:setPackageFeeVal, 
      condition, 
      availability, 
      category:categoryToUse
      };

      

      const response = await Api.post(`/updatepainting/${id}`, {
        ...updatedProduct
        },  {
        headers: {
          Authorization: `${localStorage.getItem('id_token')}`,
        },
      });

      if(files.length>0){
        const formData = new FormData()
        for(const file of files){
          formData.append("photos", file)
        }
        const response2 = await Api.post(`/uploadphotos/${id}`,formData, {
          headers:{
            "content-type":"multipart/form-data",
            Authorization: `${localStorage.getItem('id_token')}`,
          },
        });

        if (response2 && response2.data) {
          setFiles([]) 
          setPicsToDelete([])     
        }
      }

      if(picsToDelete.length>0){
        const response3 = await Api.post(`/deletephotos/${id}`,{photoUrl:picsToDelete}, {
          headers:{
            Authorization: `${localStorage.getItem('id_token')}`,
          },
        });

        if (response3 && response3.data) {
          setPicsToDelete([])  
          setFiles([]) 
        }
      }

      if (response.status === 200) {
        setPIsLoading(false)
        setEditCategory(false)
        setEditAval(false)
        setEditPackaging(false)
        alert('painting updated'); 
      } else {
        setPIsLoading(false)
        alert('Failed to update painting');
      }

    } catch (err) {
      setPIsLoading(false)
      if (err.response && err.response.status === 401) {
      // Handle 401 Unauthorized error
      alert('Failed to update painting');
        setFetchStatus(false);
      } else {
        alert('Failed to update painting');
      }

    }finally{
      setRefetch(true)
    }
  };

  const handleCancel = () => {
    setIsDeleting(false)
    setIsSaving(false)
    setIsDialogOpen(false);
  };

  if(!fetchStatus){
    return(
      navigate('/')
    )
  }

  if (isSPLoading) {
    return (<div>Loading...</div>)
  }

  return (
  <div className="container px-2 text-bg-dark p-3">
    <div className={isDialogOpen?"confirmopen":""}>

    <div className="mb-3">
      <h3>Category:</h3>
      {!editCategory&&sproduct.category&&
        <h3 className="text-secondary">{sproduct.category.categoryname}</h3>
      }
      {editCategory===true&&!isDialogOpen&&
        <div className="mb-3">
          <select
          className="form-select"
            name="category"
            value={selCategoryOpt}
            onChange={handleSelCatOpt}
          >
            <option value=''>Select a category</option>
            {allCategories&&allCategories.map((cat,index) => (
              <option key={index} value={cat._id}>
                {cat.categoryname}
              </option>
            ))}
                 </select>
          <div className="my-2">or</div>
          <label className="form-label">
            type a new category:
            <input type="text" className="form-control" value={selTypeCat} onChange={handleTypeCat} />
          </label>
        </div>
      }
      <div className="mb-3">
      {editCategory===false&&
        
        <button className="btn btn-primary" disabled={sproduct.editable==='no'||isDialogOpen}
        
          onClick={() => setEditCategory(true)}>
        Edit
        </button>
      
    }
          {editCategory&&
        <button
        className="btn btn-primary"
        disabled={isDialogOpen}
          onClick={cancelEditCategory}>
        Cancel
        </button>
      }
      </div>


    </div>
    
    
    
    <div className="mb-3">
      <h2>Date created:</h2>
      <h3 className="text-secondary">
        {(sproduct.creationDate).split('T')[0]}      
      </h3>
    </div>

    <div className="mb-3">
      <h2>Availability:</h2>
      {editAvailability===false&&<h3 className="text-secondary">
        {availability}      
      </h3>}
      {editAvailability===true&&!isDialogOpen&& 
          <select
          className="form-select"
            name="availability"
            value={availability}
            onChange={(e)=>setSelectedAval(e.target.value)}     
          >
            <option value='sold'>sold</option>
            <option value='unavailable'>unavailable</option>
            <option value='available'>available</option>
          </select>
      
      }
        <div className="mb-3">
        {editAvailability===false&&
          <button className="btn btn-primary" disabled={sproduct.editable==='no'||isDialogOpen}style={{ cursor: 'pointer' }}
            
            onClick={() => setEditAval(true)}>
            Edit
          </button>  }
        </div>
      {editAvailability&&
        <button
        disabled={isDialogOpen}
        className="btn btn-secondary"
          onClick={() => {setEditAval(false); setSelectedAval(sproduct.availability)}}>
          Cancel
        </button>
      }
    </div>
    <div className="mb-3 d-flex flex-wrap">
      {sproduct.pics&&sproduct.pics.map((element,index) => (
        <div key={index} className="p-2">
          <img style={{width: '200px'}} className={`img-thumbnail ${picsToDelete.includes(element) ? 'toDelete' : ''}`} src={element} alt={`${element} ${index}`}/>          
          {picsToDelete.includes(element)? (
            <div>
              <button className="btn btn-secondary mt-2" disabled={isDialogOpen} style={{ cursor: 'pointer' }}
                onClick={() => handleCancelDeletePic(element)}>
              Cancel
            </button>
          </div> 
          ) : (
          <div>    
            <button  className="btn btn-danger mt-2" disabled={isDialogOpen} style={{ cursor: 'pointer' }}
              onClick={() => handleDeletePic(element)}>
              Delete
            </button>
          </div>
          )}
        </div>
      ))}
    </div>
    <div className="mb-3" >
      
        <h3>Add photo</h3>
     
      {!isDialogOpen&&<input id="file-upload" type="file" className="form-control" multiple onChange={changeFiles}/>}
    </div>
    <form onSubmit={handleSubmit}>
      <div >
        <div className="mb-3">      
            <h3>Title:</h3>
            {!isDialogOpen&&         
            <input type="text" className="form-control" 
            value={title} onChange={(e) => setTitle(e.target.value)} />}
            {isDialogOpen&&<div><h3>{title}</h3></div>}     
        </div>
        <div className="mb-3">            
            <h3>Price:</h3>
            {!isDialogOpen&&
            <input type="number" className="form-control"
            name='price'
            required
            value={price} 
            onChange={(e) => handleChange(e.target.value,e.target.name)} />}
            {isDialogOpen&&<div><h3>{price}</h3></div>}     
        </div>
        <div className="mb-3">                
           <h3>Description:</h3> 
            {!isDialogOpen&&<textarea className="form-control" rows="3" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />}
            {isDialogOpen&&<div><h3>{description}</h3></div>}       
        </div>
        <div className="mb-3">        
            <h3>Author:</h3>       
            {!isDialogOpen&&<input type="text" className="form-control" value={artist} onChange={(e) => setArtist(e.target.value)} />}
            {isDialogOpen&&<div><h3>{artist}</h3></div>}    
        </div>
        <div>        
      <h3>Painting Dimensions(inches):</h3>
      {!isDialogOpen && (
  <div className='container'>
    <div className='row'>
      <div className='col-sm-4'>
        <div className='mb-3'>
          <label htmlFor='paintLength' className='form-label'>L:</label>
          <input 
            type="number"
            className='form-control'
            id='paintLength'
            name='paintLength'
            required 
            value={paintLength} 
            onChange={(e) => handleChange(e.target.value,e.target.name)}
          />
        </div>
      </div>
      <div className='col-sm-4'>
        <div className='mb-3'>
          <label htmlFor='paintWidth' className='form-label'>W:</label>
          <input 
            type="number" 
            className='form-control'
            id='paintWidth'
            name='paintWidth' 
            required 
            value={paintWidth}
            onChange={(e) => handleChange(e.target.value,e.target.name)}
          />
        </div>
      </div>
      <div className='col-sm-4'>
        <div className='mb-3'>
          <label htmlFor='paintHeight' className='form-label'>H:</label>
          <input 
            type="number" 
            className='form-control'
            id='paintHeight'
            name='paintHeight'
            required 
            value={paintHeight} 
            onChange={(e) => handleChange(e.target.value,e.target.name)}
          />
        </div>
      </div>
    </div>
  </div>
)}
            {isDialogOpen&&<div><h3>{paintLength}x{paintWidth}x{paintHeight}</h3></div>}
       
        </div>

        <div className="mb-3">        
      <h3>Painting Weight(lbs):</h3>
            {!isDialogOpen&&
           
         
              <input type="number"
              className="form-control" 
              name='paintWeight'
               required value={paintWeight} 
                onChange={(e) => handleChange(e.target.value,e.target.name)} />       
            }
            
            {isDialogOpen&&<div><h3>{paintWeight}</h3></div>}
         
        </div>

        <div className='mb-3'>
      <h3>Package Type:</h3>
      
      {editPackaging===false&&< h3 className="text-secondary">
        {packageType}      
      </h3>}
      {editPackaging===true&&!isDialogOpen&&
        <div>
          <select
          className="form-select"
            name="freightPackaging"
            value={packageType}
            onChange={(e)=>setPackageType(e.target.value)}
           
          >
            <option value='CRATE'>Crate</option>
            <option value='BOX'>Box</option>
          </select>
        </div>
      }
      {editPackaging===false&&
        <div>
          <button disabled={sproduct.editable==='no'||isDialogOpen}style={{ cursor: 'pointer' }}
            className="btn btn-primary"
            onClick={() => setEditPackaging(true)}>
            Edit
          </button>
        </div>
      }
      {editPackaging&&
        <button
        disabled={isDialogOpen}
        className="btn btn-primary"
          onClick={() => {setEditPackaging(false); setPackageType(sproduct.packageType)}}>
          Cancel
        </button>
      }
    </div>
        <div className='mb-3'>        
      <h3>Package Fee $:</h3>
            {!isDialogOpen&&
            
       
              <input type="number" 
              className="form-control"
              required 
              name='packageFee'
              value={packageFee} 
              onChange={(e) => handleChange(e.target.value,e.target.name)} />
            
        
            }
            {isDialogOpen&&<div><h3>{packageFee}</h3></div>}
            
        </div>
        <div>        
      <h3>Package Dimensions(inches):</h3>
      {!isDialogOpen && (
  <div className="container">
    <div className="row">
      <div className="col-sm">
        <div className="mb-3">
          <label htmlFor="packageLength" className="form-label">L:</label>
          <input 
            type="number" 
            className="form-control" 
            id="packageLength" 
            name="packageLength" 
            required 
            value={packageLength} 
            onChange={(e) => handleChange(e.target.value, e.target.name)} 
          />
        </div>
      </div>
      <div className="col-sm">
        <div className="mb-3">
          <label htmlFor="packageWidth" className="form-label">W:</label>
          <input 
            type="number" 
            className="form-control" 
            id="packageWidth" 
            name="packageWidth" 
            required 
            value={packageWidth} 
            onChange={(e) => handleChange(e.target.value, e.target.name)} 
          />
        </div>
      </div>
      <div className="col-sm">
        <div className="mb-3">
          <label htmlFor="packageHeight" className="form-label">H:</label>
          <input 
            type="number" 
            className="form-control" 
            id="packageHeight" 
            name="packageHeight" 
            required 
            value={packageHeight} 
            onChange={(e) => handleChange(e.target.value, e.target.name)} 
          />
        </div>
      </div>
    </div>
  </div>
)}

            {isDialogOpen&&<div><h3>{packageLength}x{packageWidth}x{packageHeight}</h3></div>}
       
        </div>

    <div className='mb-3'>
          <h3>Package+Paint Weight(lbs):</h3>
          
            {!isDialogOpen&&
            <input type="number"
            className="form-control"
            name='packageWeight' 
            required 
            value={packageWeight} 
            onChange={(e) => handleChange(e.target.value,e.target.name)} />}
            {isDialogOpen&&<div><h3>{packageWeight}</h3></div>}
        
        </div>
        <div className='mb-3'>
          <h3>Condition:</h3>
            {!isDialogOpen&&<input className="form-control" type="text" value={condition} onChange={(e) => setCondition(e.target.value)} />}
            {isDialogOpen&&<div><h3>{condition}</h3></div>}
         
        </div>
      </div>
      <div className='mb-3'>
        <button className="btn btn-primary" disabled={sproduct.editable==='no'||isDialogOpen}  type="submit">Save Changes</button>
      </div>
    </form>  
    <div className='mb-3'>
      <button className="btn btn-primary" disabled={sproduct.editable==='no'||isDialogOpen} style={{ cursor: 'pointer' }}
        onClick={handleSubmitDelete}>
        Delete Painting
      </button>
    </div>
    </div>
        <ConfirmationDialog
        isOpen={isDialogOpen}
        message={confirmMessage}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        isDeleting={isDeleting}
        isSaving={isSaving}
        handleDelete={handleDelete}
      />                
  </div> 
  );
}

export default Singleproduct;