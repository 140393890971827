
import React, { useEffect, useState } from 'react';
import Loginmessage from "../Loginmessage";
import { Api } from '../../../utils/api';

function Singleproducpage({
  productpage
}) 
{
  
  const [renProduct,setRenProduct] = useState('')
  const [renPrIsLoading,setRpIsLoading] = useState(true)
  const [fetchStatus, setFetchStatus] = useState(true);
  const [errorFetchingPr, setErrorFetching] = useState(false);

  useEffect(() => {
  const fetchSingleProduct = async () => {
    try {
      const response = await Api.get(`/getsinglepainting/${productpage}`, {
        headers: {
          Authorization: `${localStorage.getItem('id_token')}`,
        },
      });


      if (response && response.data) {
        setRenProduct(response.data);
        setRpIsLoading(false);
      }
    } catch (error) {
      setRpIsLoading(false);
      if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error
        setFetchStatus(false);
       
      } else {
        setErrorFetching(true)
        
      }
    }
  };
  fetchSingleProduct();
}, [productpage]);

if(!fetchStatus){
  return(
    <Loginmessage/>
  )
}

if(errorFetchingPr){
  return(
    <div  className='custom6 text-center custom4'><h3>no product to show</h3></div>
  )
}

if(renPrIsLoading){
  return <div className='custom6 custom4'>loading...</div>
}

return (
<div className="container mt-3 pb-3 custom6">
<div className="card border-primary text-white bg-dark">
    <div className="card-body">
        <h5 className="card-title text-center">{renProduct.title}</h5>

        <div className="text-center my-3">
            {renProduct.pics && renProduct.pics.map((element, index) => (
                <img key={index} src={element} alt={`Product ${index}`} 
                     className="img-fluid img-thumbnail mx-1 custom9" 
                     /> // Applied img-fluid here
            ))}
        </div>

        <p className="card-text">
            <strong>Category:</strong> {renProduct.category ? renProduct.category.categoryname : 'no defined'}
        </p>

        <p className="card-text"><strong>Painter:</strong> {renProduct.artist}</p>

        <p className="card-text">
            <strong>Date created:</strong> {renProduct.creationDate.split('T')[0]}
        </p>

        <p className="card-text"><strong>Description:</strong> {renProduct.description}</p>

        <p className="card-text"><strong>Painting Price:</strong> $ {renProduct.price}</p>

        <p className="card-text">
            <strong>Paint Dimensions (inches):</strong> {renProduct.paintLength}x{renProduct.paintWidth}x{renProduct.paintHeight}
        </p>

        <p className="card-text"><strong>Paint Weight (lbs):</strong> {renProduct.paintWeight}</p>

        <p className="card-text">
            <strong>Package Dimensions (inches):</strong> {renProduct.packageLength}x{renProduct.packageWidth}x{renProduct.packageHeight}
        </p>

        <p className="card-text"><strong>Package+Paint Weight (lbs):</strong> {renProduct.packageWeight}</p>

        <p className="card-text"><strong>Package Fee:</strong> $ {renProduct.packageFee}</p>

        <p className="card-text"><strong>Package Type:</strong> {renProduct.packageType}</p>

        <p className="card-text"><strong>Condition:</strong> {renProduct.condition ? renProduct.condition : 'no defined'}</p>

        <p className="card-text"><strong>Availability:</strong> {renProduct.availability}</p>
    </div>
</div>

</div>

);
}

export default Singleproducpage;