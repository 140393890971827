import React from 'react';
// Make sure you import Link if you're using react-router

function Ordersingleelement({ order }) {
  return (
    <div className="container py-4">
      <div className="card border-success text-bg-dark">
        <div className="card-header">
          Order# {(order._id).slice(17)}
        </div>

        <div className="card-body">
          <h5 className="card-title">Order Details</h5>
          <p className="card-text"><strong>Date:</strong> {order.createdAt.split('T')[0]}</p>
          <p className="card-text"><strong>Total Order:</strong> $ {order.total}</p>
          {order.refnum1&&<p className="card-text"><strong>Paypal Order ref#:</strong> {order.refnum1}</p>}
          <p className="card-text"><strong>Delivery method:</strong> {order.delivery}</p>
          <p className="card-text"><strong>Package Dimension(inches):</strong> {order.package.dimensions.length} x {order.package.dimensions.width} x {order.package.dimensions.height}</p>
          <p className="card-text"><strong>Package Weight(lbs):</strong> {order.package.weight.value}</p>
          <p className="card-text"><strong>Package Type:</strong> {order.packageType}</p>
          <p className="card-text"><strong>Packaging fee:</strong> $ {order.packageFee}</p>
          {order.shippingFee && <p className="card-text"><strong>Shipping fee:</strong> $ {order.shippingFee}</p>}
          {order.delivery === 'pick up' && <p className="card-text"><strong>Pick up date(6pm to 9:30pm):</strong> {order.pickupSchedule}</p>}
        </div>

        <div className="card-body">
          <h5 className="card-title">Customer Details</h5>
          <p className="card-text"><strong>Name:</strong> {order.name}</p>
          <p className="card-text"><strong>Address:</strong> $ {order.address}</p>
          <p className="card-text"><strong>Email:</strong> {order.email}</p>
          <p className="card-text"><strong>Phone#:</strong> {order.phone}</p>
          {order.refnum2&&<p className="card-text"><strong>Paypal Customer ref#:</strong> {order.refnum2}</p>}
        </div>
      </div>

      <div className="my-4">
        <h2>*Paintings ordered*</h2>
        {order.goods && order.goods.map((good, index) => (
          <div key={good._id} className="card border-success text-bg-dark my-2">
            <div className="card-body">
              {good.item.pics && (
                <img src={good.item.pics[0]} className="img-fluid" alt={`${good.item.title}`} style={{maxHeight: '200px'}} />
              )}
              <p className="card-text"><strong>Item {index + 1}:</strong> {good.item.title}</p>
              <p className="card-text"><strong>Category:</strong> {good.item.category ? good.item.category.categoryname : 'No defined'}</p>
              <p className="card-text"><strong>Description:</strong> {good.item.description}</p>
              <p className="card-text"><strong>Dimensions(inches):</strong> L:{good.item.paintLength} W:{good.item.paintWidth} H:{good.item.paintHeight}</p>
              <p className="card-text"><strong>Price:</strong> $ {good.saleprice}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="text-center my-4">
      <h2>**Order Status**</h2>
        {order.refnum1!==null&&order.refnum1!==undefined&&order.refnum1!==''&&order.refnum2!==null
        &&order.refnum2!==undefined&&order.refnum2!==''?(<h2 className='text-success bg-black'>completed</h2>):(<h2 className='text-danger'>unsuccessfull</h2>)}
        
      </div>
    </div>
  );
}

export default Ordersingleelement;
