import React, { useEffect, useState } from 'react';
import { Alldata } from '../utils/api';
import AllEventsFs from '../components/Gallery/EventsFs/AlleventsFs.js'; 
import Canonical from '../components/Canonical'
import { Api } from '../utils/api';


function Eventpublish({ 
  navigatemenuopen,
  setMyEventOrder,
  setEventOrderComplete,
  setppalk
 }) {


  const [eventsFs, setEventsFs]=useState([])
  const [isPLoading, setPIsLoading] = useState(true);
  const [errorPfound, setErrorPfound]=useState(false)
  const[ppalstr, setppalstr]=useState('')
  const [loadingppal, setLoadingppal] = useState(false);
  const[errFoundppl, setErrFoundppal]=useState(false)

  useEffect(() => {
    setLoadingppal(true);
    const fetchpplk = async () => {
   
     try {
       const response = await Api.get('/ppalclt')
       if (response && response.data) {
          setppalstr(response.data)
          setLoadingppal(false)
       }
     }catch(error){
      setLoadingppal(false)
      setErrFoundppal(true);
      
     }
    };

   fetchpplk();
 }, []);  

  useEffect(() => {

    const fetchEventsFs= async () => {

      try {
        const response = await Alldata.get('/getallevents');

        if (response && response.data) {
          
          setEventsFs(response.data);
          setPIsLoading(false)
        }

      } catch (error) {
        setPIsLoading(false);
                 setErrorPfound(true);
          
        
      }
    };

    fetchEventsFs();
  }, []);


  if(isPLoading||loadingppal){
    return(
      <div>
{!navigatemenuopen&&<div className='text-light' style={{paddingTop:'150px',minHeight:'100vh'}}>Loading...
     </div>}
     </div>
     )
  }

  if (errorPfound||errFoundppl) {
    return (
      <div>
{!navigatemenuopen&&<div className='classminheight text-center text-light' style={{paddingTop:'200px'}}>
        An error has occurred on our side. Please try again later
      </div>}
      </div>
    );
  }

  if (eventsFs && eventsFs.length === 0) {
    return (
      <div>
{!navigatemenuopen&&<div className='classminheight text-center text-light' 
style={{paddingTop:'200px'}}><h4>Stay tuned for new events—more are coming soon!</h4></div>}
      </div>
    
     
  
)
  }

  return (
    <div>
      <Canonical url="https://www.mylovepainting.com/eventsfs" />
  {!navigatemenuopen&&<div className='gallerydiv'>
      <AllEventsFs 
      eventsFs= {eventsFs} 
      setMyEventOrder={setMyEventOrder}
      setEventOrderComplete={setEventOrderComplete}
      setppalk={setppalk}
      ppalstr={ppalstr}
      />
    </div>}
    </div>
  
  );
}



export default Eventpublish;