import React, { useEffect, useState } from 'react';
import { Api } from '../../../utils/api';
import Productlist from './Productlist';
import Auth from "../../../utils/auth";
import {useNavigate } from 'react-router-dom';
import { Link as ScrollLink} from 'react-scroll';

function Allproducts(
{ 
products,  
refetchsingle,
setRefetch,

setPIsLoading 
}) 
{
const navigate = useNavigate();

const[allCategories,setAllCategories]=useState([])
const [refetchCat,setRefetchCat]=useState(false)
const [categories, setCategories]=useState([])
const [fetchStatus, setFetchStatus] = useState(true);
const [isLoadingCs, setIsLoadingCs]=useState(true)
const [selArrCategories, setSelArrCategories] = useState([]);
const [errorCfound, setErrorCfound]=useState(false)
const [unselect, setUnselectall]=useState('')
const [availability, setAvailability]=useState('all')
const selopts=['select all', 'unselect all', ]
const [filteredproducts,setFilteredProducts]=useState([])

const [activeProductIndex, setActiveProductIndex] = useState(null);
const [activeSingleProductIndex, setActiveSingleProductIndex] = useState(null);

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

useEffect(() => {
  const fetchCategories = async () => {
    try {
      const response = await Api.get('/getpaintings/categories', {
        headers: {
          Authorization: `${localStorage.getItem('id_token')}`,
        },
      });

      if (response && response.data) {
        const newarr = response.data.map((element) => (
          element.categoryname
        ))

        const newArr =[...newarr,'no defined']
        setAllCategories(response.data)
        setSelArrCategories(newArr)
        setCategories([...response.data, { categoryname: 'no defined' }]);
        setIsLoadingCs(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
    // Handle 401 Unauthorized error
      setFetchStatus(false);
      }else{
        setErrorCfound(true);
        
      }
    }
  };
  fetchCategories();
}, []);

useEffect(() => {
  const fetchCategories = async () => {
    try {
      const response = await Api.get('/getpaintings/categories', {
        headers: {
          Authorization: `${localStorage.getItem('id_token')}`,
        },
      });

      if (response && response.data) {
        setAllCategories(response.data)
        setCategories([...response.data, { categoryname: 'no defined' }]);
        setIsLoadingCs(false);
      }
    }catch(error) {
      if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error
        setRefetchCat(false)
        setFetchStatus(false);        
      }else{
        setRefetchCat(false)
        setErrorCfound(true);
        
      }
    }finally{
      setRefetchCat(false)
    }
  };

  if(refetchCat===true){
    fetchCategories();
  }

}, [refetchCat]);

const handleCatOpt = (event) => {
  const value = event.target.value;
  let updatedCategories = [];

  if ((selArrCategories.includes(value))) {
    updatedCategories = selArrCategories.filter((f) => f !== value);
    setUnselectall(null)
  } else {
    updatedCategories = [...selArrCategories, value];
    setUnselectall(null)
  }
  setSelArrCategories(updatedCategories);
  setActiveProductIndex(null)
  setActiveSingleProductIndex(null)
}

const handleUnselectAll = (event) => {
  const value = event.target.value;
  if(unselect===value){
    setUnselectall(null)
  }else{
    setUnselectall(value)
    if(value==='unselect all'){
      setSelArrCategories([])
    }else{
      let cats = []
      cats = categories.map((element) => (
        element.categoryname
      ))
      setSelArrCategories(cats)
      setActiveProductIndex(null)
      setActiveSingleProductIndex(null)
    }
  }
}


useEffect(() => {
  const filterdAll = products.filter((p) => {

  if(selArrCategories&&p.category&&categories){

    if(selArrCategories.includes(p.category.categoryname)){

      if(availability==='all'){
        return true
      }else if(availability===p.availability){
        return true
      }else{
        return false
      }

    }else{
      return false
    };
  }else{
    if((p.category===null||p.category===undefined)&&(selArrCategories.includes('no defined'))){
      if(availability==='all'){
        return true
      }else if(availability===p.availability){
        return true
      }else{
        return false
      }
    }else{
      return false
    }
  }
  })

  setFilteredProducts(filterdAll)
}, [selArrCategories,availability, categories,products]);


useEffect(() => {
  if(!Auth.loggedIn){
    setFetchStatus(false)
  }
}, [filteredproducts, activeProductIndex,activeSingleProductIndex,unselect]);


if(!fetchStatus){
  return(
    navigate('/')
  )
}

if(isLoadingCs===true){
  return<div className='custom6'>loading...</div>
}

return (
<div>
{filteredproducts&&filteredproducts.length>3&&<code className='up' style={{ cursor: 'pointer'}} onClick={scrollToTop}>&#x21ea;</code>}
  <div className="container text-center">
    <div className="row justify-content-center">    
    <div className="col-auto">
    {!errorCfound&&selopts.map((element,index) => (
    <div className='form-check form-check-inline' key={index}>
  
      <input
      
      className="form-check-input"
        type='checkbox'
        value={element}
        checked={unselect===element}
        onChange={handleUnselectAll}
      />
                <label className="form-check-label" htmlFor="Top">
        {element}
      </label> 
       
    </div>
    ))}
    </div>
    <div className="col-auto">
    {!errorCfound&&categories.map((option) => (
      <div className='form-check form-check-inline' key={option.categoryname}>
        <input
          type='checkbox'
          className="form-check-input"
          value={option.categoryname}
          checked={selArrCategories.includes(option.categoryname)}
          onChange={handleCatOpt}
        />
        <label className="form-check-label">
          {option.categoryname}
        </label> 
      </div>
    ))}
    </div>
  
    {selArrCategories.length>0&&
      <div className="col-auto">
        <label>Availability:</label>
        <select
          name="availability"
          value={availability}
          onChange={(e)=>setAvailability(e.target.value)}
        >
          <option value="all">All</option>      
          <option value="available">available</option>
          <option value="sold">sold</option>
          <option value="unavailable">unavailable</option>    
        </select>
      </div>
    }</div>
 
  </div>
  <div className='text-center my-3'>
    <button id="Top"
   className="btn btn-primary btn-sm"
      type="submit"
      onClick={() => {
        navigate('/Createproduct')
      }}
    >
    Create New Painting
    </button>
  </div>
  {!errorCfound&&filteredproducts&&
    <div>
      <Productlist 
        filteredproducts={filteredproducts} 
        refetchsingle={refetchsingle}
        setRefetch={setRefetch}
        activeProductIndex={activeProductIndex} 
        setActiveProductIndex={setActiveProductIndex}
        activeSingleProductIndex={activeSingleProductIndex}
        setActiveSingleProductIndex={setActiveSingleProductIndex}
        allCategories={allCategories}
        setRefetchCat={setRefetchCat}
        
        setPIsLoading={setPIsLoading} 
      />
    </div>
  }
</div>
);
}

export default Allproducts;