import React from 'react';
import {PayPalButtons} from "@paypal/react-paypal-js";
import {  useRef } from "react";
import {Api} from "../../../utils/api";


const ButtonWrapper = ({
  currency,
  lessonOrder,
  setIstimeout,
  istimeout,
  setLessonOrder,
  setOrderComplete
          
 }) => {

  const timeoutId = useRef(null)
  const orderobj = useRef(lessonOrder)
  const preorder = useRef('');
 

  const handleCancel= async () => {
    return window.location.replace('/');
    }

    const handleTimeOut = async() => {
     setIstimeout(true)
      }

    const handleInitiation=async (b) => { 
try {
 const myorder = orderobj.current
 
  const response = await Api.post('/checktimeslots',myorder);
              
  if (response && response.status===200) {
    const preOrder = { ...myorder, refnum1: b };
    preorder.current = preOrder
   
  }else{
    orderobj.current=''
    setIstimeout(true)
    setLessonOrder('')
    alert('An error ocurred and your order could not be processed')
  }

} catch (error) {
  setIstimeout(true)
  
  alert('An error ocurred and your order could not be processed')
  setLessonOrder('')
 
}
      }

      const handleCapture = async (a) => {   
       
        const order = { ...preorder.current, refnum2: a };
            if(a===''||a===undefined||a===null){
return alert('An error ocurred and your order could not be processed.Please claim a refund if any transaction has been made. ')
            }else{
              try {
     
                    const response = await Api.post('/createlessonorder',order);
              
                    if (response && response.data) {
                      
                      const orderUrl = response.data.order;
                      // Redirect the user to the Google Cloud URL
                      window.location.href = orderUrl;
                     
                      setOrderComplete(true)
                      setLessonOrder('')
                    }else{
                      alert('An error ocurred and your order could not be processed.Please claim a refund if any transaction has been made. ')
                    setLessonOrder('')
                    setIstimeout(true)
                    }
                  } catch (error) {
                    alert('An error ocurred and your order could not be processed.Please claim a refund if any transaction has been made. ')
                    setLessonOrder('')
                  }

                }
                };
   
   return(

          <div style={{minHeight:'90vh', position:'relative'}}>
                <div className=' text-center mt-1 text-light px-1'>
<h5>After your purchase is approved, a new tab will open displaying your order details in a printable PDF format. Additionally, a PDF file containing your order details will be sent to your email.</h5>
</div>
            <div className='text-center mt-3'>
            <button className="btn btn-secondary mb-3" style={{ cursor: 'pointer' }} onClick={handleCancel}>Cancel Order</button>
            </div>
        
          
                    
        {lessonOrder&&istimeout===false&&<div className='ppaldiv'>
        <PayPalButtons
 
            forceReRender={[orderobj, istimeout, currency]}
            createOrder={(data, actions) => {
              return actions.order
                .create({
                  purchase_units: [
                    {
                      amount: {
                        currency_code: currency,
                        value: lessonOrder.total,
                      },
                      description: `Yenis Camargo Painting lesson`,
                    },
                  ],
                  application_context: {
                    shipping_preference: "NO_SHIPPING",
                  },
                })
                .then((orderId) => {

                  setTimeout(() => {

                    handleTimeOut()
                  },8*60*1000)
            handleInitiation(orderId)
            
                  return orderId;
              });
            }}
            onApprove={(data, actions) => {
              
                return actions.order.capture().then(() => {
                  clearTimeout(timeoutId.current)
                  const validate=data.payerID
                  handleCapture(validate);
                });
            }}
          />
        </div>}

          </div>
      );
}

export default ButtonWrapper;
