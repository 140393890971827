import React, { useState } from 'react';
import PayPalWrapperLesson from '../components/Gallery/Paymentlesson/PayPalWrapperLesson';
// import Canonical from '../components/Canonical'

const Paymentlesson = ({

  ppalk,
  lessonOrder,
  setLessonOrder,
  setOrderComplete

}) => {
   const[istimeout,setIstimeout]=useState(false)
   const handleRedirectPayment = () =>{
      window.location.replace('/')
  }
   
  return (
   <>
    {/* <Canonical url="https://www.mywebsite.com/ProcessPayment" /> */}
     
     {lessonOrder&&ppalk? (
       <PayPalWrapperLesson
             
       ppalk={ppalk}
               lessonOrder={lessonOrder}
               istimeout={istimeout}
               setIstimeout={setIstimeout}
               setLessonOrder={setLessonOrder}
               setOrderComplete={setOrderComplete}
                />
              ) : (
                <div className='text-center text-light' style={{paddingTop:'150px',minHeight:'100vh'}}>
                <button 
                  
                  onClick={handleRedirectPayment}
                className='mb-3 btn btn-secondary'>
                  Go home
                </button>
                <h1>no lessons booked</h1>
              </div>
              )}
    </>
          );
        }
        
        export default Paymentlesson;
