import React from 'react';
import Canonical from '../components/Canonical'
import image from '../components/imgs/2024-04-04 at 01.46.43_e805f372.jpg';

function About({
  navigatemenuopen
}) {
  return (
    <div>
      <Canonical url="https://www.mylovepainting.com/about" />
      {!navigatemenuopen && (
        <div className='aboutmediv2'>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={image} alt="Descriptive Alt Text" style={{ maxWidth: '100%', maxHeight: '500px', width: 'auto', height: 'auto' }} />
          </div>
          <p className='px-3 pb-2 pt-2 text-light'>
            Located in the peaceful and inspiring city of Port Colborne,
            Canada, I have dedicated many years to my profound passion for painting and teaching art. 
            What started as a personal exploration of the visual arts has grown into a fulfilling career that allows me to share my
            love for capturing the world on canvas and inspiring others to discover their artistic voice.
            Teaching art is an extension of this passion. There's nothing more rewarding 
            than guiding my students through the process of discovery and creation, 
            helping them unlock their potential and find joy in their own artistic pursuits. 
            Whether you're a seasoned artist looking to explore new techniques or 
            a beginner eager to pick up a brush for the first time, I'm here to support 
            your journey with patience and encouragement.

            I invite you to explore my gallery, where each piece reflects my love for the 
            world and the joy of creation. Or, reach out to me for a custom painting or 
            art lessons. 
          </p>
        </div>
      )}
    </div>
  );
}

export default About;
