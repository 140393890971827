import React, { useEffect, useState } from 'react';
import { Alldata } from '../../../utils/api';
import Productlist from './Productlist';

function Allproducts(
{ 
products,
setProductsOrder,
productsOrder  
}) 
{

const [categories, setCategories]=useState([])
const [isLoadingCs, setIsLoadingCs]=useState(true)
const [selArrCategories, setSelArrCategories] = useState([]);
const [errorCfound, setErrorCfound]=useState(false)
const [filteredproducts,setFilteredProducts]=useState([])
const [currentPage, setCurrentPage] = useState(1);

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};


useEffect(() => {
  const fetchCategories = async () => {
    try {
      const response = await Alldata.get('/getcategories');

      if (response && response.data) {
        const newarr = response.data.map((element) => (
          element.categoryname
        ))
        const newArr =[...newarr]
        setSelArrCategories(newArr)
        setCategories([...response.data]);
        setIsLoadingCs(false);
      }
    } catch (error) {
        setErrorCfound(true);
    }
  };
  fetchCategories();
}, []);

useEffect(() => {
  const filterdAll = products.filter((p) => {

  if(selArrCategories&&p.category&&categories){
    if(selArrCategories.includes(p.category.categoryname)){
        return true
    }else{
      return false
    };
  }
  return false
  })

  setFilteredProducts(filterdAll)
}, [selArrCategories,categories,products]);


;

const handleCatOpt = (event) => {
  const value = event.target.value;
  setCurrentPage(1);
  let updatedCategories = [];

  if ((selArrCategories.includes(value))) {
    updatedCategories = selArrCategories.filter((f) => f !== value);
    
  } else {
    updatedCategories = [...selArrCategories, value];
  }
  setSelArrCategories(updatedCategories);

}


if(isLoadingCs===true){
  return<div className='text-light' style={{paddingTop:'150px',minHeight:'100vh'}}>loading...</div>
}

return (
<div className=''style={{minHeight:'100vh'}}>
{filteredproducts&&filteredproducts.length>2&&
       <code className='up' style={{ cursor: 'pointer'}} onClick={scrollToTop}>&#x21ea;</code>}
      <div className="custom111">
  <span className='badge rounded-pill text-bg-dark mb-3' id='Top'>Filter by category:</span>
  <div className='d-flex flex-wrap'>
    {!errorCfound && categories.map((option) => (
      <div className="form-check bg-dark rounded-pill px-4 mx-1 mb-1" key={option.categoryname}>
        <input
          className="form-check-input"
          id={option.categoryname} // Ensures unique ID for each input
          type="checkbox"
          value={option.categoryname}
          checked={selArrCategories.includes(option.categoryname)}
          onChange={handleCatOpt}
        />
        <label className="form-check-label text-light" htmlFor={option.categoryname}>
          {(option.categoryname||'').toUpperCase()}
        </label>
      </div>
    ))}       
  </div>
</div>



  {!errorCfound && filteredproducts &&
    <div className="custom222">      



      <Productlist 
        filteredproducts={filteredproducts}
        setProductsOrder={setProductsOrder} 
        productsOrder={productsOrder}
        selArrCategories={selArrCategories}
       currentPage={currentPage}
       setCurrentPage = {setCurrentPage}
      />
    </div>
  }
</div>
);
}

export default Allproducts;