import React, { useState,useEffect } from 'react';

function Buytickets({
    event,
    eventsOrder,
    setEventsOrder
}) {

    const [count, setCount] = useState(0);

    useEffect(() => {

        if(eventsOrder.length<=0){
setCount(0);
        }
    
    }, [eventsOrder]);

    
    const updateEventsOrder = (newCount) => {
        const existingOrderIndex = eventsOrder.findIndex(order => order.myevent === event._id);
        if (existingOrderIndex > -1) {
            // Update the existing order count
            const updatedOrders = eventsOrder.map((order, index) => {
                if (index === existingOrderIndex) {
                    return { ...order, myevent: event._id, title: event.title, price:event.price, venue:event.venue, datetimeslots:event.datetimeslots, tickets_sold: newCount };
                }
                return order;
            });
            setEventsOrder(updatedOrders);
        } else {
            // Add new order for the event
            setEventsOrder([...eventsOrder, { myevent: event._id, title: event.title, price:event.price, venue:event.venue, datetimeslots:event.datetimeslots, tickets_sold: newCount }]);
        }
    };

    const handleIncrement = () => {
        const newCount = count + 1;

        if(newCount > parseInt(event.numberofPeople) - parseInt(event.tickets_sold)){
            setCount(0);
            updateEventsOrder(0);
        }else{
            setCount(newCount);
            updateEventsOrder(newCount);
        }
    };

    const handleDecrement = () => {
        const newCount = count > 0 ? count - 1 : 0;
        setCount(newCount);
        updateEventsOrder(newCount);
    };


    return (
        <div className="mt-3 d-flex flex-column align-items-center">
            <h6 className="mb-3 text-light">Buy tickets</h6>
            <h6 className="mb-3 text-light">{count}/{parseInt(event.numberofPeople) - parseInt(event.tickets_sold)}</h6>
            <div>
                <button 
                    className="btn btn-danger mx-1"
                    onClick={handleDecrement}
                >
                    -
                </button>
                <button 
                    className="btn btn-success mx-1"
                    onClick={handleIncrement}
                >
                    +
                </button>
            </div>
        </div>
    );
}

export default Buytickets;
