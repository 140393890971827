
import React, { useEffect, useState } from 'react';
import Loginmessage from "../Loginmessage";
import { Api } from '../../../utils/api';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Singleeventpage({
  eventpage
}) 
{


  function convertISOTo12Hour(isoString) {
   
    const to12Hour = (time) => {
      let [hours, minutes] = time.split(':').map(num => parseInt(num, 10)); // Parse hours and minutes
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // Convert 0 to 12 for 12 AM
      minutes = minutes < 10 ? '0' + minutes : minutes.toString();
  
      return `${hours}:${minutes} ${ampm}`;
    };
  
    const Time = isoString.split('T')[1].substring(0, 5);
  
    const formattedTime = to12Hour(Time);
    
    return `${formattedTime}`;
  }

  const settings = {
   
   
    className: "center custom600",
  
    
    dots: true,
    infinite: true,
    speed: 500,
    centerPadding: "60px",
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
   
  }
  
  const [renEvent,setRenEvent] = useState('')
  const [renEvIsLoading,setEvIsLoading] = useState(false)
  const [fetchStatus, setFetchStatus] = useState(true);
  const [errorFetchingEv, setErrorFetching] = useState(false);

  useEffect(() => {
  const fetchSingleEvent = async () => {
    setEvIsLoading(true);
    try {
      const response = await Api.get(`/getsingleeventadmin/${eventpage}`, {
        headers: {
          Authorization: `${localStorage.getItem('id_token')}`,
        },
      });


      if (response && response.data) {
        setRenEvent(response.data);
        setEvIsLoading(false);
      }
    } catch (error) {
      setEvIsLoading(false);
      if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error
        setFetchStatus(false);
       
      } else {
        setErrorFetching(true)
        
      }
    }
  };
  fetchSingleEvent();
}, [eventpage]);

if(!fetchStatus){
  return(
    <Loginmessage/>
  )
}

if(errorFetchingEv){
  return(
    <div  className='custom6 text-center custom4'><h3>no event to show</h3></div>
  )
}

if(renEvIsLoading){
  return <div className='custom6 custom4'>loading...</div>
}

return (
<div className="container mt-3 pb-3 custom6">
<div className="card border-primary text-white bg-dark">
    <div className="card-body">
        <h5 className="card-title text-center">{renEvent.title}</h5>

        <div className="custom700">
        <div className="slider-container">
    
          <Slider {...settings}>
            {renEvent.pics && renEvent.pics.map((element, index) => (
              
                <img style={{maxWidth:'500px'}}key={index} src={element} alt={`Event Pic ${index}`} 
                      />
             
            ))}
          </Slider>
          </div>
        </div>

        <p className="card-text">
            <strong>Description:</strong> {renEvent.description? renEvent.description : ''}
        </p>

        <p className="card-text"><strong>Ticket price:</strong> ${renEvent.price}</p>

        <p className="card-text">
            <strong>Max # of participants:</strong> {renEvent.numberofPeople}
        </p>
        <p className="card-text">
            <strong>Is event posted?:</strong> {renEvent.publish}
        </p>
        <p className="card-text">
            <strong>Venue address:</strong> {renEvent.venue}
        </p>

  
            {renEvent.datetimeslots&&renEvent.datetimeslots.map((element,index) => (
               <div key={index}>
                        <p className="card-text">
            <strong>date:</strong> {element.date.split('T')[0]}
        </p>      


        <p className="card-text">
            <strong>Time:</strong>
        </p>  
        {element.timeslots&&element.timeslots.map((time,timeindex) => (
           <div key={`${timeindex}timeslots`}>
                  <p className="card-text">
            {convertISOTo12Hour(time.startingtime)}-{convertISOTo12Hour(time.endingtime)}
        </p>        
           </div>
         ))
        } 
               </div>
             ))
            }
        
    </div>
</div>

</div>

);
}

export default Singleeventpage;

