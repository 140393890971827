export function Decimalstorequest(number, d) {
   let v;
   let Decimals = parseInt(d, 10);

   if (Decimals === 0) {
       v = number.toString().split('.')[0];
   } else {
       const numberParts = number.toString().split('.');
       const integerPart = numberParts[0];
       let decimalPart = numberParts.length > 1 ? numberParts[1] : '';

       if (decimalPart.length < Decimals) {
           
           decimalPart += '0'.repeat(Decimals - decimalPart.length);
       } else if (decimalPart.length > Decimals) {
           
           decimalPart = decimalPart.substring(0, Decimals);
       } else if (decimalPart.length === 0 && Decimals > 0) {
          
           decimalPart = '0'.repeat(Decimals);
       }

       v = integerPart + (Decimals > 0 ? '.' + decimalPart : '');
   }

   return v 
}










