import React, { useState } from 'react';
import PayPalWrapper from '../components/Gallery/Payment/PayPalWrapper';
// import Canonical from '../components/Canonical'

const Payment = ({

  order, 
  ppalk,
  packagingDUpdts,
  paintDUpdts
}) => {
   const[istimeout,setIstimeout]=useState(false)
   const handleRedirectPayment = () =>{
  
      window.location.replace('/')

  }
   
  return (
   <>
    {/* <Canonical url="https://www.mywebsite.com/ProcessPayment" /> */}
     
     {order? (
       <PayPalWrapper
             
       ppalk={ppalk}
               order={order}
               istimeout={istimeout}
               setIstimeout={setIstimeout}
               packagingDUpdts={packagingDUpdts}
  paintDUpdts={paintDUpdts}
                />
              ) : (
                <div className='text-center text-light' style={{paddingTop:'150px',minHeight:'100vh'}}>
                  <button 
                    
                    onClick={handleRedirectPayment}
                  className='mb-3 btn btn-secondary'>
                    Go home
                  </button>
                  <h1>nothing ordered yet</h1>
                </div>
              )}
            </>
          );
        }
        
        export default Payment;