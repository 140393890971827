import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Api } from '../../../utils/api';
import "react-datepicker/dist/react-datepicker.css";


const PickCreationDate = ({ creationDate, onDateChange }) => {
 
    return (
      <div style={{position:"relative", zIndex:1000}}>
        <h3>Select a date: </h3>
        <DatePicker
         className="form-select"
          selected={creationDate}  
          onChange={onDateChange} 
         minDate={new Date()}
         maxDate={new Date(new Date().getTime() + 28 * 24 * 60 * 60 * 1000)}
          showYearDropdown
          dropdownMode="select"  // select a year from a dropdown
          scrollableYearDropdown // makes the year dropdown scrollable
        />
      </div>
    );
  };

const Eventnoschdates = ({

    setDatesSelArr,
    setIsIncomplete,
isIncomplete
  
}) => {
    const [creationDate, setCreationDate] = useState("");
    const [timeslots, setTimeslots] = useState([]);
    const [conflicts, setConflicts] = useState([]);
    const [selectedStartTime, setSelectedStartTime] = useState('');
    const [selectedEndTime, setSelectedEndTime] = useState('');
    const [mySelection, setMyselection] = useState('');
    

    useEffect(() => {
        async function fetchTimeslots(date) {
            try {
                const response = await Api.post('/eventdatesnosch', { date: date.toISOString() }, {
                    headers: {
                        Authorization: `${localStorage.getItem('id_token')}`,
                    },
                });
                
                
                if (response.status === 200) {
                    setTimeslots(response.data.mydates[0].timeslots);
                    if(response.data.conflicttimes){
                       
                  
                            setConflicts(response.data.conflicttimes);
                        }else{
                            setConflicts([]);
                        }
                    
                } 
            } catch (error) {
                alert('An error occurred. Please try again later.');
            }
        }


        if (creationDate) {
            fetchTimeslots(creationDate);
        }
    }, [creationDate]);


    const handleStartTimeChange = (e) => {
        setSelectedStartTime(e.target.value);
        setSelectedEndTime('');

        
    };

    const handleDatesConfirmationNosch=(e) => {
        let result = true
        const st = parseFloat(mySelection.timeslots[0].startingtimeMill)
        const et = parseFloat(mySelection.timeslots[0].endingtimeMill)
        
        for (let index = 0; index < conflicts.length; index++) {
         const element = conflicts[index];
          const sti = parseFloat(element.startingtimeMill)
          const eti = parseFloat(element.endingtimeMill)
        
          if((st>=sti&&st<eti)||(et<=eti&&et>sti)||(st<sti&&et>eti)){
            result=false
            break
          }
        } 

        if(!result){
            return alert('time selected conflicts with other time slots, please select another time')
        }
        let arraytopush = [];

        arraytopush.push(mySelection);

        if(arraytopush.length>0){
            setDatesSelArr(arraytopush);

        setIsIncomplete(false);

        }
    }

    const handleEndTimeChange = (e) => {
        setSelectedEndTime(e.target.value);
        const timestrstime = timeslots.find(slot => slot.startingtimeMill === selectedStartTime);
        const timestretime = timeslots.find(slot => slot.endingtimeMill === e.target.value);



        const selection = {
            date: creationDate.toLocaleDateString('en-US', {
                weekday: 'short',
                month: 'short',
                day: 'numeric',
                year: 'numeric'
            }),
            dateMill: creationDate.getTime().toString(),
            timeslots: [{
                startingtime: timestrstime.startingtime,
                startingtimeMill: selectedStartTime,
                endingtime: timestretime.endingtime,
                endingtimeMill: e.target.value,
                // Include other necessary details from the endpoint
            }]
        };
                setMyselection(selection);
                
    };

    return (
        <div>
            
            <div className='mb-3'>
            <PickCreationDate popperClassName="custom-popper"  creationDate={creationDate} onDateChange={setCreationDate} />
            </div>
            {timeslots.length > 0 && (
                <>
                    <div>
                        <label className="form-label">Start Time:</label>
                        <select className="form-select-sm mx-2 mb-2" value={selectedStartTime} onChange={handleStartTimeChange}>
                            <option value="">Select Start Time</option>
                            {timeslots.map((slot, index) => (
                                <option key={index} value={slot.startingtimeMill}>{slot.startingtime}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label>End Time:</label>
                        <select className="form-select-sm mx-2 mb-2" value={selectedEndTime} onChange={handleEndTimeChange} disabled={!selectedStartTime}>
                            <option value="">Select End Time</option>
                            {selectedStartTime && timeslots.filter(slot => slot.endingtimeMill > selectedStartTime).map((slot, index) => (
                                <option key={index} value={slot.endingtimeMill}>{slot.endingtime}</option>
                            ))}
                        </select>
                    </div>
                </>
            )}

               
            
                
                    {conflicts&&conflicts.length > 0 && (<div className='text-center'><h4>{conflicts.length} conflicting times found</h4></div>)}
            {conflicts&&conflicts.map((element,index) => (
               <div className='text-center' key={index}>
                     

                         <h5>{element.startingtime}-{element.endingtime}</h5>     
               </div>
             ))
            }
        {mySelection&&<button 
          disabled={
         !isIncomplete
         
            }
          onClick={handleDatesConfirmationNosch} className='mt-3 btn btn-primary'>
            Confirm date/time
          </button>}
        </div>
    );
};

export default Eventnoschdates ;
