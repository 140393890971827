import React from 'react';

function ConfirmationDialog({ isOpen, message, onConfirm, onCancel, isDeleting,handleDelete,isSaving }) {
    if (!isOpen) return null;
  
    return (
      <div className="confirmation-dialog">

        <p>{message}</p>

        {isDeleting===true&&
         <button onClick={handleDelete}>Yes</button>
    }
        {isSaving===true&&
         <button onClick={onConfirm}>Yes</button>
    }
        <button onClick={onCancel}>No</button>
      </div>
    );
  
}

export default ConfirmationDialog;