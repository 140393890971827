export function Decimals(number, d) {

   let v=0
   let Decimals = parseFloat(d)

   const valuestr = number.toString()
   const valuearr = valuestr.split('.')
   const decimals = valuearr[1]
   const firstTwo = decimals.slice(0, Decimals)
   const mystrvalue = valuearr[0]+'.'+firstTwo
   v=mystrvalue

   if(d===0||d==='0'){
    v=valuearr[0]
   }
   
   return v

}





