import React, { useEffect, useState } from 'react';
import { Alldata } from '../utils/api';
import {Api} from "../utils/api";
import {States} from "../utils/States";
import {CaStates} from "../utils/CaStates";
import { useNavigate } from 'react-router-dom';
import Shippingopt from '../components/Gallery/Checkout/Shippingopt'

function Checkout({     
  setSelectFdxSerName,
  setSelectFdxSerFee,
  setPreOrder,
  setPaintingTotal,
  setpackagingTotal,
  setUserFormData,
  setppalk,
  userFormData,
  productsOrder,
  order,
  setpackagingDUpdts,
  setPaintDUpdts,
  selectFdxSerFee,
  paintingTotal,
  packagingTotal
}) {

  const navigate = useNavigate()

  const[errFound, setErrFound]=useState(false)

  const[myPaintings, setMyPaintings]=useState([])


  const [city, setCity] = useState('');
  const [street, setStreet] = useState('');
  const [apartment, setApartment] = useState('');
  const [suite, setSuite] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [selAddress, setSelAddress] = useState('');
  const [selObjAddress, setSelObjAddress] = useState({});
  const [countrycode, setcountrycode] = useState('');
  const[ppalstr, setppalstr]=useState('')

  const [selectedState, setSelectedState] = useState('');
  const [resolvedAddresses, setResolvedAddresses] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchpplk = async () => {
   
     try {
       const response = await Api.get('/ppalclt')
       if (response && response.data) {
          setppalstr(response.data)
          setLoading(false)
       }
     }catch(error){
      setLoading(false)
      setErrFound(true);
      
     }
    };

   fetchpplk();
 }, []);



  useEffect(() => {
    const fetchSelPaints = async () => {

    try {

      if (productsOrder.length <= 0) {
        navigate("/");
      }else{

        let paintsString = productsOrder.join('&&');
        const response = await Alldata.get(`/getselpaintings/${paintsString}`);

        if (response && response.data) {
          setMyPaintings(response.data)
        }
      }
    } catch (error) {
      setErrFound(true)
      
    }
    };

    fetchSelPaints();

  }, [productsOrder, navigate]);

  useEffect(() => {
    const fetchAddress = async () => {

      setSelAddress('')
      setResolvedAddresses('')
      setSelObjAddress('')

      let myapt = ''
      let mysuite = ''

      if(apartment){
        myapt = `APT ${apartment}`
      }

      if(suite&&countrycode==='US'){
        mysuite = `STE ${suite}`
      }else{
        mysuite = `SUITE ${suite}`
      }

      const streetLines = [street,myapt,mysuite].filter(Boolean);
      const Address = {
        streetLines,
        city,
        stateOrProvinceCode: selectedState,
        postalCode,
        countryCode: countrycode
      };
      const Contact = {
        personName: userFormData.name,
        phoneNumber: userFormData.phone
      };
      const arrElement = { address: Address, contact: Contact };
      const Input = { addressesToValidate: [arrElement] };

      try {
        const response = await Api.post("/fedexapi/address", Input);

        if (response && response.data && response.data.output && response.data.output.resolvedAddresses) {

          const filteredAddresses = response.data.output.resolvedAddresses.filter((address) => {
              let dpv = address.attributes.DPV
              let resolved = address.attributes.Resolved
              let intstreetaddress = address.attributes.customerMessages
              let addressestype = address.attributes.AddressType

             
if(intstreetaddress && intstreetaddress.includes('INTERPOLATED.STREET.ADDRESS')){
  return false
}else if(dpv && dpv==='true'){
  return true
}else if(resolved && resolved === 'true'){
  return true
}else if(addressestype && addressestype==='STANDARDIZED'){
  return true
}else{
  return false
}
  
            })

        setResolvedAddresses(filteredAddresses);
         
        }
      } catch (error) {
        console.log('failed to fetch address')
      }
    }; 

    fetchAddress();
    
  }, [userFormData, street, selectedState, postalCode, city, apartment, suite, countrycode]);

  const handleInputChange = (event) => {
    setSelectFdxSerName('')
    setSelectFdxSerFee('')
    setSelAddress('')

    setSelObjAddress('')
    const { name, value } = event.target;
    setUserFormData({ ...userFormData, [name]: value });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if(ppalstr.length>6&&order.delivery!==null){
      setppalk(ppalstr)
      navigate('/Payment')
    }else{
      alert('Some information is missing, please fill out all fields')
    };
  }

  const handleSelAddressOpt = (event) => {
    setSelectFdxSerName('')
    setSelectFdxSerFee('')
    const value = event.target.value;

    if(selAddress !== value){

      setSelAddress(value)
      const objseladd = resolvedAddresses.find((A) => A.postalCode === value);
      setSelObjAddress(objseladd)

    }else{
     
      setSelAddress('')
      setSelObjAddress('')
    }
  }

  if (loading) return <div className='text-light' style={{paddingTop:'150px',minHeight:'100vh'}} >Loading...</div>;
  if (errFound) return <div className='text-light text-center' style={{paddingTop:'150px',minHeight:'100vh'}}>An error has ocurred from our side, please try again later</div>;

  return (
    <div>
<div className="table-responsive">
  <table className="table">
    <thead>
      <tr>
        <th scope="col">Item</th>
        <th scope="col">Painting</th>
        <th scope="col">Title</th>
        <th scope="col">Dimensions (inches)</th>
        <th scope="col">Description</th>
        <th scope="col">Price</th>
        <th scope="col">Commissioned</th>
        <th scope="col">Condition</th>
      </tr>
    </thead>
    <tbody>
  {productsOrder && !errFound && myPaintings.map((element, index) => (
    <tr key={element._id}>
      <td>{index + 1}</td>
      <td><img src={element.pics[0]} alt={`product ${element._id}`} style={{ width: '80px', objectFit: 'cover' }}/></td>
      <td>{element.title}</td>
      <td>{`${element.paintLength}x${element.paintWidth}x${element.paintHeight}`}</td>
      <td>
        {element.description.length > 50
          ? element.description.substring(0, 25) + "..."
          : element.description}
      </td>
      <td>${element.price}</td>
      <td>{element.creationDate.split('T')[0]}</td>
      <td>{element.condition}</td>
    </tr>
  ))}
</tbody>
  </table>
</div>

<div className="container bg-dark text-light py-5">
      {order && !errFound && (
        <div>
          {/* Map through your paintings or orders here */}
        </div>
      )}
      <form>
        <h3>Contact Info</h3>
        <div className="mb-3">
          <label htmlFor="nameInput" className="form-label">Name:</label>
          <input 
            type="text"
            className="form-control"
            id="nameInput"
            placeholder="Enter Full Name"
            name="name"
            required
            onChange={handleInputChange}
            value={userFormData.name}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="emailInput" className="form-label">Email:</label>
          <input 
            type="email" // Changed to email for validation
            className="form-control"
            id="emailInput"
            placeholder="example@myemail.com"
            name="email"
            required
            onChange={handleInputChange}
            value={userFormData.email}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="phoneInput" className="form-label">Phone#:</label>
          <input 
            type="tel" // Changed to tel for validation
            className="form-control"
            id="phoneInput"
            placeholder="Phone Number"
            name="phone"
            required
            onChange={handleInputChange}
            value={userFormData.phone}
          />
        </div>
        <h3>Delivery Address:</h3>
        <div className="mb-3">
          <label htmlFor="cityInput" className="form-label">City:</label>
          <input 
            type="text"
            className="form-control"
            id="cityInput"
            placeholder="City"
            name="city"
            onChange={(e) => setCity(e.target.value)}
            value={city}
          />
        </div>

        
        <div className="mb-3">
          <label htmlFor="country" className="form-label">Select country:</label>
          <select
            className="form-select"
            id="country"
            name="country"
            value={countrycode}
            onChange={(e) => {setcountrycode(e.target.value); setSelectedState('')}}
          >
            <option value="">Select...</option>
           
              <option value='CA'>Canada</option>
              <option value='US'>USA</option>
          
          </select>
        </div>
        {countrycode==='US'&&<div className="mb-3">
          <label htmlFor="stateSelect" className="form-label">Select state:</label>
          <select
            className="form-select"
            id="stateSelect"
            name="State"
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
          >
            <option value="">Select...</option>
            {States.map((s, index) => (
              <option key={index} value={s.c}>{s.s}</option>
            ))}
          </select>
        </div>}
        {countrycode==='CA'&&<div className="mb-3">
          <label htmlFor="stateSelect" className="form-label">Select province:</label>
          <select
            className="form-select"
            id="stateSelect"
            name="State"
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
          >
            <option value="">Select...</option>
            {CaStates.map((s, index) => (
              <option key={index} value={s.c}>{s.s}</option>
            ))}
          </select>
        </div>}
        <div className="mb-3">
          <label htmlFor="streetInput" className="form-label">Street:</label>
          <input 
            type="text"
            className="form-control"
            id="streetInput"
            placeholder="Street"
            name="street"
            onChange={(e) => setStreet(e.target.value)}
            value={street}
          />
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="aptinput">Apartment#: </label>
              <input 
              id='aptinput'
                type='text'
                className="form-control"
                placeholder='Apartment#'
                name='apartment'
                onChange={(e) => {
                setSuite('')
                setApartment(e.target.value);
                }}
                value={apartment}
              /> 
           
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="suiteinput">Suite#: </label>
              <input 
              id='suiteinput'
              type='text'
              placeholder='suite#'
              className="form-control"
              name='suite'
              onChange={(e) => {
              setApartment('')
              setSuite(e.target.value);
              }}
              value={suite}
              /> 
           
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="postcodeinput">Postal code:</label>
            <input 
            id='postcodeinput'
            type='text'
            className="form-control"
            placeholder='Postal code'
            name='postalCode'
            onChange={(e) => {
            setPostalCode(e.target.value);
            }}
            value={postalCode}
            /> 
          </div>
        {/* Matching addresses section */}
        <div className='mb-1'>Please select a matching address:</div>
        {resolvedAddresses.length > 0 && resolvedAddresses.map((option, index) => (
          <div className="form-check" key={index}>
            <input
              className="form-check-input"
              type="checkbox"
              id={`addressOption-${index}`}
              value={option.postalCode}
              checked={selAddress === option.postalCode}
              onChange={handleSelAddressOpt}
            />
            <label className="form-check-label" htmlFor={`addressOption-${index}`}>
              {`${option.streetLinesToken.join(' ')}, ${option.city}, ${option.stateOrProvinceCode} ${option.postalCode}, ${option.countryCode}`}
            </label>
          </div>
        ))}
        {resolvedAddresses.length <= 0 && (
          <div className="alert alert-warning" role="alert">
            No matching address found.
          </div>
        )}
            
                {selObjAddress&&
          <Shippingopt 
            setSelectFdxSerFee={setSelectFdxSerFee} 
            setSelectFdxSerName={setSelectFdxSerName}
            setPreOrder={setPreOrder}
            setPaintingTotal={setPaintingTotal}
            setpackagingTotal={setpackagingTotal} 
            selObjAddress={selObjAddress} 
            selAddress={selAddress} 
            productsOrder={productsOrder}
            setpackagingDUpdts={setpackagingDUpdts}
            setPaintDUpdts={setPaintDUpdts}
            order={order}
            selectFdxSerFee={selectFdxSerFee}
            paintingTotal={paintingTotal}
            packagingTotal={packagingTotal}
          />
        }
        <button disabled={selectFdxSerFee===''||selAddress===''} type="button" className="btn btn-primary mt-3" onClick={handleFormSubmit}>
          Proceed Payment
        </button>
      </form>
    </div>
    </div>
  );
}

export default Checkout;
