import React, { useEffect, useState } from 'react';

function Spot({spotstart,
    spotend,
  spotIndex,
  availability,
  setAvailability}) {


const [Shoursarr, setShoursarr] = useState([]);
const [Ehoursarr, setEhoursarr] = useState([]); 

useEffect(() => {
   
let Sthoursarr = [];
let Enhoursarr = [];
    for (let hour = 6; hour < 12; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
          const time = `${hour}:${minute === 0 ? '00' : minute}AM`;
          Sthoursarr.push(time);
        }
      }
      for (let hour1 = 12; hour1 <24; hour1++) {
        for (let minute1 = 0; minute1 < 60; minute1 += 30) {
          if(hour1 === 12){
            const time121 = `${hour1}:${minute1 === 0 ? '00' : minute1}PM`;
            Sthoursarr.push(time121);
          }else{
            const time1 = `${hour1-12}:${minute1 === 0 ? '00' : minute1}PM`;
            Sthoursarr.push(time1);
          }
          
        }
      }
      for (let hour2 = 6; hour2 < 12; hour2++) {
        for (let minute2 = 0; minute2 < 60; minute2 += 30) {
          const time2 = `${hour2}:${minute2 === 0 ? '00' : minute2}AM`;
          Enhoursarr.push(time2);
        }
      }
      for (let hour3 = 12; hour3 <24; hour3++) {
        for (let minute3 = 0; minute3 < 60; minute3 += 30) {
          if(hour3 === 12){
            const time3 = `${hour3}:${minute3 === 0 ? '00' : minute3}PM`;
            Enhoursarr.push(time3);
          }else{
            const time31 = `${hour3-12}:${minute3 === 0 ? '00' : minute3}PM`;
            Enhoursarr.push(time31);
          }
          
        }
      }

      if(Sthoursarr.length !== 0&&Enhoursarr.length !== 0){
        setShoursarr(Sthoursarr);
        setEhoursarr(Enhoursarr);
      }

}, []);
  

    const convertToMinutes = (timeString) => {
        const [hour, minutePart] = timeString.split(':');
        const [minute, period] = minutePart.split(/(?=[AP]M)/); // Extract minute and period (am/pm)
        let totalMinutes = parseInt(hour, 10) * 60 + parseInt(minute, 10);
        if (period === 'PM' && parseInt(hour, 10) !== 12) {
            totalMinutes += 12 * 60;
        }
        return totalMinutes;
    };


    const timeavailablesh = (hour) => {
let result=true
   const start = availability.spots.filter((spot) =>{
    
    if(spot.starthour&&spot.starthour!==''&& spot.endhour&& spot.endhour!==''){
      return true
    }else{
      return false
    }
  
  })
   const starth = availability.spots.filter((spot) =>{

    if(spot.starthour&&spot.starthour!==''&& spot.endhour&& spot.endhour===''){
      return true
    }else{
      return false
    }
    
  })

   if(start.length>0){
    for(let i=0;i<start.length;i++){
      const j = convertToMinutes(start[i].starthour)
      const k = convertToMinutes(start[i].endhour)
      const hh = convertToMinutes(hour)
      
      
      if(hh>=j&&hh<k){
          result = false
          break
      
      
      }
      }
   }
   if(starth.length>0){
    for(let ii=0;ii<starth.length;ii++){
      const jj = convertToMinutes(start[ii].starthour)
      const hhh = convertToMinutes(hour)
      
      
      if(hhh===jj){
          result = false
          break
      
      
      }
      }
   }


return result
    }
    const timeavailableeh = (hour) => {
let result2=true
const start = availability.spots.filter((spot) =>{
    
  if(spot.starthour&&spot.starthour!==''&& spot.endhour&& spot.endhour!==''){
    return true
  }else{
    return false
  }

})


   if(start.length>0){
    for(let iee=0;iee<start.length;iee++){
      const jee = convertToMinutes(start[iee].starthour)
      const kee= convertToMinutes(start[iee].endhour)
      const hhee = convertToMinutes(hour)
      
      
      if(hhee>jee&&hhee<=kee){
          result2 = false
          break
      
      
      }
      }
   }
return result2
    }
            
    
    const timeavailable = (hour) => {

let result33=true

        if(spotstart&&spotstart!==''){
            const shourmin = convertToMinutes(hour)      
            const hourmin = convertToMinutes(spotstart);

            if((shourmin-hourmin)<120){
              return false
            }
            if((shourmin-hourmin)%120!==0){
              return false
            }
           if(hourmin < shourmin){


            const start = availability.spots.filter((spot) =>{
    
              if(spot.starthour&&spot.starthour!==''&& spot.endhour&& spot.endhour!==''){
                return true
              }else{
                return false
              }
            
            })

            if(start.length>0){
              for(let iee=0;iee<start.length;iee++){
                const jee = convertToMinutes(start[iee].starthour)
                const kee= convertToMinutes(start[iee].endhour)
           
                
                
                if(hourmin<jee&&kee<shourmin){
                    result33 = false
                    break
                
                
                }
                }
             }


           }else{
            result33= false
           };
        }else{
            result33 = false
        }
      

return result33
    }
    
   // Only keep hours after start hour
        

   
// Only re-run the effect when spotstart changes
 


  
  const handleEndHourChange = (spotIndex, value) => {


    const updatedSpots = [...availability.spots];
    updatedSpots[spotIndex] = {
      ...updatedSpots[spotIndex],
      endhour: value,
    };

  
    setAvailability({ ...availability, spots: updatedSpots });
  };

  // Function to handle input change for adding spots
  const handleSpotChange = (spotIndex, value) => {

   
    const updatedSpots = [...availability.spots];
    updatedSpots[spotIndex] = {
      endhour: '',
      starthour: value,
    };
    setAvailability({ ...availability, spots: updatedSpots });
  };

  return (
    <div>
    <div  className='d-flex-column'>
      {spotend===''&&<select
        className="form-select-sm mx-2 mb-2" // Added mb-2 for bottom margin
        value={spotstart}
        onChange={(e) => handleSpotChange(spotIndex, e.target.value)}
      >
        <option value=''>Start Hour</option>
        {Shoursarr && Shoursarr.filter(timeavailablesh).map((hour, index) => (
          <option key={index} value={hour}>{hour}</option>
        ))}
      </select>}
      {spotend===''&&<select
        className="form-select-sm mx-2 mb-2" // Added mb-2 for bottom margin
        value={spotend}
        onChange={(e) => handleEndHourChange(spotIndex, e.target.value)}
      >
        <option value=''>End Hour</option>
        {Ehoursarr && spotstart !== '' && Ehoursarr.filter(timeavailableeh).filter(timeavailable).map((hour, index) => (
          <option key={index} value={hour}>{hour}</option>
        ))}
      </select>}
      
      
    </div>
    {spotstart&&spotend&&<h4 className="mr-2">{spotstart} to {spotend}</h4>}
    <div>
    </div>
  </div>
  
  );
  
}

export default Spot;