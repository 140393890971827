import React, { useState } from 'react';
import PayPalWrapperEvent from '../components/Gallery/Paymentevents/PayPalWrapperEvent';
// import Canonical from '../components/Canonical'

function trimStringForPayPal(description) {
  const maxLength = 100;
  return description.length > maxLength ? description.substring(0, maxLength) : description;
}


function formatTime(time) {
  
  const hourstring = time.split('T')[1].split(':')[0]
  const minutestring = time.split('T')[1].split(':')[1]


  if (parseInt(hourstring) >12) {

    return `${parseInt(hourstring) - 12}:${minutestring}pm`
  } else if(parseInt(hourstring)===12) {
    return `${hourstring}:${minutestring}pm`
  }else{
    return `${hourstring}:${minutestring}am`
  
  }
}
const Paymentevent = ({

  myEventOder,
  ppalk,
  setMyEventOrder,
  setEventOrderComplete,
  setppalk

}) => {
   const[istimeout,setIstimeout]=useState(false)

   const [userFormDatalesson, setUserFormDatalesson] = useState({name:'', email:'',phone:''});
   const [goToPayment, setGoToPayment] = useState(false);
   const [myEventOrderToPay, setMyEventOrderToPay] = useState('');
   const [description, setDescription] = useState('');
   const [noTicketsAval, setNoTicketsAval] = useState('');
   const [timeoutTriger, setTimeOutTrigger] = useState('');
   const [internalError, setInternalError] = useState('');
   
   const handleRedirectPayment = () =>{
    setMyEventOrder('')
    setEventOrderComplete(false)
      window.location.replace('/')
  }
  const handleInputChange = (event) => {
    
    const { name, value } = event.target;
    setUserFormDatalesson({ ...userFormDatalesson, [name]: value });
  };

  const handleFormSubmit = () => {

    const{total, myeventstopass} = myEventOder

    const neweventstopass=[]
const descriparr = []
    for(const myorderstr of myeventstopass){

const {tickets_sold, title, price, datetimeslots, myevent} = myorderstr
const descripstr = `${tickets_sold} x ${title}`



descriparr.push(descripstr)


const myorderobj={tickets_sold:tickets_sold, title:title, 
  price:price, myevent:myevent, datetimeslots:datetimeslots, refcode:new Date().getTime()}

neweventstopass.push(myorderobj)

    }

    const myNewEventOder = {total:total, myeventstopass:neweventstopass}

    const descriptioneventstr = `Y Camargo event tickets:${descriparr.join(' , ')}`
    const descriptrimed = trimStringForPayPal(descriptioneventstr)

    setDescription(descriptrimed)
   
  const myorderobj = {...myNewEventOder,...userFormDatalesson} 

  setMyEventOrderToPay(myorderobj)

  if(myorderobj){
    setGoToPayment(true)
  }


    }

  return (
   <div className='custom4'>
    {/* <Canonical url="https://www.mywebsite.com/ProcessPayment" /> */}
    {!goToPayment&&myEventOder&&ppalk&&
    <div>
      <div className='mb-2 text-center'>
      <h2 className=' text-light'>Checkout</h2>
      </div>

      <div className="table-responsive">
  <table className="table w-100 table-sm">
    <tbody>
      {myEventOder && myEventOder.myeventstopass.map((event, index) => (
        <React.Fragment key={`event-${index}`}>
          {/* Event and Date Header */}
          <tr className="table-dark text-center">
            <th colSpan='2'>Event</th>
          </tr>
          <tr className="text-center">
            <td colSpan='2' className="single-line">{event.title}</td>
          </tr>
          <tr>
            <th colSpan='2'>Venue address</th>
          </tr>
          <tr>
            <td colSpan='2'className="single-line">
              {event.venue}
            </td>
          </tr>
          <tr>
            <th >Date</th>
            <th >Time</th>
          </tr>
          <tr>
            <td className="single-line">
              {event.datetimeslots.map((dateSlot, dateIndex) => (
                <code key={`date-${dateIndex}`}>
                  {dateSlot.date.split('T')[0]}
                </code>
              ))}
            </td>
            <td className="single-line">
              {event.datetimeslots.flatMap((dateSlot, dateIndex) => 
                dateSlot.timeslots.map((timeSlot, timeIndex) => (
                  <span key={`time-${dateIndex}-${timeIndex}`}>
                    {timeIndex === 0 ? formatTime(timeSlot.startingtime) : ''}-
                    {timeIndex === (dateSlot.timeslots.length - 1) ? formatTime(timeSlot.endingtime) : ''}
                  </span>
                ))
              )}
            </td>
          </tr>
          <tr>
            <th >Tickets Qty</th>
            <th >$Price/Ticket</th>
          </tr>
          <tr>
            <td className="single-line">{event.tickets_sold}</td>
            <td className="single-line">{event.price}</td>
          </tr>
        </React.Fragment>
      ))}
    </tbody>
  </table>
</div>

      
<div className=" container bg-dark text-light py-3">

              
              <h3>Contact Info</h3>
              <div className="mb-3">
                <label htmlFor="nameInput" className="form-label">Name:</label>
                <input 
                  type="text"
                  className="form-control"
                  id="nameInput"
                  placeholder="Enter Full Name"
                  name="name"
                  required
                  onChange={handleInputChange}
                  value={userFormDatalesson.name}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phoneInput" className="form-label">Phone#:</label>
                <input 
                  type="tel" // Changed to tel for validation
                  className="form-control"
                  id="phoneInput"
                  placeholder="Phone Number"
                  name="phone"
                  required
                  onChange={handleInputChange}
                  value={userFormDatalesson.phone}
                />
                </div>
              <div className="mb-3">
                <label htmlFor="emailInput" className="form-label">Email:</label>
                <input 
                  type="email" // Changed to tel for validation
                  className="form-control"
                  id="emailInput"
                  placeholder="myemail@email.com"
                  name="email"
                  required
                  onChange={handleInputChange}
                  value={userFormDatalesson.email}
                />
                </div>
              <div className="mb-3 text-center">
<h4>Total order: ${myEventOder.total} CAD</h4>
                </div>
              <button disabled={
              userFormDatalesson.name===''
              ||userFormDatalesson.email===''
              ||userFormDatalesson.phone===''
                    } 
              type="button" className="btn btn-primary mt-3" onClick={handleFormSubmit}>
                Proceed Payment
              </button>
              <div className='mt-3'>
              <button 
                  
                  onClick={handleRedirectPayment}
                className='mb-3 btn btn-secondary'>
                  Cancel order
                </button>
              </div>
 
              </div>

    </div>
    }
     
     {myEventOrderToPay&&ppalk&&goToPayment&&myEventOder&& 
       description&&<PayPalWrapperEvent
             
                    ppalk={ppalk}
                    setMyEventOrderToPay={setMyEventOrderToPay}
               istimeout={istimeout}
               myEventOrderToPay={myEventOrderToPay}
               setIstimeout={setIstimeout}
               setMyEventOrder={setMyEventOrder}
               setEventOrderComplete={setEventOrderComplete}
               description={description}
                setNoTicketsAval={setNoTicketsAval}
                setTimeOutTrigger={setTimeOutTrigger}
                setInternalError={setInternalError}
                setppalk={setppalk}
            

                />}


         {myEventOrderToPay===''&&myEventOder===''&&ppalk===''&&<div className='text-center text-light' style={{paddingTop:'150px',minHeight:'100vh'}}>
                <button 
                  
                  onClick={handleRedirectPayment}
                className='mb-3 btn btn-secondary'>
                  Go home
                </button>
                {noTicketsAval!==''&&<h1>{noTicketsAval}</h1>}
                {timeoutTriger!==''&&<h1>{timeoutTriger}</h1>}
                {internalError!==''&&<h1>{internalError}</h1>}
                {noTicketsAval===''&&timeoutTriger===''&&internalError===''&&<h1>no events found in your cart</h1>}
             
              </div>}
  
            </div>
          );
        }
        
        export default Paymentevent;


      //   {
      //     "myeventstopass": [
      //         {
      //             "myevent": "6623d33654a29d3b9311063a",
      //             "title": "mi fiesta colombiana",
      //             "price": "15.00",
      //             "datetimeslots": [
      //                 {
      //                     "date": "2024-04-30T00:00:00.000Z",
      //                     "timeslots": [
      //                         {
      //                             "startingtime": "2024-04-30T10:00:00.000Z",
      //                             "endingtime": "2024-04-30T12:00:00.000Z",
      //                             "_id": "6623d33654a29d3b9311063c"
      //                         },
      //                         {
      //                             "startingtime": "2024-04-30T08:00:00.000Z",
      //                             "endingtime": "2024-04-30T10:00:00.000Z",
      //                             "_id": "6623d33654a29d3b9311063d"
      //                         },
      //                         {
      //                             "startingtime": "2024-04-30T12:00:00.000Z",
      //                             "endingtime": "2024-04-30T14:00:00.000Z",
      //                             "_id": "6623d33654a29d3b9311063e"
      //                         }
      //                     ],
      //                     "_id": "6623d33654a29d3b9311063b"
      //                 }
      //             ],
      //             "tickets_sold": 2
      //         }
      //     ],
      //     "total": 30
      // }



      
