import React from 'react';
import {useNavigate } from 'react-router-dom';
import imageInst from '../components/imgs/Instagram_Glyph_White.png';

    function Footer({setNavigatemenuopen}) {

      const navigate = useNavigate();
      return (
        <footer className='pt-5' style={{position:'relative', backgroundColor: "#000", color: "#fff" }}>
          <div className="container" style={{ backgroundColor: "transparent" }}>
            <div className="row">
              {/* Contact Information */}
              <div className="col-sm-5 pb-3">
                <h5 className="text-uppercase">Contact:</h5>
                <p>For inquiries and commissions, feel free to reach out.</p>
                <p>🎨 Email: </p>
                <p style={{wordBreak:'break-all' }}>lovepainting.yenis@gmail.com </p>
              </div>
              <div className="col-sm-5 pb-3">
                <h5 className="text-uppercase">Follow me:</h5>
                <a href="https://www.instagram.com/camargoquitian?igsh=NjVwd282ZzlmcDVm" target="_blank" rel="noopener noreferrer"><img style={{width:"30px"}} src={imageInst} alt="logo Instagram"></img></a>
              </div>
              {/* Web Developer Credit */}
              <div className="col-sm-2 pb-3">
                <h5 className="text-uppercase">Website by</h5>
                <a href="https://www.javiermp.com/" target="_blank" rel="noopener noreferrer" style={{ color: "#fff" }}>
                  JMP
                </a>
              </div>
              <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
        <button className='mt-1 mb-1 btn btn-outline-info'       onClick={(event) => {
              event.preventDefault();
              setNavigatemenuopen(false);
              navigate('/admin');
            }}>Access</button>
              </div>
            </div>
          </div>
        </footer>
      );
    }
    
 
    
  

export default Footer;

