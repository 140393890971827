import React from 'react';
// import Slider from 'react-slick';
import Singleproduct from './Singleproduct'

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Productlist(
{ 
filteredproducts, 
refetchsingle,
setRefetch,
activeProductIndex,
setActiveProductIndex,
activeSingleProductIndex,
setActiveSingleProductIndex,
allCategories,
setRefetchCat,

        setPIsLoading
}) 
{

  const handleProductClick = (id) => {
    if (activeProductIndex === id) {
    setActiveProductIndex(null);
    setActiveSingleProductIndex(null);
  } else {
    setActiveProductIndex(id);
    setActiveSingleProductIndex(id);
  }
  };


  return (
   
<div className="container mt-3 pb-2">
  <div className="row row-cols-1 g-4 justify-content-center">
    {filteredproducts && filteredproducts.map((element, index) => (
      <div key={index} className="col d-flex justify-content-center">
        <div className="card h-100" style={{ maxWidth: '600px', width: '100%' }}>
          {activeProductIndex !== element._id &&<img src={element.pics && element.pics[0]} className="card-img-top" alt={`product ${index}`} onClick={() => handleProductClick(element._id)} style={{ cursor: "pointer", width: '100%', height: 'auto' }}/>}
          <div className="card-body px-1">
            {activeProductIndex !== element._id &&<h5 className="card-title" onClick={() => handleProductClick(element._id)} style={{ cursor: "pointer" }}>{element.title}</h5>}
            {activeProductIndex === element._id &&<div className="card-title text-center"><button className="btn btn-primary" onClick={() => handleProductClick(element._id)}>GO BACK</button></div>}
            {activeProductIndex === element._id && (
              <Singleproduct 
                setRefetchCat={setRefetchCat} 
                id={activeSingleProductIndex} 
                allCategories={allCategories} 
                setActiveProductIndex={setActiveProductIndex} 
                refetchsingle={refetchsingle} 
                setRefetch={setRefetch}
                
        setPIsLoading={setPIsLoading}
              />
            )}
          </div>
        </div>
      </div>
    ))}
  </div>
</div>


  );
}

export default Productlist;

