import React from 'react';
import DatesTimes from './DatesTimes';

function Datesdiv({
    dates,
    datesSelArr,
    setDatesSelArr,
    setDates
}) {
  
const removeSpot = (index) => {
  const updatedSpots = [...datesSelArr];
  updatedSpots.splice(index, 1);
  if(datesSelArr.length===1){
  setDatesSelArr([]);
}else{
  setDatesSelArr(updatedSpots);
}
}

const addSpot = () => {

  const updatedSpots = [
    ...datesSelArr,
    { date: '', id:'', time:'', timeslots:[] },
  ];
  setDatesSelArr(updatedSpots);
};

    return (
    <div>
      <h4>Class time slot(s)</h4>
      {dates&&datesSelArr&&datesSelArr.map((element,index) => (
         <div key={index} className='mb-2 border p-1 rounded'>
                <h4>{element.date}</h4>
               <DatesTimes setDates={setDates} dates={dates} element={element} datesSelArr={datesSelArr} setDatesSelArr={setDatesSelArr} index={index}/>  
               <button className="btn btn-danger" onClick={() => removeSpot(index)}>Remove</button>     
         </div>
         
       ))
      }
      <button disabled={datesSelArr.length===dates.length}className="btn btn-primary" onClick={addSpot}>Add Slot</button>
    </div>
  );
}

export default Datesdiv;
