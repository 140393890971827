import React from 'react';
import {PayPalButtons} from "@paypal/react-paypal-js";
import {  useRef } from "react";
import {Api} from "../../../utils/api";

const ButtonWrapper = ({
  currency,
  setIstimeout,
  istimeout,
  setMyEventOrderToPay,
  myEventOrderToPay,
  setMyEventOrder,
  setEventOrderComplete ,
  description,
  setNoTicketsAval,
  setTimeOutTrigger,
  setInternalError,
  setppalk  
 }) => {

  const timeoutId = useRef(null)
  const orderobj = useRef(myEventOrderToPay)
  const preorder = useRef('');
 

  const handleCancel= async () => {
    return window.location.replace('/');
    }

    const handleTimeOut = async() => {
      orderobj.current=''
      setMyEventOrderToPay('')
      setMyEventOrder('')
      setppalk('')
     setIstimeout(true)
     setTimeOutTrigger('Session expired after 8 minutes.')
      }

    const handleInitiation=async (b) => { 
try {
 const myorder = orderobj.current
  const response = await Api.post('/eventticketsaval',myorder);
              
  if (response && response.status===200) {


  

const totalnumevents = response.data.eventspaid
const totalinvoinces = response.data.invoices.length



if(((totalnumevents*1)-(totalinvoinces*1))===0){

    const preOrder = { ...response.data, refnum1: b };
    preorder.current = preOrder
   
  }else{
    orderobj.current=''
    setMyEventOrderToPay('')
    setMyEventOrder('')
    setppalk('')
   setIstimeout(true)
    setNoTicketsAval('No tickets available for one or more selected event(s)')
    alert('No tickets available for one or more selected event(s)')
  }

}else{
  orderobj.current=''
  setMyEventOrderToPay('')
  setMyEventOrder('')
  setppalk('')
 setIstimeout(true)
  alert('An error ocurred and your order could not be processed')
  setInternalError('An error ocurred and your order could not be processed')
}

} catch (error) {
  orderobj.current=''
  setMyEventOrderToPay('')
  setMyEventOrder('')
  setppalk('')
 setIstimeout(true)
  alert('An error ocurred and your order could not be processed')
  setInternalError('An error ocurred and your order could not be processed')
}
      }

      const handleCapture = async (a) => {   
       
        const order = { ...preorder.current, refnum2: a };
            if(a===''||a===undefined||a===null){
              orderobj.current=''
              setMyEventOrderToPay('')
              setMyEventOrder('')
              setppalk('')
             setIstimeout(true)
alert('An error ocurred and your order could not be processed.Please claim a refund if any transaction has been made. ')
setInternalError('An error ocurred and your order could not be processed.Please claim a refund if any transaction has been made. ')
            }else{
              try {
     
                    const response = await Api.post('createeventorder',order);
              
                    if (response && response.data) {
                      
                      const orderUrl = response.data.order;
                      // Redirect the user to the Google Cloud URL
                      window.location.href = orderUrl;
                     
                      setEventOrderComplete (true)
                      setMyEventOrderToPay('')
                      setMyEventOrder('')
                    }else{
                      orderobj.current=''
                      setMyEventOrderToPay('')
                      setMyEventOrder('')
                      setppalk('')
                     setIstimeout(true)
                      alert('An error ocurred and your order could not be processed.Please claim a refund if any transaction has been made. ')
                      setInternalError('An error ocurred and your order could not be processed.Please claim a refund if any transaction has been made. ')
                    }
                  } catch (error) {
                    orderobj.current=''
                    setMyEventOrderToPay('')
                    setMyEventOrder('')
                    setppalk('')
                   setIstimeout(true)
                    setInternalError('An error ocurred and your order could not be processed.Please claim a refund if any transaction has been made. ')
                    alert('An error ocurred and your order could not be processed.Please claim a refund if any transaction has been made. ')
                  }

                }
                };
   
   return(

          <div style={{minHeight:'90vh', position:'relative'}}>
                <div className=' text-center mt-1 text-light px-1'>
<h5>After your purchase is approved, a new tab will open displaying your order details in a printable PDF format. Additionally, a PDF file containing your order details will be sent to your email.</h5>
</div>
            <div className='text-center mt-3'>
            <button className="btn btn-secondary mb-3" style={{ cursor: 'pointer' }} onClick={handleCancel}>Cancel Order</button>
            </div>
        
          
                    
        {myEventOrderToPay&&istimeout===false&&description&&<div className='ppaldiv'>
        <PayPalButtons
 
            forceReRender={[orderobj, istimeout, currency]}
            createOrder={(data, actions) => {
              return actions.order
                .create({
                  purchase_units: [
                    {
                      amount: {
                        currency_code: currency,
                        value: myEventOrderToPay.total,
                      },
                      description: description,
                    },
                  ],
                  application_context: {
                    shipping_preference: "NO_SHIPPING",
                  },
                })
                .then((orderId) => {

                  setTimeout(() => {

                    handleTimeOut()
                  },8*60*1000)
            handleInitiation(orderId)
            
                  return orderId;
              });
            }}
            onApprove={(data, actions) => {
              
                return actions.order.capture().then(() => {
                  clearTimeout(timeoutId.current)
                  const validate=data.payerID
                  handleCapture(validate);
                });
            }}
          />
        </div>}

          </div>
      );
}

export default ButtonWrapper;