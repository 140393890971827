import React from 'react';

function Orderconfirmation() {
  const handleRedirectPayment = () =>{
  
    window.location.replace('/')

}
  return (
    <div>
                      <div className='text-center' style={{paddingTop:'90px',minHeight:'100vh'}}>
                  <button 
                    
                    onClick={handleRedirectPayment}
                  className='btn btn-primary'>
                    Go home
                  </button>
                  <h1 className='text-light pt-3'>Order was received, please check your email. Thank you</h1>
                </div>
      
    </div>
  );
}

export default Orderconfirmation;