import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import ReadMore from './ReadMore';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Productlist(
{ 
filteredproducts,
setProductsOrder,
productsOrder,
selArrCategories,
currentPage,
       setCurrentPage
}) 
{
  const navigate = useNavigate();
  
  const [currentItems, setCurrentItems] = useState([]);
const [allPages,setAllPages]=useState([])

useEffect(() => {
  const totalproduct = filteredproducts.length;
  let arr = [];

  if (totalproduct <= 0) {
    setAllPages([]);
  } else {
    const totalpages = Math.ceil(totalproduct / 6);
    let ipos = 0;
    let fpos = 5;

    for (let index = 1; index <= totalpages; index++) {
      if (index !== 1) {
        ipos = fpos + 1; 
      }
      if (index === totalpages) { 
        fpos = totalproduct - 1; 
      } else {
        fpos = ipos + 5; 
      }

      arr.push({ page: index, positioni: ipos, positionf: fpos });
    }
  }

  setAllPages(arr);
}, [filteredproducts, selArrCategories]);

useEffect(() => {
  // Fetch current page details from pagesarray
  const pageInfo = allPages.find(page => page.page === currentPage);

  // Update the currentItems based on the positions in pageInfo
  if (pageInfo) {
    setCurrentItems(filteredproducts.slice(pageInfo.positioni, pageInfo.positionf + 1));
  }
}, [currentPage, allPages, filteredproducts,selArrCategories]);



    const handleAddPainting = (element) => {
           

            if(!productsOrder.includes(element)){
              let newSelPaintings = [...productsOrder,element]
              setProductsOrder(newSelPaintings)

            }
            };

            const handleNextPage = () => {
              setCurrentPage(prev => (prev < allPages.length ? prev + 1 : prev));
            };
            
            // Handle previous page click
            const handlePrevPage = () => {
              setCurrentPage(prev => (prev > 1 ? prev - 1 : prev));
            };
    

  return (
    // <div >
    <div className='cartcontainer'>


      
      {productsOrder.length>0&&<div className='cartbuttons'>
<button type="button"  className="btn btn-primary position-relative d-block p-1 cartbtnselement" onClick={() => {
        
        navigate('/Checkout');
       }}>
  Checkout 🛒
  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger ">
  {productsOrder.length}
  </span>
</button>
<button type="button" className="btn btn-primary position-relative d-block mt-1 p-1 cartbtnselement" onClick={() =>setProductsOrder('')}>
Empty Cart
</button>
</div>}
<div className="text-center my-3">
    <button 
        onClick={() => setCurrentPage(1)} 
        disabled={currentPage === 1}
        className="btn mx-2 text-light"
    >
        <i className="fas fa-angle-double-left text-light"></i> First
    </button>
    <button 
        onClick={handlePrevPage} 
        disabled={currentPage === 1}
        className="btn mx-2 text-light"
    >
        <i className="fas fa-angle-left text-light"></i> Prev
    </button>
    <button 
        onClick={handleNextPage} 
        disabled={currentPage === allPages.length}
        className="btn mx-2 text-light"
    >
        Next <i className="fas fa-angle-right text-light"></i>
    </button>
    <button 
        onClick={() => setCurrentPage(allPages.length)} 
        disabled={currentPage === allPages.length}
        className="btn mx-2 text-light"
    >
        Last <i className="fas fa-angle-double-right text-light"></i>
    </button>
</div>
 <div className="d-flex flex-wrap justify-content-center">
 {filteredproducts.length>0&&currentItems.length>0&&allPages.length>0&& currentItems.map((element, index) => (
  <div className="col" key={index}> {/* Adjusted for full width on small screens and auto on md and up */}
    <div className="card mx-auto my-3 no-border carddivcontainer"> {/* Adjusted for centering, width control, and no borders */}
      <a href={element.pics[0]} target="_blank" rel="noreferrer">
        <img src={element.pics[0]} className="card-img-top no-border" alt={`product ${index}`} style={{width: '100%'}}/>
      </a>
      <div className="card-body d-flex flex-column">
        <h5 className="card-title text-light">{element.title}</h5>
        <p className="card-text text-light">Dimensions(inches): {element.paintLength}x{element.paintWidth}x{element.paintHeight}</p>
        <p className="card-text text-light">Price: ${element.price} CAD</p>
        <p className="card-text text-light">
   <ReadMore text={element.description} maxLength={50} />
  </p>
        
        <div className="mt-auto">
          <button onClick={() => handleAddPainting(element.upn)} className="btn btn-dark border border-primary w-100">Add to Cart</button>
        </div>
      </div>
    </div>
  </div>
))}

</div>


  </div>
  );
}

export default Productlist;

