import React, { useEffect, useState } from 'react';
import { Alldata } from '../utils/api';
import Allproducts from '../components/Gallery/Allproducts/Allproducts';
import Canonical from '../components/Canonical'


function Home({setProductsOrder, 
  navigatemenuopen,
  productsOrder}) {


  const [products, setProducts]=useState([])
  const [isPLoading, setPIsLoading] = useState(true);
  const [errorPfound, setErrorPfound]=useState(false)


  useEffect(() => {

    const fetchProducts = async () => {

      try {
        const response = await Alldata.get('/getpaintings');

        if (response && response.data) {
          setProducts(response.data);
          setPIsLoading(false)
        }

      } catch (error) {
        setPIsLoading(false);
                 setErrorPfound(true);
         
        
      }
    };

    fetchProducts();
  }, []);


  if(isPLoading){
    return(
      <div>
{!navigatemenuopen&&<div className='text-light' style={{paddingTop:'150px',minHeight:'100vh'}}>Loading...
     </div>}
     </div>
     )
  }

  if (errorPfound) {
    return (
      <div>
{!navigatemenuopen&&<div className='classminheight text-center text-light' style={{paddingTop:'200px'}}>
        An error has occurred on our side. Please try again later
      </div>}
      </div>
    );
  }

  if (products && products.length === 0) {
    return (
      <div>
{!navigatemenuopen&&<div className='classminheight text-center text-light' style={{paddingTop:'200px'}}>Paintings will be available soon</div>}
      </div>
    
     
  
)
  }

  return (
    <div>
      <Canonical url="https://www.mylovepainting.com/home" />
  {!navigatemenuopen&&<div className='gallerydiv'>
      <Allproducts 
      products = {products}  
      setProductsOrder = {setProductsOrder}  
    productsOrder = {productsOrder}
      />
    </div>}
    </div>
  
  );
}



export default Home;