import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import ButtonWrapper from './ButtonWrapper';

function PayPalWrapper({
  ppalk,
  order,
  setIstimeout,
  istimeout,
  packagingDUpdts,
  paintDUpdts
}) {
 const currency = 'CAD'
 if (!ppalk) {
  // ppalk not provided or is an empty string
  return <div className='text-light' style={{paddingTop:'150px',minHeight:'100vh'}}>Loading...</div>;
} else {
  // ppalk is provided
  return (
    <PayPalScriptProvider
      options={{
        "client-id": ppalk,
        components: "buttons",
        currency: "CAD"
      }}
    >
      <ButtonWrapper
        currency={currency}
        order={order}
        setIstimeout={setIstimeout}
        istimeout={istimeout}
        packagingDUpdts={packagingDUpdts}
        paintDUpdts={paintDUpdts}
      />
    </PayPalScriptProvider>
  );
}

 
}

export default PayPalWrapper;
