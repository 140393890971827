import React, { useState } from 'react';
import Myeventsadminlist from './Myeventsadminlist';

import {useNavigate } from 'react-router-dom';


function AllMyeventsadmin(
{ 
  myEvents,
      setErrorAuth,
      setFetchStatus,
     
}) 
{
const navigate = useNavigate();
const [activeEventIndex, setActiveEventIndex] = useState('');
const [activeSingleEventIndex, setActiveSingleEventIndex] = useState('');

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};


return (
<div>
{myEvents&&myEvents.length>3&&<code className='up' style={{ cursor: 'pointer'}} onClick={scrollToTop}>&#x21ea;</code>}
  <div className='text-center my-3'>
    <button id="Top"
   className="btn btn-primary btn-sm"
      type="submit"
      onClick={() => {
        navigate('/createmyeventsadmin')
      }}
    >
    Create New Event
    </button>
  </div>
  {myEvents&&
    <div>
      <Myeventsadminlist 
  myEvents={myEvents}
  activeEventIndex={activeEventIndex} 
  setActiveEventIndex={setActiveEventIndex}
activeSingleEventIndex={activeSingleEventIndex}
 setActiveSingleEventIndex={setActiveSingleEventIndex}
 setErrorAuth={setErrorAuth}
 setFetchStatus={setFetchStatus}
      />
    </div>
  }
</div>
);
}

export default AllMyeventsadmin;