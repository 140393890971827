import { useState } from 'react';
import Auth from '../utils/auth';
import { Api } from '../utils/api';
import Confirmresetpwd from '../components/Admin/Confirmresetpwd'
import { Loginuser,Signupuser,myEnv } from '../utils/api';


function Login() {

  const [SignUpDataUser, setSignUpDataUser] = useState({ username: '', email: '', password: ''});
  const [loginDataUser, setLoginDataUser] = useState({ email: '', password: ''});
  const [emailResetPwd, setEmailResetPwd] = useState('');

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleConfirm = async (e) => {
    
    setIsDialogOpen(false);

    // Proceed with form submission logic
    e.preventDefault();

    try {

        const response = await Api.post('/forgotpassword',{email:emailResetPwd});

      if (response.status === 200) {
setEmailResetPwd('')
alert('check your email for further instructioins on how to reset your password')
      }

    } catch (err) {
      setIsDialogOpen(false);
        alert('Failed to proceed with password reset');
        
    
    }
  };

  const handleLoginDataUser = (event) => {
    const { name, value } = event.target;
    setLoginDataUser({ ...loginDataUser, [name]: value });
  };

  const handleLoginUserSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await Loginuser.post('/',loginDataUser)

      if (response.status === 200) {
        const data = await response.data;
        Auth.login(data.token);
      } else {
        alert('Failed to login.');
      }
    } catch (error) {
      alert('Failed to login.');
    }
  };

  const handleCancel = () => {
    setEmailResetPwd('')
   setIsDialogOpen(false)
  };

  const handleEmail = (event) => {
    const value = event.target.value; // Correctly access the value of the input
    setEmailResetPwd(value);
  
  };
  const handleSignUpDataUser = (event) => {
    const { name, value } = event.target;
    setSignUpDataUser({ ...SignUpDataUser, [name]: value });
  };

  const handleSignUpUserSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await Signupuser.post('/',SignUpDataUser)

      if (response.status === 200) {
        const data = await response.data;
        Auth.login(data.token);
      } else {
        alert('Failed to sign up.');
      }
    } catch (error) {
     alert('Failed to sign up.');
    }
  };

  return (
  <div className='login custom10 pt-2 text-light'>

    <div className='mb-4 text-center'>
      <button className='btn btn-primary mb-2' onClick={()=>window.location.assign('./home')}>GO GALLERY</button>
    </div>
    <form>
      <div className='px-4'>
        <h3>Login</h3>
      </div>
  
      <div className="px-2 my-3">
    <div style={{maxWidth: '500px'}}> 
        <label htmlFor="emaillogin" className="form-label-lg mb-2">Email address:</label>
        <input 
            className="form-control form-control-md mb-3"
            id="emaillogin"
            type="email"
            placeholder="name@example.com"
            name="email"
            onChange={handleLoginDataUser}
            disabled={isDialogOpen}
            value={loginDataUser.email}
        />

        <label htmlFor="passwordlogin" className="form-label-lg mb-2">Password:</label>
        <input
            className="form-control form-control-md mb-3"
            id="passwordlogin"
            type="password"
            placeholder="password"
            name="password"
            disabled={isDialogOpen}
            onChange={handleLoginDataUser}
            value={loginDataUser.password}
            
        />
    </div>

    <p style={{cursor:'pointer',  textDecoration:'underline'}} onClick={()=> setIsDialogOpen(true)}>Forgot password</p>
    <button type="button" disabled={isDialogOpen} className="btn btn-primary"
        onClick={handleLoginUserSubmit}>
        Submit
      </button>
</div>

      

    </form>          
    {myEnv&&<form>
      <div className='px-4 mt-5'>
        <h3>Sign up</h3>
      </div>
      <div className="px-2 my-3">
    <div style={{maxWidth: '500px'}}>
        <div className="mb-3">
            <input 
                className="form-control form-control-md mb-3"
                type="text"
                placeholder="Your name"
                name="username"
                disabled={isDialogOpen}
                onChange={handleSignUpDataUser}
                value={SignUpDataUser.username}
            />
        </div>
        <div className="mb-3">
            <input 
                className="form-control form-control-md mb-3"
                type="email"
                placeholder="name@example.com"
                name="email"
                disabled={isDialogOpen}
                onChange={handleSignUpDataUser}
                value={SignUpDataUser.email}
            />
        </div>
        <div className="mb-3">
            <input 
                className="form-control form-control-md mb-3"
                type="password"
                placeholder="Password"
                name="password"
                disabled={isDialogOpen}
                onChange={handleSignUpDataUser}
                value={SignUpDataUser.password}
            />
        </div>
        <button
            className="btn btn-primary"
            disabled={isDialogOpen}
            onClick={handleSignUpUserSubmit}>
            Submit
        </button>
    </div>
</div>

    </form>} 
    
    <Confirmresetpwd
        isOpen={isDialogOpen}
        message='please enter your email associated with your account'
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        emailResetPwd={emailResetPwd} 
        setEmailResetPwd={setEmailResetPwd}
        handleEmail={handleEmail}
  
      />         
  </div>
  );
}

export default Login;