import React from 'react';
import auth from '../../utils/auth'; // Import authService

function Loginmessage() {
  return (
  <div className='custom4 custom6'>
    <div className='text-center mt-2 mb-2' >
      {!auth.loggedIn()&&
        <button
        className="btn btn-secondary"
          type="submit"
          onClick={(event) => {
            event.preventDefault();
            window.location.assign('./');
          }}
        >
          login
        </button>
      }
    </div>
    <div className='text-center'>
      <h4>you need to login</h4>
    </div>
  </div>
  );
}

export default Loginmessage;